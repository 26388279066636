import React, { useState } from "react";
import { List, NumberField, TextField } from "react-admin";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

import MyDatagrid from "../customise/CustomDataGrid";
import { CommonFilter } from "../helpers/commonFilters";
// import exporters from "../helpers/exporters";
import { getParameterByName, getDefaultTime, filtersChanged, allFilters } from "../helpers/utils";
import { authProvider } from "../provider/provider";
import { resources } from "../provider/configs";
import ExpandItemOER from "./ExpandItemOER";
import T from "../translation/translation.js";
import * as _ from "lodash";

const useStyles = makeStyles({
  failed: { color: "red" },
  passed: { color: "green" },
  boldy: { fontWeight: "bold" },
  month: { backgroundColor: "#a7d4ff" },
});

const notPass = (actual, target) => actual < Number.parseFloat(target);

const PerformanceField = (props) => {
  const classes = useStyles();
  let fail = notPass(props.record["actual"], props.record.oer_item.target);
  return (
    <span
      className={classnames({
        [classes.failed]: fail,
        [classes.passed]: !fail,
        [classes.boldy]: true,
      })}
    >
      {props.record["performance"]} %
    </span>
  );
};

const rowStyle = (record, index) => {
  if (record.isGroupHeader) {
    return {
      height: "2em",
      backgroundColor: "#dde6e9",
      textAlign: "center",
      fontSize: "2em",
      fontWeight: "bold",
    };
  }

  if (record.month) {
    return { backgroundColor: "#ffdab3" };
  }

  return  {};
};

const MonthField = ({ record, source }) => {
  let month = _.result(record, source);
  if (month) month = T.translate(month);
  return (<span>{month}</span>)
};

export const OERsList = ({ ...props }) => {
  let filter = getParameterByName("filter") || "{}";
  filter = JSON.parse(filter);
  let { year, month } = getDefaultTime();

  let [stateFilter, setStateFilter] = useState({
    displayed: allFilters(filter),
    vendor: filter.vendor,
    cost_center: filter.cost_center,
    year: filter.year || year,
    month: filter.month || month,
  });

  function filtersUpdated(filters) {
    if (filtersChanged(filters, stateFilter)) {
      if (!allFilters(filters)) {
        setStateFilter({ ...filters, displayed: false });
      } else {
        setStateFilter({ ...filters, displayed: true });
      }
    }
  }
  const classes = useStyles();

  let colorAllCells = ({ record }) => classes.month;

  const isEditAllowed = () => authProvider.isAllowed(props.permissions, resources.oers.app.name, "update");
  let total_fields = [{ field: "performance", key: "total_response" }];
  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<CommonFilter filtersUpdated={filtersUpdated} />}
        filterDefaultValues={{ year, month }}
        pagination={false}
        title="OERs List"
        exporter={false}
        // exporter={(d) => exporters(d, "oers")}
      >
        <MyDatagrid
          expand={ExpandItemOER}
          groupby={"oer_item.oer_group.label"}
          rowStyle={rowStyle}
          rowClick={(id, basePath, record) => {
            if (record.isGroupHeader) return null;
            if (!isEditAllowed()) return "show";
            return !record.editable ? "show" : "edit";
          }}
          colSpan={3}
          total_fields={total_fields}
          toSum={["actual", "oer_item.categoryPercentage", "oer_item.target"]}
        >
          <TextField source="oer_item.Ref" label="Ref" />
          <TextField sortable={false} source="oer_item.category" label="Category" />
          <TextField sortable={false} source="oer_item.info" label="Information" />
          <MonthField sortable={false} source="month" label="Audit Month" cellformat={colorAllCells} />
          <NumberField sortable={false} source="oer_item.target" label="Expected Target" />
          <NumberField sortable={false} unit={"%"} source="oer_item.categoryPercentage" label="Rating Percentage" />
          <NumberField sortable={false} source="actual" label="Actual" />
          <PerformanceField sortable={false} unit={"%"} source="performance" label="Performance" />
        </MyDatagrid>
      </List>
    </div>
  );
};
