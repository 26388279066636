import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import { result } from "lodash";

function createObj(headers, obj, i) {
  return headers.reduce((acc, { key, source }) => {
    acc[key] = source ? `${result(obj, source)}` : i + 1;
    return acc;
  }, {});
}

const USERS_HEADERS = [
  { key: "No." },
  { key: "Full Name", source: "fullName" },
  { key: "Username", source: "username" },
  { key: "Email", source: "email" },
  { key: "Role", source: "role.name" },
];

const QA_HEADERS = [
  { key: "No." },
  { key: "Zone Id", source: "zoneId" },
  { key: "Zone Name", source: "zoneName" },
  { key: "Criticality", source: "criticality" },
  { key: "Value To Score", source: "value_to_score" },
  { key: "Exponential Criticality", source: "exponential_criticality" },
  { key: "Technical Audit", source: "technicalAudit" },
  { key: "Joint Audit", source: "jointAudit" },
  { key: "Snap Audit", source: "snapAudit" },
  { key: "Total", source: "total" },
];

const ROLES_HEADERS = [
  { key: "No." },
  { key: "Name", source: "name" },
  { key: "Users Count", source: "nb_users" },
  { key: "Description", source: "description" },
];

const KPIS_HEADERS = [
  { key: "No.", source: "kpi_item.index" },
  { key: "Service Type", source: "kpi_item.serviceType" },
  { key: "Service Area", source: "kpi_item.serviceArea" },
  { key: "Key Activity", source: "kpi_item.keyActivity" },
  { key: "Metric", source: "kpi_item.metric" },
  {
    key: "Key Performance Indicators",
    source: "kpi_item.keyPerformanceIndicators",
  },
  { key: "Performance Level", source: "kpi_item.performanceLevel" },
  { key: "Target Label", source: "kpi_item.targetLabel" },
  { key: "Client Weighting", source: "kpi_item.clientWeighting" },
  { key: "KPI", source: "kpi_item.KPI" },
  { key: "Measurement", source: "kpi_item.measurement" },
  { key: "Expected Target", source: "target" },
  { key: "Actual", source: "actual" },
  { key: "Performance", source: "performance" },
];

const SLAS_HEADERS = [
  { key: "No." },
  { key: "Service Type", source: "sla_item.serviceType" },
  { key: "Service Area", source: "sla_item.serviceArea" },
  { key: "KPI", source: "sla_item.KPI" },
  { key: "Performance Level", source: "sla_item.performanceLevel" },
  { key: "Key Activity", source: "sla_item.keyActivity" },
  { key: "Measurement", source: "sla_item.measurement" },
  { key: "Metric", source: "sla_item.metric" },
  { key: "Performance Indicator", source: "sla_item.performanceIndicator" },
  { key: "Target", source: "sla_item.target" },
  { key: "Client Weighting", source: "sla_item.clientWeighting" },
  { key: "Required Proof Document", source: "sla_item.requiredProofDocument" },
  { key: "Pass/Fail", source: "state" },
  { key: "Score", source: "score" },
  { key: "Penalty", source: "penalty" },
];

const NCRS_HEADERS = [
  { key: "No." },
  { key: "Criticality", source: "criticality" },
  { key: "Description", source: "description" },
  { key: "Count", source: "count" },
  { key: "Score", source: "score" },
  { key: "Penalty", source: "penalty" },
];

const defaultExport = (headers) => ({
  headers,
  handler: (d) => d.map((...p) => createObj(headers, ...p)),
});

const CONFIGS = {
  users: defaultExport(USERS_HEADERS),
  "Quality Audits": {
    headers: QA_HEADERS,
    handler: (all) => {
      if (all.length === 0) return all;
      let arr = all.map((data, i) => createObj(QA_HEADERS, data, i));
      arr.push({
        "No.": "Total",
        Total: `${all[0]["total_response"]} %`,
      });
      return arr;
    },
  },
  roles: defaultExport(ROLES_HEADERS),
  slas: {
    headers: SLAS_HEADERS,
    handler: (all) => {
      if (all.length === 0) return all;
      let arr = all.map((data, i) => createObj(SLAS_HEADERS, data, i));
      arr.push({
        "No.": "Total",
        score: `${all[0]["total_response"]} %`,
        penalty: `${all[0]["totalPenalties_response"]} AED`,
      });
      return arr;
    },
  },
  kpis: {
    headers: KPIS_HEADERS,
    handler: (all) => {
      if (all.length === 0) return all;
      let actual = 0,
        target = 0;
      let arr = all.map((data, i) => {
        actual += data.actual || 0;
        target += data.target || 0;
        return createObj(KPIS_HEADERS, data, i);
      });
      arr.push({
        "No.": "Total",
        Performance: `${all[0]["total_response"]} %`,
        Actual: `${actual}`,
        "Expected Target": `${target}`,
      });
      arr.push({
        "No.": "Total Penalties",
        Performance: `${all[0]["totalPenalties_response"]} AED`,
      });
      return arr;
    },
  },
  ncrs: {
    headers: NCRS_HEADERS,
    handler: (all) => {
      if (all.length === 0) return all;
      let penalty = 0;
      let arr = all.map((data, i) => {
        penalty += data.penalty || 0;
        return createObj(NCRS_HEADERS, data, i);
      });
      arr.push({
        "No.": "Total",
        Score: `${all[0].total_response} %`,
        Penalty: `${penalty} AED`,
      });
      return arr;
    },
  },
  logs: {
    default: (data) => {
      var blob = new Blob([JSON.stringify(data, 0, 4)], { type: "application/json" });
      let url = window.URL.createObjectURL(blob);

      var date = new Date()

      // Creating the hyperlink and auto click it to start the download
      let link = document.createElement("a");
      link.href = url;
      link.download = "dump_" + date + ".json";
      link.click();
    },
  },
};

export default (data, type) => {
  const conf = CONFIGS[type];
  if (conf.default) return conf.default(data);
  const dataForExport = conf.handler(data);
  jsonExport(
    dataForExport,
    { headers: conf.headers.map(({ key }) => key) },
    (err, csv) => {
      downloadCSV(csv, type || "data");
    }
  );
};
