import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextInput, EditController } from "react-admin";
import CustomSimpleForm from "../customise/CustomSimpleForm";
import CustomToolbar from "../customise/CustomToolbar";

const editStyles = {};

class RoleEditToStyle extends React.Component {
  render() {
    return (
      <EditController {...this.props} undoable={false}>
        {({ resource, record, redirect, save, basePath, version, ...rest }) => {
          return (
            <div>
              {record && (
                <CustomSimpleForm
                  toolbar={
                    <CustomToolbar allowDelete={false} editable={true} />
                  }
                  basePath={basePath}
                  redirect={'/settingsUsers?tab=1'}
                  resource={resource}
                  record={record}
                  save={save}
                  version={version}
                >
                  <TextInput
                    multiline={true}
                    fullWidth={true}
                    source="description"
                    resettable
                  />
                </CustomSimpleForm>
              )}
            </div>
          );
        }}
      </EditController>
    );
  }
}

const RoleEdit = withStyles(editStyles)(RoleEditToStyle);
export default RoleEdit;

export { RoleEdit };
