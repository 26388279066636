import React from "react";
import * as _ from "lodash";
import Grid from "@material-ui/core/Grid";
import ItemTable from "../helpers/simple_table";

const headerRow1 = [
  { id: "oer_group.key", label: "Section Ref." },
  { id: "oer_group.label", label: "Section" },
  { id: "Ref", label: "Ref." },
  { id: "category", label: "Category" },
  { id: "info", label: "Information" },
];
const tableHeader1 = headerRow1.map((r) => r["label"]);

export const KeyActivitiesShow = ({ record }) => {
  if (!record) return null;
  let actualsByRef = _.keyBy(record.keyActivitiesCalculation, "Ref");
  let rows = [
    {
      isHeader: true,
      vals: ["Ref", "Key Activity Description", "Target", "Actual", "Justification"],
    },
    ...record.oer_item.keyActivities.map(({ Ref, KeyActivityDescription, target }) => {
      return {
        vals: [Ref, KeyActivityDescription, target, actualsByRef[Ref].actual, actualsByRef[Ref].Justification],
      };
    }),
  ];

  return (
    <Grid item xs={9} style={{ marginTop: "10px" }}>
      <ItemTable rows={rows} />
    </Grid>
  );
};

export const ItemDetailsShow = ({ record }) => {
  if (!record) return null;
  let rows = [
    {
      isHeader: true,
      vals: tableHeader1,
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => _.result(record.oer_item, r["id"])),
    },
  ];

  return (
    <Grid item xs={12}>
      <ItemTable rows={rows} />
    </Grid>
  );
};

export const OER_ITEM_SHOW = ({ record }) => {
  if (!record) return null;

  return (
    <Grid container spacing={0}>
      <ItemDetailsShow record={record}/>
      <KeyActivitiesShow record={record}/>
    </Grid>
  );
};

export default {
  KeyActivitiesShow,
  ItemDetailsShow,
  OER_ITEM_SHOW,
};

