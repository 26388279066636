import React from "react";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import AlertDialog from "../../customise/confirmationDialog";

const useStyles = makeStyles((theme) => ({
  buttonMore: {
    backgroundColor: "darkGreen",
    float: "right",
    "&:hover": { backgroundColor: "darkGreen" },
  },
}));

export default function SendAnyWay({ handler, message, disabled, label }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (...params) => {
    if (params[0] === "Ok") {
      handler(...params);
    }
    setOpen(false);
  };

  let description = () => {
    return <span>{message}</span>;
  };
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        className={classes.buttonMore}
        startIcon={<SendIcon />}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {label}
      </Button>
      <AlertDialog
        open={open}
        messages={{
          title: "Confirmation!",
          description,
          ok: "Send",
          cancel: "Cancel",
        }}
        handler={handleClose}
      />
    </>
  );
}
