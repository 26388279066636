import { resources } from "./configs";

const resourcesArr = Object.values(resources);
const byName = resourcesArr.reduce((acc, obj) => {
  acc[obj.name] = obj;
  return acc;
}, {});

const defaultSort = function (resource, s) {
  let resourceObj =
    byName[resource] || resourcesArr.find((e) => e.name === resource) || {};
  byName[resource] = resourceObj;
  if (s && s.field !== "" && s.field !== "id") {
    return s.field + ":" + s.order;
  }
  let { field = "id", order = "DESC" } = resourceObj.defaultSort || {};
  return `${field}:${order}`;
};

const componentsKeys = [
  "totalNCRsPerCategory",
  "sla_item",
  "kpi_item",
  "opm_item",
  "sppv_item",
  "oer_item",
  "regulatory_compliance_item",
  "zone_location_mappings",
  "deduction_per_types",
];

function isComponentsKeys(k) {
  return componentsKeys.indexOf(k) !== -1;
}

function customPagination({ page, perPage }, resource) {
  let resourceObj =
    byName[resource] || resourcesArr.find((e) => e.name === resource) || {};
  const start = (page - 1) * perPage;
  if (resourceObj.allowPagingation) {
    const limit = perPage;
    const range = "_start=" + start + "&_limit=" + limit;
    return range;
  }
  return "_start=" + start + "&_limit=" + -1;
}

function listAndReferneceCustomizedResponse(resource, json) {
  switch (resource) {
    case resources["roles"].name:
      return json.roles.filter((r) => !r.name.match(/Public|Authenticated/gi));

    default:
      return json;
  }
}

const adjustQueryForStrapi = (params, resource) => {
  /*
      params = { 
          pagination: { page: {int} , perPage: {int} }, 
          sort: { field: {string}, order: {string} }, 
          filter: {Object}, 
          target: {string}, (REFERENCE ONLY)
          id: {mixed} (REFERENCE ONLY)
      }
      */

  // Handle SORTING
  const s = params.sort;
  const sort = `_sort=${defaultSort(resource, s)}`;

  // Handle FILTER
  const f = params.filter;
  let filter = "";
  const keys = Object.keys(f);
  for (let i = 0; i < keys.length; i++) {
    //react-admin uses q filter in several components and strapi use _q
    if (keys[i] === "q" && f[keys[i]] !== "") {
      filter += "_q=" + f[keys[i]] + (keys[i + 1] ? "&" : "");
    } else {
      if(f[keys[i]] !== undefined) filter += keys[i] + "=" + f[keys[i]] + (keys[i + 1] ? "&" : "");
    }
  }
  if (params.id && params.target && params.target.indexOf("_id") !== -1) {
    const target = params.target.substring(0, params.target.length - 3);
    filter += "&" + target + "=" + params.id;
  }

  // Handle PAGINATION
  const range = customPagination(params.pagination, resource);

  return sort + "&" + range + "&" + filter;
};

export {
  defaultSort,
  isComponentsKeys,
  customPagination,
  listAndReferneceCustomizedResponse,
  adjustQueryForStrapi,
};
