import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import DeductionSummary from "./DeductionSummaryEditable";
import InvoiceSummary from "./InvoiceSummaryWithRefresh";
import { authProvider } from "../provider/provider";
import { resources } from "../provider/configs";
import { allFilters } from "../helpers/utils";
import { TypesTable } from "./configTable.js";
import SummaryCharts from "./SummaryCharts.js";

export default function AfterLoadComponents(props) {
  let { lastUpdate, setLastUpdate, data } = props;
  // function ConfigTables(props) {
  let invoiceSummaryAllowed = authProvider.isAllowed(
    props.permissions,
    resources["invoice-summaries"].app.name,
    resources["invoice-summaries"].app.find
  );
  let deductionSummaryAllowed = authProvider.isAllowed(
    props.permissions,
    resources["deduction_summaries"].app.name,
    resources["deduction_summaries"].app.find
  );

  return allFilters(props.filter) ? (
    <CardContent>
      <Grid container spacing={0}>
        <Grid container>
          <Grid item xs={12}>
            <SummaryCharts data={props.data} ids={props.ids}  />
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                padding: "10px",
                "marginTop": "10px",
                "marginBottom": "10px",
                background: "#e6e6e6",
                "textAlign": "center",
                "fontWeight": "bold",
              }}
            >
              Financial Summary
            </h3>
          </Grid>
          <Grid container item xs={12} spacing={0}>
            <Grid item xs={6}>
              {invoiceSummaryAllowed && (
                <InvoiceSummary
                  lastUpdate={lastUpdate}
                  filters={props.filter}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {deductionSummaryAllowed && (
                <DeductionSummary
                  permissions={props.permissions}
                  lastUpdate={lastUpdate}
                  setLastUpdate={(v) => {
                    setLastUpdate(v);
                  }}
                  filters={props.filter}
                />
              )}
            </Grid>
          </Grid>
        </Grid>



        <Grid container item xs={12}>
          <Grid item xs={12}>
            <h3
              style={{
                padding: "10px",
                "marginTop": "10px",
                "marginBottom": "10px",
                background: "#e6e6e6",
                "textAlign": "center",
                "fontWeight": "bold",
              }}
            >
              KPI Configurations
            </h3>
          </Grid>
          <Grid item xs={6}>
            <TypesTable filter={props.filter} />
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />

      {/* <Typography variant="h3" component="h2">
                PRS History
            </Typography>
            <PastMonthsTotals {...props} /> */}
    </CardContent>
  ) : null;
}
