import React from "react";
import ItemTable from "../helpers/simple_table";

const headerRow1 = [
  { id: "Ref", label: "Ref" },
  { id: "ValueDriver", label: "Value Driver" },
  { id: "Measurement", label: "Measurement" },
  { id: "Goal", label: "Goal" },
];
const tableHeader1 = headerRow1.map((r) => r["label"]);

const headerRow2 = [
  { id: "Benefit", label: "Benefit" },
  { id: "KPI", label: "KPI" },
  { id: "Frequency", label: "Frequency" },
  { id: "", label: "" },
];
const tableHeader2 = headerRow2.map((r) => r["label"]);

export const OPMItemShow = function ({ record }) {
  let rows = [
    {
      isHeader: true,
      vals: tableHeader1,
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => record[r["id"]]),
    },
    {
      isHeader: true,
      vals: tableHeader2,
    },
    {
      isHeader: false,
      vals: headerRow2.map((r) => record[r["id"]]),
    },
  ];
  return <ItemTable rows={rows} />;
};

export const OPM_ITEM_SHOW = (props) => {
  return <OPMItemShow record={props.record.opm_item} />;
};

export default { OPM_ITEM_SHOW, OPMItemShow };
