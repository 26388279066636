import * as React from "react";
import { List, TextField, NumberField } from "react-admin";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { withStyles } from "@material-ui/styles";

import MyDatagrid from "../customise/CustomDataGrid";
import { CommonFilter } from "../helpers/commonFilters";
import {
  getParameterByName,
  getDefaultTime,
  allFilters,
} from "../helpers/utils";
import AfterLoadComponents from "./AfterLoadComponents";
import exporters from "../helpers/exporters";

const useStyles = makeStyles({
  boldy: { fontWeight: "bold" },
});

const styles = (theme) => ({});

const CountField = (props) => {
  const classes = useStyles();

  return (
    <NumberField className={classnames({ [classes.boldy]: true })} {...props} />
  );
};

class ncrsListToStyle extends React.Component {
  constructor(props) {
    super(props);
    let filter = getParameterByName("filter") || "{}";
    filter = JSON.parse(filter);

    this.state = {
      displayed: filter.vendor && filter.cost_center,
      filter: { vendor: filter.vendor, cost_center: filter.cost_center },
    };
    this.total_fields = [{ field: "score", key: "total_response" }];
  }

  filtersUpdated(filters) {
    let all = allFilters(filters);
    if (!all && this.state.displayed) {
      this.setState({
        displayed: false,
        filter: filters,
      });
    } else if (all && !this.state.displayed) {
      this.setState({
        displayed: true,
        filter: filters,
      });
    }
  }

  render() {
    // const { classes } = this.props;
    let { year, month } = getDefaultTime();
    return (
      <div>
        <List
          {...this.props}
          bulkActionButtons={false}
          title="List of NCRs Results"
          pagination={false}
          filters={
            <CommonFilter filtersUpdated={this.filtersUpdated.bind(this)} />
          }
          filterDefaultValues={{ year, month }}
          exporter={d => exporters(d, 'ncrs')}
        >
          <MyDatagrid
            // style={{tableLayout: 'fixed'}}
            toSum={["penalty"]}
            colSpan={2}
            total_fields={this.total_fields}
            AfterLoadComponents={<AfterLoadComponents />}
          >
            <Cus width="75" source="criticality" />
            <TextField label="Category Description" source="description" />
            <CountField label="count" source="count" />
            <CountField unit={"%"} source="score" />
            <CountField
              locales="fr-FR"
              options={{ style: "currency", currency: "AED" }}
              source="penalty"
            />
          </MyDatagrid>
        </List>
      </div>
    );
  }
}
function Cus({ record, source }) {
  return <span>{record[source]}</span>;
}
const ncrsList = withStyles(styles)(ncrsListToStyle);
export default ncrsList;

export { ncrsList };
