import React, { cloneElement, useState } from "react";
import {
  Datagrid,
  ListBase,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  TextField,
  TopToolbar,
  showNotification,
  ExportButton,
  BulkDeleteButton,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import { getParameterByName } from "../../helpers/utils";
import { UserCreate, UserEdit } from "../../users";
import EditIcon from "@material-ui/icons/Edit";
import exporters from "../../helpers/exporters";

const basePath = "/users";

const UsersListActions = ({ onClickAction }) => (
  <TopToolbar>
    <Button
      color="primary"
      onClick={() => onClickAction("create")}
      startIcon={<AddIcon />}
    >
      Create New User
    </Button>
    <ExportButton />
  </TopToolbar>
);

const UsersList = ({
  currentView,
  onClickAction,
  editParams,
  permissions,
  ...props
}) => (
  <ListBase {...props}>
    {currentView === "list" && permissions.find.enabled && (
      <>
        <ListToolbar filters={props.filters} actions={props.actions} />
        <Card>
          <BulkActionsToolbar>{props.bulkActionButtons}</BulkActionsToolbar>
          {cloneElement(props.children, {
            hasBulkActions: props.bulkActionButtons !== false,
          })}
          {props.pagination && <Pagination />}
        </Card>
      </>
    )}
    {currentView === "create" && permissions.create.enabled && (
      <UserCreate
        basePath={basePath}
        resource={"users"}
        onClickAction={onClickAction}
      />
    )}
    {currentView === "edit" && permissions.update.enabled && (
      <UserEdit
        {...editParams}
        onClickAction={onClickAction}
        permissions={permissions}
      />
    )}
  </ListBase>
);

const CustomEditButom = ({ toEdit, ...rest }) => {
  return (
    <Button
      color="primary"
      onClick={(e) => toEdit(e, rest)}
      startIcon={<EditIcon />}
    >
      {"Edit"}
    </Button>
  );
};

const UsersBulkActionButtons = (props) => <BulkDeleteButton {...props} />;

const UsersTab = ({ permissions, history, ...rest }) => {
  let originalView = getParameterByName("view");
  let view =
    originalView === "list"
      ? originalView
      : originalView === "create" && !permissions.create.enabled
      ? "list"
      : originalView === "edit" && !permissions.update.enabled
      ? "list"
      : originalView;

  let [currentView, setCurrentView] = useState(view);
  let [editParams, setEditParams] = useState(null);

  const onClickAction = (p) => {
    if (
      (p === "create" && !permissions.create.enabled) ||
      (p === "edit" && !permissions.update.enabled)
    ) {
      this.prosp.showNotification("Not Allowed: Pemmission Not granted");
      p = "list";
    }
    history.push(`settingsUsers?tab=0&view=${p}`);
    setCurrentView(p);
  };

  const toEdit = function (e, p) {
    e.stopPropagation();
    console.log("toEdit", p);
    setEditParams(p);
    onClickAction("edit");
  };

  return (
    <UsersList
      {...{
        title: "Users",
        bulkActionButtons: permissions.destroy.enabled ? (
          <UsersBulkActionButtons />
        ) : (
          false
        ),
        basePath,
        resource: "users",
        onClickAction,
        actions: permissions.create.enabled ? (
          <UsersListActions basePath={basePath} onClickAction={onClickAction} />
        ) : null,
        currentView,
        editParams,
        pagination: true,
        perPage: 25,
        permissions,
        exporter: (users) => exporters(users, "users"),
        ...rest
      }}
    >
      <Datagrid>
        <TextField label="Full Name" source="fullName" />
        <TextField label="Username" source="username" />
        <TextField label="Role" source="role.name" />
        <ArrayField source="vendors">
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ArrayField>
        <ArrayField source="cost_centers">
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ArrayField>
        <TextField label="Email" source="email" />
        {permissions.update.enabled && <CustomEditButom toEdit={toEdit} />}
      </Datagrid>
    </UsersList>
  );
};

export default connect(null, { showNotification })(UsersTab);
export { UsersTab };
