import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export default function Icon1(props) {
  return (
    <SvgIcon
      {...props}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="245.000000pt"
      height="246.000000pt"
      viewBox="0 0 245.000000 246.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
        fill="#2c478d"
        stroke="none"
      >
        <path
          d="M1267 2350 c-82 -15 -189 -50 -252 -83 -59 -32 -158 -121 -207 -187
-17 -23 -30 -32 -36 -25 -5 6 -29 61 -53 122 l-44 113 -327 0 -328 0 0 -1090
0 -1090 385 0 385 0 0 588 c0 640 1 649 58 756 15 28 52 76 82 107 97 98 223
138 376 119 187 -24 279 -98 337 -273 19 -56 21 -91 25 -385 l4 -323 121 3
122 3 3 137 3 138 129 0 130 0 0 -140 0 -140 -130 0 -130 0 0 -135 0 -135
-125 0 -125 0 0 -160 0 -160 385 0 385 0 0 160 0 160 -130 0 -130 0 0 135 0
135 130 0 130 0 0 140 0 140 -130 0 -130 0 0 140 0 140 130 0 130 0 0 24 c0
53 -21 236 -36 311 -83 414 -326 662 -728 741 -95 18 -340 27 -409 14z"
        />
      </g>
    </SvgIcon>
  );
}
