import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3a97f3',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
  },
  container: {
    // maxWidth: '80%',
  }
});

export default function ItemTable({rows}) {
    let classes = useStyles()
    return (
        <TableContainer className={classes.container} component={Paper}>
            <Table className={classes.table} size="small" aria-label="simple table">
                {rows.map((r, k) => {
                    return r.isHeader ? 
                        <TableHead key={"head - " + k}>
                            <TableRow>
                            {r.vals.map((ele, i) => (
                                <StyledTableCell key={`${k}-${i}`} style={{width: '20%', fontWeight: 'bold'}}>
                                    {ele}
                                </StyledTableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        :
                        <TableBody key={"head - " + k}>
                            <StyledTableRow>
                                {r.vals.map((v, i) =>
                                    <StyledTableCell key={`${k}-${i}`}>
                                        {v}
                                    </StyledTableCell>
                                    )}
                            </StyledTableRow>
                        </TableBody>
                })}
            </Table>
        </TableContainer>
    )
}