import React, { useCallback } from "react";
import { usePermissions, useRedirect, Loading, GET_LIST } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import { resources } from "../provider/configs";
import CreateFilters from "./filters";
import { authProvider, dataProvider } from "../provider/provider";
import ViewCharts from "./viewCharts";

function getTimeStampFromYearAndMonth({ year, month }, startOrEnd) {
  let date = new Date();
  date.setFullYear(year);
  if (startOrEnd == "start") {
    date.setMinutes(0);
    date.setHours(0);
    date.setMonth(month - 1); // months counts from 0 in javascript Date
    date.setDate(1);
  } else {
    date.setMonth(month); // use previouse month because `0` will get the last day of it
    date.setHours(23);
    date.setMinutes(59);
    date.setDate(0);
  }
  console.log("getTimeStampFromYearAndMonth", {
    year,
    month,
    date,
    startOrEnd,
  });
  return date.valueOf();
}

const resource = resources["dashboard"];

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    top: "50%",
    margin: "auto",
    position: "relative",
    width: "50px",
  },
  chartContainer: {
    width: "40%",
    display: "inline-block",
  },
  button: {
    float: "right",
  },
  card: {
    display: "block",
    transitionDuration: "0.3s",
    height: "100%",
  },
  title: {
    textAlign: "center",
    fontSize: "20px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  alert: {
    boxShadow: "3px 3px 3px #888888",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const redirect = useRedirect();
  const { loaded: permissionsLoaded, permissions } = usePermissions();

  const getPerm = useCallback(
    (n, a) => {
      return authProvider.getPermissionPerType(permissions, n, a || "find");
    },
    [permissions]
  );

  let d = new Date();

  let def = {
    // nestromUserID: "5f4b8b6eecf4a248cd3c60cf", // ahmad basher
    // nestromUserID: "5d7113da2c69145270717dd9", // ali shattah - stg
    year: d.getFullYear(),
    month: d.getMonth(),
    nestromUserID: "invalid",
    // user: { organizations: [] },
    // cost_center,
  };

  def.startDate = getTimeStampFromYearAndMonth(def, "start");
  def.endDate = getTimeStampFromYearAndMonth(def, "end");


  const [state, setState] = React.useState({
    fetching: false,
    filter: def,
    dataLoaded: false,
    dataLoadedYTD: false,
    data: null,
  });

  const fetchChartData = useCallback(
    function (nestromUserID, startDate, endDate, cost_center) {
      let body = {
        nestromUserID: nestromUserID,
        startDate: startDate,
        endDate: endDate,
      };
      if (cost_center && cost_center !== "invalid")
        body.organizationId = cost_center;
      console.log("fetch with filter", {
        nestromUserID,
        startDate,
        endDate,
        cost_center,
      });
      Promise.all([
        dataProvider(GET_LIST, resource.name, {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "DESC" },
          filter: body,
        }),
        dataProvider(GET_LIST, resource.name, {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "DESC" },
          filter: {
            ...body,
            startDate: getTimeStampFromYearAndMonth(
              { year: new Date().getFullYear(), month: 1 },
              "start"
            ),
            endDate: new Date().valueOf(),
          },
        }),
      ]).then(([data, dataYTD]) => {
        console.log("data", data);
        console.log("data YTD", dataYTD);
        setState({
          ...state,
          fetching: false,
          dataLoaded: true,
          data,
          fetchingYTD: false,
          dataLoadedYTD: true,
          dataYTD,
        });
      });
    },
    [state]
  );

  const onClickFetch = useCallback(() => {
    fetchChartData(
      state.filter.nestromUserID,
      state.filter.startDate,
      state.filter.endDate,
      state.filter.cost_center
    );
    setState({
      ...state,
      fetching: true,
      fetchingYTD: true,
      dataLoaded: false,
      dataLoadedYTD: false,
      data: null,
      dataYTD: null,
      filter: { ...state.filter },
    });
  }, [state, fetchChartData]);

  const filtersUpdated = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log("filterChanged", name, value, event);
    changeFilterState({ [name]: value });
  };

  function changeFilterState(obj) {
    let startDate = getTimeStampFromYearAndMonth(
      { ...state.filter, ...obj },
      "start"
    );
    let endDate = getTimeStampFromYearAndMonth(
      { ...state.filter, ...obj },
      "end"
    );

    setState({
      ...state,
      fetching: false,
      fetchingYTD: false,
      dataLoaded: false,
      dataLoadedYTD: false,
      data: null,
      dataYTD: null,
      filter: { ...state.filter, ...obj, startDate, endDate },
    });
  }

  if (!permissionsLoaded) {
    return (
      <Loading
        loadingPrimary="Loading..."
        loadingSecondary="Loading Permissions"
      />
    );
  }

  if (!getPerm(resource.app.name, resource.app.find)) {
    redirect("/quality-totals");
    return null;
  }

  console.log("state", state);
  return (
    <div>
      <CreateFilters
        filtersUpdated={filtersUpdated.bind(this)}
        changeFilterState={changeFilterState}
        filterValues={state.filter}
        requiredFilters={resource.requiredFilters}
        onClickFetch={onClickFetch}
        width={"100%"}
      />
      <br />
      <h3
        style={{
          padding: "10px",
          marginTop: "10px",
          marginBottom: "10px",
          background: "#e6e6e6",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Data Per Month
      </h3>
      {!state.fetching && !state.dataLoaded && (
        <Alert className={classes.alert} severity="info">
          Select Filters And Click The Apply Button
        </Alert>
      )}
      {state.dataLoaded && <ViewCharts data={state.data} />}
      {state.fetching && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}
      <h3
        style={{
          padding: "10px",
          marginTop: "10px",
          marginBottom: "10px",
          background: "#e6e6e6",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Year To Date
      </h3>
      {!state.fetchingYTD && !state.dataLoadedYTD && (
        <Alert className={classes.alert} severity="info">
          Select User To View Data
        </Alert>
      )}
      {state.dataLoadedYTD && <ViewCharts data={state.dataYTD} />}
      {state.fetchingYTD && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
