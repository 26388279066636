import { withStyles } from "@material-ui/core/styles";
import React from "react";
import {
  EditController,
  NumberInput,
  number,
  minValue,
  ListButton,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CustomSimpleForm from "../customise/CustomSimpleForm";
import CustomToolbar from "../customise/CustomToolbar";
import { KPI_ITEM_SHOW } from "./item_show";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const editStyles = {
  root: { display: "flex", alignItems: "flex-start", width: "100%" },
  form: { flexGrow: 9 },
};

const ShowListAction = ({ basePath, size, title = "KPI Item Details" }) => (
  <Grid container spacing={0}>
    <Grid item xs={5}>
      <span
        style={{
          float: "left",
          marginLeft: "20px",
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "1.3em",
        }}
      >
        {title}
      </span>
    </Grid>
    <Grid item xs={5}></Grid>
    <Grid item xs={2}>
      <span
        style={{ float: "right", marginRight: "20px", marginBottom: "30px" }}
      >
        <ListButton
          basePath={basePath}
          label="Back"
          icon={<ChevronLeft />}
          size={size}
        />
      </span>
    </Grid>
  </Grid>
);

class KPIsEditToStyle extends React.Component {
  render() {
    const FormDiv = withStyles(editStyles)(
      ({ children, classes, actions, ...props }) => {
        return (
          <div className={classes.root}>
            <div className={classes.form}>
              {actions}
              <KPI_ITEM_SHOW {...props} />
              <br />
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <NumberInput
                    source="target"
                    validate={[number(), minValue(0)]}
                    disabled={!props.record.editable}
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberInput
                    source="actual"
                    validate={[number(), minValue(0)]}
                    disabled={!props.record.editable}
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberInput source="performance" disabled />
                </Grid>
              </Grid>
            </div>
          </div>
        );
      }
    );

    return (
      <EditController {...this.props} undoable={false}>
        {({ resource, record, redirect, save, basePath, version, ...rest }) => {
          return (
            <div>
              <Card>
                {record && (
                  <CustomSimpleForm
                    toolbar={
                      <CustomToolbar
                        allowDelete={false}
                        editable={record.editable}
                      />
                    }
                    basePath={basePath}
                    redirect={redirect}
                    resource={resource}
                    record={record}
                    save={save}
                    version={version}
                  >
                    <FormDiv
                      record={record}
                      actions={
                        <ShowListAction basePath={basePath} size="large" />
                      }
                    />
                  </CustomSimpleForm>
                )}
              </Card>
            </div>
          );
        }}
      </EditController>
    );
  }
}

const KPIsEdit = withStyles(editStyles)(KPIsEditToStyle);
export default KPIsEdit;

export { KPIsEdit };
