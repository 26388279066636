import * as React from "react";
import {
  List,
  TextField,
  ShowButton,
  NumberField,
  DateField,
} from "react-admin";
import MyDatagrid from "../customise/CustomDataGrid";
// import exporters from "../helpers/exporters";
import { CommonFilter } from "../helpers/commonFilters";
import { getDefaultTime } from "../helpers/utils";

export const PRSApprovalsList = ({ ...props }) => {
  let { year, month } = getDefaultTime();
  const filtersUpdated = () => {};
  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<CommonFilter filtersUpdated={filtersUpdated.bind(this)} allowEmpty={true} />}
        filterDefaultValues={{ year, month }}
        pagination={false}
        title="PRS Approvals"
        exporter={false}
        // exporter={(d) => exporters(d, "kpis")}
      >
        <MyDatagrid rowClick={"show"}>
          <TextField source="cost_center.name" label="Cost Center" />
          <TextField source="vendor.name" label="Vendor" />
          <NumberField source="year" label="Year" />
          <NumberField source="month" label="Month" />
          <TextField source="status" label="Status" />
          <DateField source="createdAt" label="Created At" />
          <TextField source="approvedBy" label="Approved By" />
          <DateField source="approvedAt" label="Approved At" />
          <ShowButton />
        </MyDatagrid>
      </List>
    </div>
  );
};
