import * as React from "react";

import WithHeaderGroupTable from "../../helpers/WithHeaderGroupTable.js";

const styles = {
  rejected: { backgroundColor: "red", color: "white" },
  approved: { color: "green" },
};

const COLUMNS = [
  {
    Header: "Contract",
    columns: [
      { accessor: "month", Header: "Month" },
      { accessor: "year", Header: "Year" },
      { accessor: "cost_center.name", Header: "Cost Center" },
      { accessor: "vendor.name", Header: "Vendor" },
    ],
  },
  {
    Header: "Approval",
    columns: [
      {
        accessor: "status",
        Header: "Approval Result",
        stylePerCell: ({ row, accessor }) => ({
          ...(styles[row[accessor]] || {}),
          fontWeight: "bold",
        }),
      },
      { accessor: "approvedBy", Header: "Approved By" },
      { accessor: "approvedAt", Header: "Approved At" },
    ],
  },
];

const ApprovalsTable = ({ approvals }) => {
  approvals.forEach(approval => {
    if (approval.approvedAt) approval.approvedAt = new Date(approval.approvedAt).toString()
  });
  let body = <WithHeaderGroupTable columns={COLUMNS} rows={approvals} />;

  return (
    <div style={{ flexGrow: 1 }}>
      {body}
      <br />
    </div>
  );
};

export default ApprovalsTable;
