import React from "react";
import { GET_LIST } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import { resources } from "../provider/configs";
import { dataProvider } from "../provider/provider";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class ResourceSelect extends React.Component {
  state = {
    error: null,
    isLoaded: false,
    options: [
      <MenuItem value="invalid" key="invalid">
        {this.props.default || "-"}
      </MenuItem>,
    ],
    data: [],
    resource: resources[this.props.configResource],
  };

  get() {
    this._asyncRequest = dataProvider(GET_LIST, this.state.resource.name, {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    });
    return this._asyncRequest;
  }

  componentDidMount(p) {
    this._isMounted = true;
    this.reFetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._asyncRequest) {
      this._asyncRequest.cancel && this._asyncRequest.cancel();
    }
  }

  reFetch() {
    if (this.state.isLoaded) this.setState({ isLoaded: false });
    this.get().then(
      ({ data }) => {
        if (!this._isMounted) return;
        this.setState({ isLoaded: true, data });
      },
      (error) => this.setState({ isLoaded: true, error })
    );
  }

  createOptions(data) {
    let arr = (this.props.handleData || ((a) => a))(data);
    return [
      <MenuItem value="invalid" key="invalid">
        {this.props.default || "-"}
      </MenuItem>,
      ...arr.map((ele) => (
        <MenuItem value={ele[this.props.valueToSelect || "id"]} key={ele.id}>
          {ele.name}
        </MenuItem>
      )),
    ];
  }

  render() {
    let { classes, handleChange, value, name, alias } = this.props;
    const { error, isLoaded, data, resource } = this.state;
    alias = alias || resource.alias || resource.name;
    value = value || "invalid";
    console.log("value", value);
    let options = this.createOptions(data);
    if (error) {
      return (
        <FormControl variant="filled" className={classes.formControl} error>
          <InputLabel htmlFor="filled-month-native-simple">{alias}</InputLabel>
          <Select
            name={name}
            onChange={handleChange}
            renderValue={(value) => `⚠️ ${value}`}
            // renderValue={(value) => `⛔ ${value}`}
            value={"Error"}
            disabled
          >
            <MenuItem value="Error" key="Error">
              <span style={{ color: "darkRed" }}>{"Error"}</span>
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else if (!isLoaded) {
      return (
        <FormControl variant="filled" className={classes.formControl} error>
          <InputLabel htmlFor="filled-month-native-simple">{alias}</InputLabel>
          <Select
            name={name}
            onChange={handleChange}
            renderValue={(value) => (
              <>
                <AutorenewIcon />
                {value}
              </>
            )}
            value={"loading"}
            disabled
          >
            <MenuItem value="loading" key="loading">
              <span style={{ color: "darkRed" }}>{"Loading"}</span>
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel htmlFor="filled-month-native-simple">{alias}</InputLabel>
          <Select
            name={name}
            label={name}
            onChange={handleChange}
            value={value}
            disabled={this.props.disabled || false}
          >
            {options}
          </Select>
        </FormControl>
      );
    }
  }
}

export default withStyles(styles)(ResourceSelect);
