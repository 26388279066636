import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  content: {
        top: "50%",
        margin: "auto",
        position: "relative",
        width: "450px",
  },
});

export default (props) => {
  const classes = useStyles();
  return (
    <Box height="100%">
      <p className={classes.content}>
        {"Nestrom Has Been opened in a new tab"}
      </p>
    </Box>
  );
};
