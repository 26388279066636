import React, { useState } from "react";
import { List, NumberField, TextField } from "react-admin";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

import MyDatagrid from "../customise/CustomDataGrid";
import { CommonFilter } from "../helpers/commonFilters";
import { MergeFields } from "../helpers/sharedComponents";
import exporters from '../helpers/exporters'
import {
  getParameterByName,
  getDefaultTime,
  filtersChanged,
  allFilters,
} from "../helpers/utils";
import { authProvider } from "../provider/provider";

const MergeServiceFields = MergeFields(
  "kpi_item.serviceType",
  "kpi_item.serviceArea"
);

const useStyles = makeStyles({
  failed: { color: "red" },
  passed: { color: "green" },
  boldy: { fontWeight: "bold" },
});

const notPass = (perf, target) => perf < Number.parseFloat(target);

const PerformanceField = (props) => {
  const classes = useStyles();
  let fail = notPass(
    props.record["performance"],
    props.record.kpi_item.targetLabel
  );
  return (
    <span
      className={classnames({
        [classes.failed]: fail,
        [classes.passed]: !fail,
        [classes.boldy]: true,
      })}
    >
      {props.record["performance"]} %
    </span>
  );
};

export const KPIsList = ({ ...props }) => {
  let filter = getParameterByName("filter") || "{}";
  filter = JSON.parse(filter);
  let { year, month } = getDefaultTime();

  let [stateFilter, setStateFilter] = useState({
    displayed: allFilters(filter),
    vendor: filter.vendor,
    cost_center: filter.cost_center,
    year: filter.year || year,
    month: filter.month || month,
  });

  function filtersUpdated(filters) {
    if (filtersChanged(filters, stateFilter)) {
      if (!allFilters(filters)) {
        setStateFilter({ ...filters, displayed: false });
      } else {
        setStateFilter({ ...filters, displayed: true });
      }
    }
  }

  const isEditAllowed = () =>
    authProvider.isAllowed(props.permissions, "kpi-item-calculation", "update");
  let total_fields = [{ field: "performance", key: "total_response" }];
  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<CommonFilter filtersUpdated={filtersUpdated} />}
        filterDefaultValues={{ year, month }}
        pagination={false}
        title="KPIs List"
        exporter={d => exporters(d, 'kpis')}
      >
        <MyDatagrid
          rowClick={(id, basePath, record) => !isEditAllowed() || (id && id.match(/future/i)) ? null : "edit"}
          colSpan={3}
          total_fields={total_fields}
          toSum={["actual", "target"]}
        >
          <NumberField source="kpi_item.index" label="#" />
          <MergeServiceFields sortable={false} label="Service Type/Area" />
          <TextField
            sortable={false}
            source="kpi_item.keyActivity"
            label="Key Activity"
          />
          <TextField sortable={false} source="kpi_item.metric" label="Metric" />
          <TextField
            sortable={false}
            source="kpi_item.keyPerformanceIndicators"
            label="Key Performance Indicators"
          />
          <TextField
            sortable={false}
            source="kpi_item.targetLabel"
            label="Target Label"
          />
          <TextField
            sortable={false}
            source="kpi_item.measurement"
            label="Measurement"
          />
          <NumberField
            sortable={false}
            label="Expected Target"
            source="target"
          />
          <NumberField sortable={false} source="actual" label="Actual" />
          <PerformanceField
            sortable={false}
            unit={"%"}
            source="performance"
            lanbel="Performance"
          />
        </MyDatagrid>
      </List>
    </div>
  );
};
