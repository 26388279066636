import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

import ResourceSelect from "../../helpers/ResourceSelect";
import T from "../../translation/translation.js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ListSelectMonth = (props) => {
  const classes = useStyles();

  const YourChoices = [];
  for (let index = 1; index < 13; index++) {
    YourChoices.push([index, `${index}-month-select`, T.translate(index)]);
  }

  let defaultValue = new Date().getMonth() + 1;
  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel htmlFor="filled-month-native-simple">Month</InputLabel>
      <Select
        name="Month"
        defaultValue={defaultValue}
        onChange={props.onChange}
        value={props.filterValues.month || defaultValue}
      >
        {YourChoices.map(([value, key, name]) => (
          <MenuItem value={value} key={key}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ListSelectYear = (props) => {
  const classes = useStyles();

  const yearChoices = [];
  const currentYear = new Date().getFullYear();
  for (let index = 2020; index <= currentYear; index++) {
    yearChoices.push(
      <MenuItem value={index} key={`${index}-year-select`}>
        {index}
      </MenuItem>
    );
  }

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel htmlFor="filled-year-native-simple">Year</InputLabel>
      <Select
        name="Year"
        defaultValue={currentYear}
        onChange={props.onChange}
        value={props.filterValues.year}
      >
        {yearChoices}
      </Select>
    </FormControl>
  );
};

const ListSelectV = (props) => {
  return (
    <ResourceSelect
      name={"vendor"}
      handleChange={props.onChange}
      value={props.filterValues.vendor}
      configResource={"vendors"}
    />
  );
};

const ListSelectCC = (props) => {
  return (
    <ResourceSelect
      name={"cost_center"}
      handleChange={props.onChange}
      value={props.filterValues.cost_center}
      configResource={"cost_centers"}
    />
  );
};

function allFilters(requiredFilters, filterValues) {
  return requiredFilters.reduce((acc, ele) => acc && filterValues[ele] && filterValues[ele] !== 'invalid', true);
}

const CreateFilters = ({
  requiredFilters,
  filtersUpdated,
  filterValues,
  onClickFetch,
  width,
}) => {
  const classes = useStyles();
  let allFiltersSelected = allFilters(requiredFilters, filterValues)
  console.log('allFiltersSelected', allFiltersSelected, requiredFilters, JSON.stringify(filterValues))

  return (
    <div style={{ width }}>
      {requiredFilters.indexOf("cost_center") !== -1 && (
        <ListSelectCC onChange={filtersUpdated} filterValues={filterValues} />
      )}
      {requiredFilters.indexOf("vendor") !== -1 && (
        <ListSelectV onChange={filtersUpdated} filterValues={filterValues} />
      )}
      {requiredFilters.indexOf("year") !== -1 && (
        <ListSelectYear onChange={filtersUpdated} filterValues={filterValues} />
      )}
      {requiredFilters.indexOf("month") !== -1 && (
        <ListSelectMonth
          onChange={filtersUpdated}
          filterValues={filterValues}
        />
      )}

      <FormControl
        variant="filled"
        className={classes.formControl}
        style={{ float: "right" }}
      >
        <Button
          aria-label="Apply Filter"
          onClick={onClickFetch}
          variant="outlined"
          color="primary"
          size="large"
          style={{minHeight: '56px'}}
          disabled={!allFiltersSelected}
          startIcon={<SearchIcon />}
        >
          Apply Filter
        </Button>
      </FormControl>
    </div>
  );
};

export default CreateFilters;
