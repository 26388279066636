import React from "react";
import ItemTable from "../helpers/simple_table";
import {
  TopToolbar,
  NumberField,
  TextField,
  SimpleShowLayout,
  Show,
  ListButton,
} from "react-admin";
import * as _ from "lodash";

const headerRow1 = [
  { id: "fm_review_group.label", label: "Group" },
  { id: "SN", label: "S/N" },
  { id: "description", label: "description" },
];
const tableHeader1 = headerRow1.map((r) => r["label"]);

export const FMReviewItemShow = function ({ record }) {
  let rows = [
    {
      isHeader: true,
      vals: tableHeader1,
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => _.result(record, r["id"])),
    },
  ];
  return <ItemTable rows={rows} />;
};

export const FM_REVIEW_ITEM_SHOW = (props) => {
  return <FMReviewItemShow record={props.record.fm_review_item} />;
};



const FMReviewsShowActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="List" />
  </TopToolbar>
);

export const FMReviewShow = (props) => {
  return (
    <Show
      actions={<FMReviewsShowActions />}
      title="FMReview Item Data"
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="fm_review_item.SN" />
        <TextField source="fm_review_item.description" />
        <NumberField source="target" />
        <NumberField source="actual" />
        <NumberField source="performance" />
        <TextField source="Justification" />
      </SimpleShowLayout>
    </Show>
  );
};

export default { FM_REVIEW_ITEM_SHOW, FMReviewItemShow, FMReviewShow };
