import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatCurrency } from "../../helpers/utils";
import WithHeaderGroupTable from "../../helpers/WithHeaderGroupTable.js";

const useStyles = makeStyles({ container: { width: "40%" } });

const COLUMNS = [
  {
    Header: "Deduction Summary",
    columns: [
      {
        stylePerCell: ({ row }) =>
          row.type === "Total Deduction" ? { fontWeight: "bold" } : {},
        accessor: "type",
        Header: "Type",
      },
      {
        stylePerCell: ({ row }) =>
          row.type === "Total Deduction" ? { fontWeight: "bold" } : {},
        accessor: "value",
        Header: "Deduction",
      },
    ],
  },
];

const KPI_Deduction = ({ record }) => {
  const classes = useStyles();
  let rows = record["deduction_per_types"].map((o) => {
    o.value_original = o.value;
    o.value = formatCurrency(o.value, "AED");
    return o;
  });
  rows.push({
    id: "Total Deduction",
    type: "Total Deduction",
    value: formatCurrency(record["total"], "AED"),
  });

  return (
    <div className={classes.container}>
      <WithHeaderGroupTable columns={COLUMNS} rows={rows} />
    </div>
  );
};

export default KPI_Deduction;
