import React from "react";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GET_LIST } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import ApprovalsTable from "./ApprovalsTable";
import { dataProvider, authProvider } from "../../provider/provider";
import { resources } from "../../provider/configs";
import SendAnyWay from "./SendAnyWay";
import TaskCompletionRate from "./taskCompletionRate";
import SendToIviva from "./PRS_Review";

const configResource = resources["prs-approvals"];
const task_completion_rate_resource = resources["task_completion_rate"];
const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    textAlign: "center",
  },
  button: {
    "&:hover": { backgroundColor: "darkGreen" },
    backgroundColor: "darkGreen",
  },
  closeButton: {
    float: "right",
    margin: "auto",
  },
  modaleTitle: {
    textAlign: "center",
    float: "left",
    color: "#8f8b7f",
    width: "90%",
  },
});

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

class SendResultTo extends React.Component {
  state = {
    open: false,
    error: null,
    success: null,
    loading: false,
    filter: {
      vendor: this.props.filter.vendor,
      cost_center: this.props.filter.cost_center,
      year: this.props.filter.year,
      month: this.props.filter.month,
    },
    approvals: null,
    completionRateSummary: null,
  };

  handleOpen() {
    this.setState({ /* loading: true, */ open: true });
    // this.fetchApprovals();
  }

  getApprovalsRequest(filter) {
    this._asyncRequest = dataProvider(GET_LIST, configResource.name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: { ...(filter || this.state.filter), deleted: false },
    });
    return this._asyncRequest;
  }

  handleClose(confirm) {
    console.log({ confirm });
    if (confirm === "Ok") {
      let that = this;
      that.setState({ loading: true, open: true });
      console.log("creating approval for ", this.state.filter);
      dataProvider("CUSTOM", configResource.name, {
        method: "POST",
        data: {
          ...this.state.filter,
          snapshot: this.props.data,
          status: "pending",
        },
      })
        .then((re) => {
          if (!this._isMounted) return;
          console.log("re", re);
          that.props.setLastUpdate(new Date());
          that.setState({ loading: false, open: true, success: re.data });
        })
        .catch((err) => {
          console.log({ err });
          if (!this._isMounted) return;
          that.setState({
            loading: false,
            open: true,
            error: ((err || {}).body || {}).message || `Approval Creation Failed`,
          });
        });
    } else {
      this.setState({ open: false, error: null, success: null, loading: false });
    }
  }

  stateChanged(obj1, obj2) {
    return (
      obj1.cost_center !== obj2.cost_center ||
      obj1.vendor !== obj2.vendor ||
      obj1.year !== obj2.year ||
      obj1.month !== obj2.month
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchApprovals();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._asyncRequest) {
      this._asyncRequest.cancel && this._asyncRequest.cancel();
    }
  }

  getTaskCompletionRate(filter) {
    return dataProvider(GET_LIST, task_completion_rate_resource.name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: { ...(filter || this.state.filter) },
    });
  }

  fetchApprovals(filter) {
    if (this.state.loading)
      this.setState({ loading: false, error: null, success: null });
    this.getApprovalsRequest(filter).then(
      async (approvals) => {
        if (!this._isMounted) return;
        let completionRateSummary = await this.getTaskCompletionRate(filter);
        this.setState({
          loading: false,
          error: null,
          approvals: approvals.data,
          completionRateSummary,
        });
      },
      (error) =>
        this.setState({
          loading: false,
          error,
          approvals: null,
          completionRateSummary: null,
        })
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.stateChanged(props.filter, this.state.filter)) {
      this.setState({
        success: null,
        filter: { ...props.filter },
      });
      this.fetchApprovals(props.filter);
    }
  }

  isResultApproved() {
    let approvals = this.state.approvals;
    if (!approvals) return false;
    if (!approvals.length) return false;
    return approvals[approvals.length - 1].status == "approved";
  }

  isPendingApproval() {
    return !!(this.state.approvals || []).find((a) => a.status == "pending");
  }

  isFuture() {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const isFuture =
      (this.props.filter.month >= currentMonth &&
        this.props.filter.year == currentYear) ||
      this.props.filter.year > currentYear;

    return isFuture;
  }

  render() {
    const { classes, permissions, data, filter, setLastUpdate } = this.props;
    let { error, loading, open, approvals, success, completionRateSummary } =
      this.state;

    let allowed =
      authProvider.isAllowed(
        permissions,
        resources.send_to_iviva_update.app.name,
        resources.send_to_iviva_update.app.find
      ) &&
      authProvider.isAllowed(
        permissions,
        configResource.app.name,
        configResource.app.find
      ) &&
      authProvider.isAllowed(
        permissions,
        task_completion_rate_resource.app.name,
        task_completion_rate_resource.app.find
      );

    let isPersisted = !!Object.keys(data).find(
      (ele) => data[ele].isPersisted_response && !data[ele].isApproval_response
    );
    let noData = Object.keys(data).length === 0;

    let isFuture = this.isFuture();
    let isPendingApproval = this.isPendingApproval();
    let isResultApproved = this.isResultApproved();
    let disabled = !!(!allowed || isPersisted || noData || isFuture);

    let bodyHeader = (
      <div>
        <h2 id="simple-modal-title">
          <span className={classes.modaleTitle}>PRS Review</span>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => this.handleClose("Cancel")}
            size="small"
          >
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <br />
        <span
          style={{
            marginLeft: "20%",
            marginTop: "15px",
            marginBottom: "15px",
            float: "left",
            width: "50%",
            borderTop: "0.5px solid #aca591",
          }}
        />
        <br />
      </div>
    );
    let body = null;

    if (success) {
      body = (
        <div>
          <h2 style={{ textAlign: "center" }}>{success.status}</h2>
          <div
            style={{
              top: "50%",
              margin: "auto",
              position: "relative",
              width: "100%",
              textAlign: "center",
            }}
          >
            <CheckCircleOutlineIcon style={{ fontSize: 100, color: "green" }} />
          </div>
          <br />
          <br />
        </div>
      );
    } else if (error) {
      console.log("error, ", error, error.message);
      body = (
        <div>
          <br />
          <h2 style={{ textAlign: "left" }}>Error: {error.message || error}</h2>
          <div
            style={{
              top: "50%",
              margin: "auto",
              position: "relative",
              width: "100%",
              textAlign: "center",
            }}
          >
            <CancelOutlinedIcon style={{ fontSize: 100, color: "red" }} />
          </div>
        </div>
      );
    } else if (loading) {
      body = (
        <div>
          <br />
          <h2>{!approvals ? "Fetching..." : "Sending ..."}</h2>
          <div
            style={{
              top: "50%",
              margin: "auto",
              position: "relative",
              width: "80%",
            }}
          >
            <LinearProgress />
          </div>
          <br />
          <br />
        </div>
      );
    } else {
      body = (
        <div>
          <ApprovalsTable
            filter={filter}
            approvals={approvals}
            message={"No Approvals Yet!"}
          />
          {!isPendingApproval && !isResultApproved && (
            <TaskCompletionRate
              handler={this.handleClose.bind(this)}
              summary={completionRateSummary}
            />
          )}

          <SendAnyWay
            disabled={isPendingApproval || isResultApproved}
            handler={this.handleClose.bind(this)}
            message={"Are you sure you want to send the PRS Approval?"}
            label={"Request Approval"}
          />

          <SendToIviva
            setLastUpdate={setLastUpdate}
            data={data}
            filter={filter}
            disabled={!isResultApproved || isPersisted}
          />
        </div>
      );
    }
    return (
      <div>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<SendIcon />}
          disabled={disabled}
          onClick={this.handleOpen.bind(this)}
        >
          {isPersisted
            ? "PRS Submitted"
            : !approvals
            ? "Fetching"
            : isResultApproved
            ? "Send To Iviva"
            : isPendingApproval
            ? "Pending Approval (show)"
            : "Send To Approval"}
        </Button>
        <Modal
          open={open}
          onClose={() => this.handleClose("Cancel")}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            {bodyHeader}
            {body}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SendResultTo);
