import * as React from "react";
import Card from "@material-ui/core/Card";
import {
  TopToolbar,
  TextField,
  // EditButton,
  NumberField,
  SimpleShowLayout,
  ListButton,
} from "react-admin";

import { getParameterByName, getWithExpiry } from "../helpers/utils";
import ExpandQuality from "./ExpandQuality";

const QualityShowActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

export const QualityShow = () => {
  let zoneId = getParameterByName("zoneId");
  let [record] = React.useState(getWithExpiry(zoneId));
  console.log("record", record);
  if (!zoneId || !record) return null;

  return (
    <>
      <QualityShowActions basePath={"/quality-totals"} />
      <Card>
        <SimpleShowLayout record={record} id={record._id}>
          <TextField label="Zone" source="zoneId" />
          <TextField label="Description" source="zoneName" />
          <NumberField label="Criticality" source="criticality" />
          <NumberField
            label="Exponential Criticality"
            source="exponential_criticality"
          />
          <NumberField label="Value To Score" source="value_to_score" />
          <NumberField label="Technical Audit" source="technicalAudit" />
          <NumberField label="Joint Audit" source="jointAudit" />
          <NumberField label="Snap Audit" source="snapAudit" />
          <NumberField label="Total Quality" source="total_q" />
          <NumberField label="Total Percentage" source="total" />
        </SimpleShowLayout>
      </Card>
      <ExpandQuality record={record} />
    </>
  );
};

export default QualityShow;
