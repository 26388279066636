import * as React from "react";
import { Query, GET_ONE, Error } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { SLA_ITEM_SHOW } from "../../slas";
import { KPI_ITEM_SHOW } from "../../kpis";
import { OER_ITEM_SHOW } from "../../oer";
import { SPPV_ITEM_SHOW } from "../../sppv";
import { OPM_ITEM_SHOW } from "../../opm";
import { RegulatoryComplianceItemShow } from "../../regulatoryCompliance";
import KPI_Deduction from "./kpi-deduction";
import CommentDetails from "./comment";

const useStyles = makeStyles({
  faded: { color: "#999" },
  deleted: {
    backgroundColor: "red",
    color: "white !important",
    fontWeight: "bold",
    padding: "10px",
  },
  inlineBlock: { marginBottom: "0px", display: "inline-block" },
});

const EXECLUDED_KEYS = {
  __v: true,
  _id: true,
  id: true,
  updatedAt: true,
  createdAt: true,
  cost_center: true,
  vendor: true,
  year: true,
  month: true,
};

const MATCHES = {
  sppv: SPPV_ITEM_SHOW,
  oer: OER_ITEM_SHOW,
  'regulatory-compliance': RegulatoryComplianceItemShow,
  opm: OPM_ITEM_SHOW,
  sla: SLA_ITEM_SHOW,
  kpi: KPI_ITEM_SHOW,
  "deduction-summary": KPI_Deduction,
  comments: CommentDetails,
  'send_PRS_Result_To_IVIVA': ({record}) => {
    return null
  }
};

function GetData({ record, k }) {
  console.log("GetData", record, k);
  switch (typeof record[k]) {
    case "object":
      console.log("OBJ", record[k], k);
      if (Array.isArray(record[k])) {
        console.log("arr", record[k], k);
        let d = record[k].map((f, i) => (
          <ul>
            <GetData key={`${k}-${f._id || i + 1}`} record={record[k]} k={i} />
          </ul>
        ));
        console.log("d", d);
        return d;
      }
      return <LogItemWithKeys record={record[k]} />;

    default:
      return <>{record[k]}</>;
  }
}

const LogItemWithKeys = ({ record, type }) => {
  if (!record) return null;

  const Comp = MATCHES[type];
  if (Comp) {
    return <Comp record={record} />;
  }

  let ks = Object.keys(record).filter((k) => !EXECLUDED_KEYS[k]);
  if (ks.length === 0) return null;
  if (ks.length === 1) {
    return (
      <p>
        <strong>{`${ks[0]} : `}</strong>
        {record[ks[0]]}
      </p>
    );
  }
  return (
    <ol>
      {ks.map((k, i) => (
        <li key={`current-doc-data-${i}`}>
          <strong>{`${k} : `}</strong>
          <GetData record={record} k={k} />
        </li>
      ))}
    </ol>
  );
};

const LogsExpand = ({ record }) => {
  const classes = useStyles();
  return (
    <Query
      type={GET_ONE}
      resource={record.refPath}
      payload={{ id: record.refId }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return "Loading...";
        }
        if (error) {
          return (
            <Error
              error={error}
              classes={{}}
              className={classes.deleted}
              errorInfo={{}}
              title={error.message || "Something went wrong!"}
            />
          );
        }
        if (data.month && data.month > 12) data.deleted = true;
        return (
          <div className={data.deleted ? classes.deleted : ""}>
            <h2 className={classes.inlineBlock}>
              Datails: {data.deleted ? "This Document Has Been Deleted" : ""}
            </h2>
            {
              <ul>
                {data.cost_center ? (
                  <li>
                    <strong>{"Cost Center:"}</strong>
                    {data.cost_center.name}
                  </li>
                ) : null}
                {data.vendor ? (
                  <li>
                    <strong>{"Vendor:"}</strong>
                    {data.vendor.name}
                  </li>
                ) : null}
                {data.year || data.month ? (
                  <li>
                    <strong>{"Month/Year:"}</strong>
                    {`${data.month || "-"} / ${data.year || "-"}`}
                  </li>
                ) : null}
              </ul>
            }
            <LogItemWithKeys record={data} type={record.type} />
          </div>
        );
      }}
    </Query>
  );
};

export default LogsExpand;
