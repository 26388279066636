import React, { useState } from "react";
import { List, NumberField, TextField } from "react-admin";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

import MyDatagrid from "../customise/CustomDataGrid";
import { CommonFilter } from "../helpers/commonFilters";
// import exporters from "../helpers/exporters";
import SPPVsSummary from "./sppv_summary";
import { getParameterByName, getDefaultTime, filtersChanged, allFilters } from "../helpers/utils";

const useStyles = makeStyles({
  failed: { color: "red" },
  passed: { color: "green" },
  boldy: { fontWeight: "bold" },
});

const notPass = (perf, target) => perf < Number.parseFloat(target);

const PerformanceField = (props) => {
  const classes = useStyles();
  let fail = notPass(props.record["performance"], props.record.sppv_item.targetLabel);
  return (
    <span
      className={classnames({
        [classes.failed]: fail,
        [classes.passed]: !fail,
        [classes.boldy]: true,
      })}
    >
      {props.record["performance"]} %
    </span>
  );
};

const rowStyle = (record, index) => {
  if (record.isGroupHeader) {
    return {
      height: "2em",
      backgroundColor: "#dde6e9",
      textAlign: "center",
      fontSize: "2em",
      fontWeight: "bold",
    };
  }
  if (record.actual !== record.target) {
    return { backgroundColor: "#ffdab3" };
  }
  return {};
};

export const SPPVsList = ({ ...props }) => {
  let filter = getParameterByName("filter") || "{}";
  filter = JSON.parse(filter);
  let { year, month } = getDefaultTime();

  let [stateFilter, setStateFilter] = useState({
    displayed: allFilters(filter),
    vendor: filter.vendor,
    cost_center: filter.cost_center,
    year: filter.year || year,
    month: filter.month || month,
  });

  function filtersUpdated(filters) {
    if (filtersChanged(filters, stateFilter)) {
      if (!allFilters(filters)) {
        setStateFilter({ ...filters, displayed: false });
      } else {
        setStateFilter({ ...filters, displayed: true });
      }
    }
  }

  let total_fields = [{ field: "performance", key: "total_response" }];
  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<CommonFilter filtersUpdated={filtersUpdated} />}
        filterDefaultValues={{ year, month }}
        pagination={false}
        title="SPPVs List"
        exporter={false}
        // exporter={(d) => exporters(d, "sppvs")}
      >
        <MyDatagrid
          groupby={"sppv_item.sppv_group.label"}
          rowStyle={rowStyle}
          colSpan={3}
          total_fields={total_fields}
          toSum={["actual", "target"]}
          AfterLoadComponents={<SPPVsSummary />}
        >
          <TextField source="sppv_item.SN" label="S/N" />
          <TextField sortable={false} source="sppv_item.Area" label="Area" />
          <TextField sortable={false} source="sppv_item.PerformanceAssessmentPeriod" label="Performance Assessment Period" />
          <NumberField sortable={false} source="target" label="Rating Target" />
          <NumberField sortable={false} source="actual" label="Actual Points" />
          <TextField sortable={false} source="Justification" label="Justification" />
          <PerformanceField sortable={false} unit={"%"} source="performance" label="Performance" />
        </MyDatagrid>
      </List>
    </div>
  );
};
