import * as React from "react";
import { SelectInput, ReferenceInput, Filter } from "react-admin";
import FormControl from "@material-ui/core/FormControl";
import { Form } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { resources } from "../provider/configs";

// import PropTypes from 'prop-types';
const dateInputUseStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ListSelectMonth = (props) => {
  const YourChoices = [];
  for (let index = 1; index < 13; index++) {
    YourChoices.push({ id: index, name: `${index}` });
  }
  return (
    <SelectInput
      label="Month"
      {...props}
      choices={YourChoices}
      allowEmpty={!!props.allowEmpty}
    />
  );
};

const ListSelectYear = (props) => {
  const YourChoices = [];
  let y = new Date().getFullYear();
  for (let index = 2020; index <= y; index++) {
    YourChoices.push({ id: index, name: `${index}` });
  }
  return (
    <SelectInput
      label="Year"
      {...props}
      choices={YourChoices}
      allowEmpty={!!props.allowEmpty}
    />
  );
};

const ListSelectCC = (props) => {
  let filter = {};
  if (props.calculationMethod) {
    filter.calculationMethod = props.calculationMethod;
  }
  return (
    <ReferenceInput
      label="Cost Center"
      emptyValue={null}
      source="cost_center"
      reference="cost-centers"
      filter={filter}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};

const ListSelectV = (props) => {
  return (
    <ReferenceInput
      label="Vendor"
      emptyValue={null}
      source="vendor"
      reference="vendors"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};

const calculationMethodByResourceName = Object.keys(resources).reduce((acc, key) => {
  if (resources[key].calculationMethod) {
    acc[resources[key].name] = resources[key].calculationMethod
  }
  return acc;
}, {});

const CommonFilter = ({ filtersUpdated, allowEmpty=false, ...props }) => {
  let { filterValues } = props;
  if (filtersUpdated) filtersUpdated(filterValues);
  return (
    <Filter {...props}>
      <ListSelectCC
        label="Cost Center"
        source="cost_center"
        alwaysOn
        allowEmpty={allowEmpty}
        calculationMethod={calculationMethodByResourceName[props.resource]}
      />
      <ListSelectV label="Vendor" source="vendor" alwaysOn allowEmpty={allowEmpty} />
      <ListSelectYear label="Year" source="year" alwaysOn allowEmpty={allowEmpty} />
      <ListSelectMonth label="Month" source="month" alwaysOn allowEmpty={allowEmpty} />
    </Filter>
  );
};

// CommonFilter.PropTypes = {
//     filtersUpdated: PropTypes.func
// }

const YearMonthSelect = ({ onChange, filterValues }) => {
  const classes = dateInputUseStyles();

  return (
    <FormControl
      variant="filled"
    >
      <Form
        onSubmit={() => console.log("YearMonthSelect submit form")}
        render={() => {
          return (
            <div>
              <ListSelectYear
                id="year"
                type="date"
                name="year"
                className={classes.textField}
                value={filterValues.year}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={onChange}
                label="Year"
                source="year"
                alwaysOn
              />
              <ListSelectMonth
                id="month"
                type="date"
                name="month"
                className={classes.textField}
                value={filterValues.month}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={onChange}
                label="Month"
                source="month"
                alwaysOn
              />
            </div>
          );
        }}
      ></Form>
    </FormControl>
  );
};

const filters = {
  YearMonthSelect,
  ListSelectMonth,
  ListSelectYear,
  ListSelectCC,
  ListSelectV,
  CommonFilter,
};

export default filters;

export {
  YearMonthSelect,
  ListSelectMonth,
  ListSelectYear,
  ListSelectCC,
  ListSelectV,
  CommonFilter,
};
