import * as React from "react";
import { List, NumberField, TextField } from "react-admin";
import Grid from "@material-ui/core/Grid";

import { CommonFilter } from "../helpers/commonFilters";
import MyDatagrid from "../customise/CustomDataGrid";
import { TypesTable } from "./configTable";
import {
  getParameterByName,
  getDefaultTime,
  filtersChanged,
  allFilters,
  // setWithExpiry,
} from "../helpers/utils";
import { PercentagesField } from "../helpers/sharedComponents";
import exporters from "../helpers/exporters";
// import ExpandQuality from './ExpandQuality'

class QualityAuditsList extends React.Component {
  constructor(props) {
    super(props);
    let filter = getParameterByName("filter") || "{}";
    filter = JSON.parse(filter);
    let { year, month } = getDefaultTime();
    this.state = {
      displayed: !!(filter.vendor && filter.cost_center),
      filter: {
        vendor: filter.vendor,
        cost_center: filter.cost_center,
        year: filter.year || year,
        month: filter.month || month,
      },
    };
  }

  filtersUpdated(filters) {
    if (filtersChanged(filters, this.state.filter)) {
      this.setState({
        displayed: allFilters(filters),
        filter: { ...filters },
      });
    }
  }

  render() {
    let props = this.props;
    console.log("this.state", this.state);
    return (
      <>
        <List
          {...props}
          bulkActionButtons={false}
          filters={
            <CommonFilter filtersUpdated={this.filtersUpdated.bind(this)} />
          }
          filterDefaultValues={{ ...this.state.filter }}
          pagination={null}
          title="Quality Audits"
          exporter={(data) => exporters(data, "Quality Audits")}
        >
          <MyDatagrid
            // rowClick={(id, basePath, record) => {
            //   setWithExpiry(record.zoneId, record, 3600000);
            //   return `${basePath}/custom?zoneId=${record.zoneId}`;
            // }}
            // rowClick={"show"}
            // rowClick={"expand"}
            // expand={ExpandQuality}
            total_fields={[{ field: "total", key: "total_response" }]}
            colSpan={2}
            toSum={["value_to_score"]}
          >
            <TextField label="Zone" source="zoneId" width={"70"} />
            <TextField label="Description" source="zoneName" />
            <PercentagesField
              bold={false}
              label="Zone Percent"
              source="value_to_score"
              unit={"%"}
            />
            <NumberField label="Criticality" source="criticality" />
            <NumberField
              label="Exponential Criticality"
              source="exponential_criticality"
            />
            <PercentagesField label="Technical Audit" source="technicalAudit" />
            <PercentagesField label="Joint Audit" source="jointAudit" />
            <PercentagesField label="Snap Audit" source="snapAudit" />
            <PercentagesField label="Total Quality" source="total_q" />
            <PercentagesField
              unit={"%"}
              label="Total Percentage"
              source="total"
            />
          </MyDatagrid>
        </List>
        {this.state.displayed && (
          <div style={{ flexGrow: 1 }}>
            {/* <div className={this.classes.root}> */}
            <h1>Configuration Table</h1>
            <Grid container spacing={10}>
              <Grid item xs={5}>
                <TypesTable filters={{ ...this.state.filter }} />
              </Grid>
              <Grid item xs={7}></Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }
}

export default QualityAuditsList;

export { QualityAuditsList };
