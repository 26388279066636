import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import configs from './configs'

export const ncrEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Cost Center" source="cost_center" reference="cost-centers" disabled >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Vendor" source="vendor" reference="vendors" disabled >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <NumberInput source="year" disabled />
            <NumberInput source="month" disabled />
            <TextInput source="totalPercentage" disabled />
            <TextInput source="penaltyAmount" />

            <ArrayInput label="Total NCRs Per Category" source="totalNCRsPerCategory">
                <SimpleFormIterator>
                    <NumberInput label="Total Percentage" source="totalPercentage" />
                    <SelectInput label="Category Name" source="categoryName" choices={configs.categories.map(e => ({id: e, name: e}))} />
                </SimpleFormIterator>
            </ArrayInput>


        </SimpleForm>
    </Edit>
);

export default ncrEdit;