import React from "react";
import { GET_LIST } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import { resources } from "../provider/configs";
import { dataProvider } from "../provider/provider";

const styles = {
  hide: { display: "none" },
};

class RefreshableTable extends React.Component {
  constructor(props) {
    super(props);
    let { filters, columns, resource, handleFetchedData, setPayloadFilter } = props;
    this.handleFetchedData = handleFetchedData;
    this.configResource = resources[resource];
    this.state = {
      error: null,
      isLoaded: false,
      columns,
      rows: [],
      filter: setPayloadFilter({...filters}),
    };
  }

  get(newFilters) {
    let { defaultSort = {}, name } = this.configResource
    return dataProvider(GET_LIST, name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: (defaultSort.field || "id"), order: defaultSort.order || "DESC" },
      filter: newFilters || { ...this.state.filter },
    });
  }

  componentDidMount() {
    this._isMounted = true
    this.reFetch();
  }
  
  componentWillUnmount() {
    this._isMounted = false
    if (this._asyncRequest && this._asyncRequest.cancel) {
      this._asyncRequest.cancel();
    }
  }

  reFetch(newFilters) {
    if (this.state.isLoaded) this.setState({ isLoaded: false, error: false });
    this._asyncRequest = this.get(newFilters).then(
      (res) => {
        let { data } = res;
        if(!this._isMounted) return;
        let newRows = this.handleFetchedData(data);
        if(newRows) this.setState({ isLoaded: true, rows: newRows });
      },
      (error) => this.setState({ isLoaded: true, error, rows: [] })
    );
  }

  UNSAFE_componentWillReceiveProps(p) {
    if (this.state.isLoaded) {
      let refresh = p.shouldRefresh(this.state.filter)
      if(refresh) {
        let newFilters = p.setPayloadFilter({...p.filters})
        this.setState({ filter: newFilters })
        this.reFetch(newFilters);
      }
    }
  }

  render() {
    const { error, isLoaded, rows, columns } = this.state;
    const { Table } = this.props;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div
          style={{
            top: "50%",
            margin: "auto",
            position: "relative",
            width: "50px",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return <Table columns={columns} rows={rows} />;
    }
  }
}

export default withStyles(styles)(RefreshableTable);
