import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  pass: {
    borderRadius: 8,
    backgroundColor: 'darkGreen',
  },
  notPass: {
    borderRadius: 8,
    backgroundColor: 'darkRed',
  },
});

function LinearProgressWithLabel({ value, isValidPercentage }) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          size={40}
          thickness={10}
          variant="determinate"
          value={value}
          className={isValidPercentage ? classes.pass : classes.notPass }
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color={isValidPercentage ? "textSecondary" : "error"}
        >
          {`${value.toFixed(2)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
