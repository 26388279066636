import React, { useState } from "react";
import { List, TextField } from "react-admin";
import { Redirect } from "react-router";
import CommentsAside from "./Comments";
import MyDatagrid from "../customise/CustomDataGrid";
import { getParameterByName, getDefaultTime } from "../helpers/utils";
import { CommonFilter } from "../helpers/commonFilters";
import { PercentagesField } from "../helpers/sharedComponents";
import AfterLoadComponents from "./afterLoadComponents";
import RedirectButton from "./redirectButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { resources } from "../provider/configs";
import { authProvider } from "../provider/provider";
import { allFilters } from "../helpers/utils";
import TotalAction from "./TotalAction";

const redirections = {
  "Quality Audits": resources.quality.name,
  "Framework SLA Value": resources.slas.name,
  "Key Performance Indicators": resources.kpis.name,
  "Non-Conformance Reports": resources.ncrs.name,
  "Output Performance Measurement Value": resources.opms.name,
  "Quality Assurance Checklist Score Calculation": resources.qaas.name,
  "Service Provider Performance Value": resources.sppvs.name,
  "FM Operations Standard/Review Performance Value": resources.fmReviews.name,
  "Overall Effectiveness Rating Value": resources.oers.name,
  "Regulatory Compliance": resources.regulatoryCompliance.name,
};

const TotalsList = (props) => {
  let urlFilter = getParameterByName("filter") || "{}";
  urlFilter = JSON.parse(urlFilter);

  let isAllFiltersSelected = allFilters(urlFilter);
  let [stateFilter, setFilter] = useState({
    filters: { ...urlFilter },
    displayed: isAllFiltersSelected,
  });
  let [redirect, setRedirect] = useState(null);
  let [lastUpdate, setLastUpdate] = useState(0);

  function filtersUpdated(filters) {
    let all_filters = allFilters(filters);

    if (!all_filters && stateFilter.displayed) {
      setFilter({ filters: { ...filters }, displayed: false });
    } else if (all_filters && !stateFilter.displayed) {
      setFilter({ filters: { ...filters }, displayed: true });
    }
  }

  function createLink(record) {
    let to = redirections[record.type];
    let q = "&order=ASC&page=1&perPage=10&sort=id";
    let link = `${to}?filter=${encodeURIComponent(
      JSON.stringify({
        cost_center: stateFilter.filters.cost_center,
        vendor: stateFilter.filters.vendor,
        year: stateFilter.filters.year,
        month: stateFilter.filters.month,
      })
    )}${q}`;
    return link;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  let { year, month } = getDefaultTime();
  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        pagination={false}
        actions={(() => <TotalAction setLastUpdate={setLastUpdate} permissions={props.permissions} />)()}
        filters={<CommonFilter filtersUpdated={filtersUpdated} />}
        filterDefaultValues={{ year, month }}
        title={resources.totals.alias}
      >
        <MyDatagrid
          Aside={
            authProvider.isAllowed(
              props.permissions,
              resources.comments.app.name,
              resources.comments.app.find
            ) ? (
              <CommentsAside permissions={props.permissions} />
            ) : null
          }
          AfterLoadComponents={
            <AfterLoadComponents
              setLastUpdate={setLastUpdate}
              lastUpdate={lastUpdate}
              permissions={props.permissions}
            />
          }
          total_fields={[{ field: "rawPercentage", key: "total_response" }]}
          colSpan={1}
          // toSum={['valueToScore']}
          rowClick={(id, basePath, record) => setRedirect(createLink(record))}
        >
          <TextField source="type" />
          <PercentagesField source="valueToScore" unit={"%"} />
          <PercentagesField
            cellformat={({ record }) => ({ color: (record.colorConfigs || {}).fontColor, backgroundColor: (record.colorConfigs || {}).backgroundColor })}
            unit={"%"}
            label="PRS Percentage"
            source="rawPercentage"
          />
          <PercentagesField source="percentage" unit={"%"} />
          <RedirectButton
            alignIcon={"right"}
            icon={<ArrowForwardIosIcon />}
            createLink={createLink.bind(this)}
            onClick={(l) => setRedirect(l)}
          />
        </MyDatagrid>
      </List>
      {/* {displayed && <ConfigTables filter={filter}/>} */}
    </div>
  );
};

export default TotalsList;

export { TotalsList };
