import React from "react";
import { Admin, Resource } from "react-admin";

import i18nProvider from "./translation/translation.js";
import { dataProvider, authProvider } from "./provider/provider";

import { LogIn } from "./loginPage";
import CustomLayout from "./customise/customLayout";
import customRoutes from "./customise/customRoutes";
import { ncrsList, ncrShow, ncrEdit } from "./ncrs";
import { OERsList, OERsEdit, OERShow } from "./oer";
import { SPPVsList, SPPVsEdit, SPPVShow } from "./sppv";
import { FMReviewsList, FMReviewsEdit, FMReviewShow } from "./fmReview";
import { OPMsList, OPMsEdit, OPM_ITEM_SHOW } from "./opm";
import { QAAsList, QAAsEdit, QAA_ITEM_SHOW } from "./qaa";
import { RegulatoryCompliancesList, RegulatoryCompliancesEdit, RegulatoryComplianceItemShow } from "./regulatoryCompliance";
import { SLAsList, SLAsEdit, SLA_ITEM_SHOW } from "./slas";
import { TotalsList } from "./Total";
import { KPIsList, KPIsEdit, KPI_ITEM_SHOW } from "./kpis";
import { ConstCentersList, CostCenterShow } from "./cost-centers";
import { VendorsList, VendorShow, VendorEdit } from "./vendors";
import { QualityAuditsList, QualityAuditsShow } from "./QualityAudits";
import { UsersList, UserCreate } from "./users";
import { RolesList, RoleEdit } from "./roles";
import { AuditLogsList } from "./auditLogs";
import { PRSApprovalsList, PRSApprovalShow } from "./prs-approvals";

import { resources } from "./provider/configs";
import "fontsource-roboto";

const App = () => (
  <Admin
    title="Alfuttaim"
    authProvider={authProvider}
    dataProvider={dataProvider}
    layout={CustomLayout}
    i18nProvider={i18nProvider}
    loginPage={LogIn}
    customRoutes={customRoutes}
  >
    {(permissions) => {
      return [
        authProvider.isAllowed(
          permissions,
          resources["totals"].app.name,
          resources["totals"].app.find
        ) ? (
          <Resource name={resources["totals"].name} list={TotalsList} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["quality"].app.name,
          resources["quality"].app.find
        ) ? (
          <Resource
            name={resources["quality"].name}
            list={QualityAuditsList}
            show={QualityAuditsShow}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["oers"].app.name,
          resources["oers"].app.find
        ) ? (
          <Resource
            name={resources["oers"].name}
            list={OERsList}
            edit={OERsEdit}
            show={OERShow}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["sppvs"].app.name,
          resources["sppvs"].app.find
        ) ? (
          <Resource
            name={resources["sppvs"].name}
            list={SPPVsList}
            edit={SPPVsEdit}
            show={SPPVShow}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["fmReviews"].app.name,
          resources["fmReviews"].app.find
        ) ? (
          <Resource
            name={resources["fmReviews"].name}
            list={FMReviewsList}
            edit={FMReviewsEdit}
            show={FMReviewShow}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["opms"].app.name,
          resources["opms"].app.find
        ) ? (
          <Resource
            name={resources["opms"].name}
            list={OPMsList}
            edit={OPMsEdit}
            show={OPM_ITEM_SHOW}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["qaas"].app.name,
          resources["qaas"].app.find
        ) ? (
          <Resource
            name={resources["qaas"].name}
            list={QAAsList}
            edit={QAAsEdit}
            show={QAA_ITEM_SHOW}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["regulatoryCompliance"].app.name,
          resources["regulatoryCompliance"].app.find
        ) ? (
          <Resource
            name={resources["regulatoryCompliance"].name}
            list={RegulatoryCompliancesList}
            edit={RegulatoryCompliancesEdit}
            show={RegulatoryComplianceItemShow}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["slas"].app.name,
          resources["slas"].app.find
        ) ? (
          <Resource
            name={resources["slas"].name}
            list={SLAsList}
            edit={SLAsEdit}
            show={SLA_ITEM_SHOW}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["kpis"].app.name,
          resources["kpis"].app.find
        ) ? (
          <Resource
            name={resources["kpis"].name}
            list={KPIsList}
            edit={KPIsEdit}
            show={KPI_ITEM_SHOW}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["ncrs"].app.name,
          resources["ncrs"].app.find
        ) ? (
          <Resource
            name={resources["ncrs"].name}
            show={ncrShow}
            edit={ncrEdit}
            list={ncrsList}
          />
        ) : null,

        authProvider.isAllowed(
          permissions,
          resources["cost_centers"].app.name,
          resources["cost_centers"].app.find
        ) ? (
          <Resource
            name={resources["cost_centers"].name}
            show={CostCenterShow}
            list={ConstCentersList}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["vendors"].app.name,
          resources["vendors"].app.find
        ) ? (
          <Resource
            name={resources["vendors"].name}
            show={VendorShow}
            edit={VendorEdit}
            list={VendorsList}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["oer_item"].app.name,
          resources["oer_item"].app.find
        ) ? (
          <Resource name={resources["oer_item"].name} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["sppv_item"].app.name,
          resources["sppv_item"].app.find
        ) ? (
          <Resource name={resources["sppv_item"].name} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["opm_item"].app.name,
          resources["opm_item"].app.find
        ) ? (
          <Resource name={resources["opm_item"].name} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["qaa_item"].app.name,
          resources["qaa_item"].app.find
        ) ? (
          <Resource name={resources["qaa_item"].name} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["regulatory_compliance_item"].app.name,
          resources["regulatory_compliance_item"].app.find
        ) ? (
          <Resource name={resources["regulatory_compliance_item"].name} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["sla_item"].app.name,
          resources["sla_item"].app.find
        ) ? (
          <Resource name={resources["sla_item"].name} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["kpi_item"].app.name,
          resources["kpi_item"].app.find
        ) ? (
          <Resource name={resources["kpi_item"].name} />
        ) : null,

        authProvider.isAllowed(permissions, "users", "find") ? (
          <Resource
            name={resources["users"].name}
            list={UsersList}
            create={UserCreate}
          />
        ) : null,
        authProvider.isAllowed(permissions, "users", "find") ? (
          <Resource
            name={resources["roles"].name}
            list={RolesList}
            edit={RoleEdit}
          />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["auditLogs"].app.name,
          resources["auditLogs"].app.find
        ) ? (
          <Resource name={resources["auditLogs"].name} list={AuditLogsList} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["zone-location-mappings"].app.name,
          resources["zone-location-mappings"].app.find
        ) ? (
          <Resource name={resources["zone-location-mappings"].name} />
        ) : null,
        authProvider.isAllowed(
          permissions,
          resources["prs-approvals"].app.name,
          resources["prs-approvals"].app.find
        ) ? (
          <Resource
            name={resources["prs-approvals"].name}
            list={PRSApprovalsList}
            show={PRSApprovalShow}
          />
        ) : null,
      ];
    }}
  </Admin>
);

export default App;
