import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Button } from 'react-admin';

const stopPropagation = e => e.stopPropagation();

const RedirectButton = ({
    basePath = '',
    label = 'ra.action.show',
    record = {},
    icon = <ArrowForwardIosIcon />,
    createLink,
    onClick,
    ...rest
}) => {
    let redirectLink = createLink(record)
    return (
        <Button
            component={Link}
            to={redirectLink}
            label={label}
            onClick={(e) => {
                stopPropagation(e)
                if(onClick) onClick(redirectLink)
            }}
            {...rest}
        >
            {icon}
        </Button>
    );
}

RedirectButton.propTypes = {
    basePath: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
    createLink: PropTypes.func,
    onClick: PropTypes.func,
};

export default RedirectButton;