import React from "react";
import ItemTable from "../helpers/simple_table";

const headerRow1 = [
  { id: "serviceType", label: "Service Type", sortField: "sortVal" },
  { id: "serviceArea", label: "Service Area" },
  { id: "keyActivity", label: "Key Activity" },
  { id: "KPI", label: "KPI" },
  { id: "performanceLevel", label: "Performance Level" },
];

const headerRow2 = [
  { id: "metric", label: "Metric", sortField: "sortVal" },
  { id: "performanceIndicator", label: "Performance Indicator" },
  { id: "target", label: "Target" },
  { id: "clientWeighting", label: "Client Weighting" },
  { id: "requiredProofDocument", label: "Required Proof Document" },
];

export const SlaItemShow = function ({ record }) {
  let rows = [
    {
      isHeader: true,
      vals: headerRow1.map((r) => r["label"]),
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => record[r["id"]]),
    },
    {
      isHeader: true,
      vals: headerRow2.map((r) => r["label"]),
    },
    {
      isHeader: false,
      vals: headerRow2.map((r) => record[r["id"]]),
    },
  ];
  return <ItemTable rows={rows} />;
};

export const SLA_ITEM_SHOW = (props) => {
  return <SlaItemShow record={props.record.sla_item} />;
};

export default { SlaItemShow, SLA_ITEM_SHOW };
