import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export default function Icon1(props) {
  return (
    <SvgIcon
      {...props}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="3000.000000pt"
      height="1068.000000pt"
      viewBox="0 0 3000.000000 1068.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1068.000000) scale(0.100000,-0.100000)"
        fill="#7266ba"
        stroke="none"
      >
        <path
          d="M9410 10664 c-14 -2 -59 -9 -100 -15 -326 -48 -650 -279 -813 -579
-143 -262 -178 -565 -101 -860 28 -110 113 -287 179 -375 426 -567 1235 -635
1735 -145 142 139 230 278 290 458 181 540 -60 1133 -566 1392 -87 44 -226 92
-319 109 -66 12 -266 22 -305 15z"
        />
        <path
          d="M4325 9239 c-971 -59 -1908 -433 -2674 -1069 -241 -200 -535 -511
-733 -775 -1013 -1354 -1201 -3167 -489 -4703 514 -1108 1468 -1982 2613
-2395 1306 -472 2762 -331 3948 381 1424 856 2284 2421 2236 4072 -26 907
-291 1733 -797 2480 -736 1087 -1922 1815 -3224 1980 -298 37 -581 47 -880 29z
m533 -2300 c582 -63 1131 -352 1510 -794 287 -335 474 -745 538 -1180 22 -148
25 -485 6 -625 -109 -781 -580 -1439 -1276 -1780 -314 -155 -599 -226 -946
-237 -398 -13 -743 60 -1095 232 -696 340 -1178 1011 -1280 1780 -21 157 -21
443 0 600 66 502 289 958 644 1318 392 399 880 633 1441 690 81 9 368 6 458
-4z"
        />
      </g>
      <g
        transform="translate(0.000000,1068.000000) scale(0.100000,-0.100000)"
        fill="#757575"
        stroke="none"
      >
        <path
          d="M27210 7064 c-466 -55 -847 -235 -1189 -560 -375 -356 -605 -795
-696 -1329 -61 -357 -57 -769 10 -1100 94 -463 317 -877 655 -1215 349 -349
742 -551 1196 -616 141 -20 419 -23 544 -5 220 30 486 119 647 215 101 61 257
176 383 285 52 44 98 81 102 81 4 0 8 -73 8 -162 0 -187 9 -218 77 -279 70
-63 77 -64 493 -64 l376 0 49 26 c56 28 109 91 125 147 15 56 14 4197 -2 4260
-15 60 -96 145 -153 161 -26 7 -174 11 -410 11 -367 0 -370 0 -420 -24 -54
-25 -100 -71 -122 -123 -9 -22 -13 -79 -13 -179 l0 -146 -70 63 c-191 172
-317 266 -470 348 -150 80 -357 150 -551 187 -81 16 -483 28 -569 18z m751
-1084 c233 -48 421 -145 592 -309 217 -208 349 -484 388 -811 16 -141 6 -414
-20 -541 -101 -500 -444 -880 -896 -994 -381 -97 -767 -27 -1057 191 -377 282
-570 745 -529 1265 31 379 155 657 401 893 205 196 426 298 715 330 84 9 309
-4 406 -24z"
        />
        <path
          d="M11775 6951 c-80 -14 -143 -64 -179 -141 l-21 -45 0 -2120 0 -2120
30 -54 c36 -66 89 -106 158 -121 35 -8 171 -11 422 -8 360 3 371 4 411 25 53
28 107 91 122 142 9 28 12 575 12 2147 l0 2109 -24 50 c-13 28 -38 64 -57 79
-72 61 -71 61 -469 63 -201 0 -383 -2 -405 -6z"
        />
        <path
          d="M13730 6951 c-49 -10 -90 -34 -127 -73 -43 -45 -63 -96 -63 -164 0
-54 59 -195 896 -2158 493 -1156 904 -2117 915 -2137 38 -75 34 -74 404 -74
396 0 369 -8 433 137 23 51 431 1009 907 2128 694 1630 868 2046 872 2090 10
99 -50 195 -146 239 -30 13 -89 16 -377 19 -215 2 -364 -1 -400 -7 -62 -12
-124 -48 -156 -91 -10 -14 -266 -605 -569 -1315 -303 -709 -554 -1293 -558
-1298 -4 -4 -257 576 -562 1288 -595 1393 -580 1362 -671 1401 -39 17 -77 19
-403 21 -198 1 -376 -2 -395 -6z"
        />
        <path
          d="M18807 6944 c-31 -10 -64 -31 -92 -59 -82 -81 -76 111 -73 -2260 3
-1971 4 -2112 20 -2141 29 -52 68 -94 112 -117 40 -21 51 -22 430 -25 333 -2
395 -1 436 13 57 19 115 72 141 129 18 40 19 119 19 2164 0 1895 -2 2127 -16
2160 -19 47 -67 97 -119 125 -39 21 -51 22 -425 24 -319 2 -393 -1 -433 -13z"
        />
        <path
          d="M20680 6951 c-79 -17 -148 -75 -176 -147 -39 -103 -91 29 894 -2279
687 -1612 907 -2118 931 -2142 21 -22 43 -34 73 -38 24 -3 176 -5 338 -3 327
3 332 4 367 72 9 17 419 976 911 2131 l895 2100 1 70 c0 81 -22 135 -76 180
-70 59 -73 60 -444 63 -215 2 -364 -1 -400 -7 -62 -12 -124 -48 -156 -91 -10
-14 -266 -606 -569 -1315 -303 -710 -554 -1294 -558 -1298 -4 -4 -257 576
-562 1288 -596 1394 -580 1362 -671 1401 -39 17 -77 19 -403 21 -198 1 -376
-2 -395 -6z"
        />
      </g>
    </SvgIcon>
  );
}
