import React from "react";
import CardContent from "@material-ui/core/CardContent";
import WithHeaderGroupTable from "../helpers/WithHeaderGroupTable.js";
import { formatCurrency } from "../helpers/utils";

const columns = [
  {
    Header: "NCR PENALTY",
    columns: [{ Header: "", accessor: "name" }],
  },
  {
    Header: "Criticality",
    columns: [{ Header: "", accessor: "Criticality" }],
  },
];

function createData(name, Criticality, configsArr) {
  let obj = { name, Criticality };
  configsArr.forEach(({ label, penalty, id }) => {
    obj[`${label}`] = formatCurrency(penalty, "AED");
    obj["id"] = id;
  });
  return obj;
}

export default function AfterLoadComponents({ data, ids, filter }) {
  if (ids.length === 0) return <></>;

  let columnsLabels = {};
  let rows = ids.map((id) => {
    let { penaltyConfigs, criticality, description } = data[id];
    penaltyConfigs.forEach(({ label }) => columnsLabels[label] = true);
    return createData(description, criticality, penaltyConfigs);
  });

  let columnsParam = [
    ...columns,
    ...Object.keys(columnsLabels).map(label => ({ Header: label, columns: [{ Header: "AED", accessor: label }] }))
  ]

  return (
    <CardContent>
      <div style={{ flexGrow: 1 }}>
        <h1>Configuration Table</h1>
        <div
          style={{
            borderBottom: "1px solid #ddd8c9",
            boxShadow: "3px 3px 7px #ddd8c9",
          }}
        >
          <WithHeaderGroupTable columns={columnsParam} rows={rows} />
        </div>
      </div>
    </CardContent>
  );
}
