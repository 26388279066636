import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash'
import classnames from 'classnames'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    percentage: { fontWeight: 'bold' },
});

const PercentagesField = (props) => {
    const classes = useStyles();
    let {record, source, bold = true} = props
    return (
        <span className={classnames({[classes.percentage]: bold})} >{record[source] || 0}%</span>
    )
}

const MergeFields = (field1, field2) => ({record}) => <span>{_.result(record, field1, 'N/A')} - {_.result(record, field2, 'N/A')}</span>

const Components = {
    PercentagesField,
    MergeFields,
}

export default Components;

export {
    PercentagesField,
    MergeFields,
}
