import { withStyles } from "@material-ui/core/styles";
import React from "react";
import {
  EditController,
  NumberInput,
  TextInput,
  required,
  number,
  minValue,
  maxValue,
  ListButton,
  FileField,
  FileInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CustomSimpleForm from "../customise/CustomSimpleForm";
import CustomToolbar from "../customise/CustomToolbar";
import { OPM_ITEM_SHOW } from "./item_show";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const editStyles = {
  root: { display: "flex", alignItems: "flex-start", width: "100%" },
  form: { flexGrow: 9 },
};

const ShowListAction = ({ basePath, size, title = "OPM Item Details" }) => (
  <Grid container spacing={0}>
    <Grid item xs={5}>
      <span
        style={{
          float: "left",
          marginLeft: "20px",
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "1.3em",
        }}
      >
        {title}
      </span>
    </Grid>
    <Grid item xs={5}></Grid>
    <Grid item xs={2}>
      <span style={{ float: "right", marginRight: "20px", marginBottom: "30px" }}>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} size={size} />
      </span>
    </Grid>
  </Grid>
);

class OPMsEditToStyle extends React.Component {
  render() {
    const FormDiv = withStyles(editStyles)(({ children, classes, actions, ...props }) => {
      let substractValueMsg = "";
      let substractValue = props.record.opm_item.substractValue;
      if (substractValue && substractValue !== "DOWNWARD_TREND") {
        substractValueMsg = `<strong>Actual Values is calculated if </strong><code>(${substractValue.match(
          /^.*(?=\?)/
        )})</code><strong> then Subtract </strong><code>(${substractValue.match(
          /(?<=\?).*(?=:)/
        )})</code><strong> points from Target otherwise; substract </strong><code>(${substractValue.match(
          /(?<=:).*$/
        )})</code><strong> points from Target</strong>`;
      }
      const validateInput = [required(), number(), minValue(props.record.opm_item.minValue)];
      if (props.record.opm_item.maxValue) validateInput.push(maxValue(props.record.opm_item.maxValue));
      const validateActual = [required(), number(), minValue(0), maxValue(props.record.opm_item.target)];
      return (
        <div className={classes.root}>
          <div className={classes.form}>
            {actions}
            <OPM_ITEM_SHOW {...props} />
            <br />
            <p>Target: {props.record.target} score points</p>
            <p>Actual: {props.record.actual} score points</p>
            <p>Input: {props.record.input || "-"}</p>
            <p>Performance: {props.record.performance}</p>
            <p>Criteria: {props.record.opm_item.criteria || "-"}</p>
            <p dangerouslySetInnerHTML={{ __html: substractValueMsg }}></p>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                {substractValue ? (
                  <NumberInput
                    source="input"
                    validate={validateInput}
                    disabled={!props.record.editable}
                    placeholder={props.record.opm_item.tips}
                  />
                ) : (
                  <NumberInput
                    source="actual"
                    validate={validateActual}
                    disabled={!props.record.editable}
                    placeholder={"Enter the Actual value"}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <p>
                  Input instructions:
                  <span style={{ color: "orange" }}>{props.record.opm_item.tips || "Enter the Actual value"}</span>
                </p>
                <p>
                  Input Min Value: <span style={{ color: "orange" }}>{props.record.opm_item.minValue}</span>
                  {props.record.opm_item.maxValue ? (
                    <span>
                      , Input Max Value: <span style={{ color: "orange" }}>{props.record.opm_item.maxValue}</span>
                    </span>
                  ) : null}
                </p>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    style={{ width: "100%" }}
                    multiline
                    rows={4}
                    source="Justification"
                    helperText={"Justification"}
                    label="Justification"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FileInput source="attachments" multiple>
                    <FileField source="url" title="name" />
                  </FileInput>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    });

    return (
      <EditController {...this.props} undoable={false} warnWhenUnsavedChanges>
        {({ resource, record, redirect, save, basePath, version, ...rest }) => {
          return (
            <div>
              <Card>
                {record && (
                  <CustomSimpleForm
                    toolbar={<CustomToolbar allowDelete={false} editable={record.editable} />}
                    basePath={basePath}
                    redirect={redirect}
                    resource={resource}
                    record={record}
                    save={save}
                    version={version}
                  >
                    <FormDiv record={record} actions={<ShowListAction basePath={basePath} size="large" />} />
                  </CustomSimpleForm>
                )}
              </Card>
            </div>
          );
        }}
      </EditController>
    );
  }
}

const OPMsEdit = withStyles(editStyles)(OPMsEditToStyle);
export default OPMsEdit;

export { OPMsEdit };
