import React, { cloneElement, useState } from "react";
import {
  Datagrid,
  ListBase,
  BulkActionsToolbar,
  Pagination,
  TextField,
  NumberField,
  ExportButton,
  TopToolbar,
  ListToolbar,
} from "react-admin";
import Card from "@material-ui/core/Card";

import { RoleEdit } from "../../roles";
import { resources } from "../../provider/configs";
import exporters from "../../helpers/exporters";

const resource = resources["roles"].name;
const basePath = "/role";

const RolesListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

const RolesList = ({ currentView, onClickAction, ...props }) => (
  <ListBase {...props}>
    {currentView === "list" && (
      <>
        <ListToolbar filters={props.filters} actions={props.actions} />
        <Card>
          <BulkActionsToolbar>{props.bulkActionButtons}</BulkActionsToolbar>
          {cloneElement(props.children, {
            hasBulkActions: props.bulkActionButtons !== false,
          })}
          {props.pagination && <Pagination />}
        </Card>
      </>
    )}
    {currentView === "edit" && (
      <RoleEdit
        basePath={basePath}
        resource={resource}
        onClickAction={onClickAction}
      />
    )}
  </ListBase>
);

export const RolesTab = ({ ...props }) => {
  let [currentView, setCurrentView] = useState("list");
  const onClickAction = setCurrentView.bind(this);
  return (
    <RolesList
      {...{
        ...props,
        title: "Roles",
        bulkActionButtons: false,
        basePath,
        resource,
        onClickAction,
        currentView,
        pagination: false,
        actions: <RolesListActions />,
        exporter: (roles) => exporters(roles, "roles"),
      }}
    >
      <Datagrid
        title={"Roles"}
        pagination={null}
        // expand={
        //   <RoleEdit
        //     basePath={basePath}
        //     resource={resource}
        //     // onClickAction={onClickAction}
        //   />
        // }
        // rowClick={"expand"}
      >
        <TextField label="Name" source="name" />
        <NumberField label="Users Count" source="nb_users" />
        <TextField label="Description" source="description" />
      </Datagrid>
    </RolesList>
  );
};
