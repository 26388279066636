import React from "react";
import ItemTable from "../helpers/simple_table";
import {
  TopToolbar,
  NumberField,
  TextField,
  SimpleShowLayout,
  Show,
  ListButton,
} from "react-admin";
import * as _ from "lodash";

const headerRow1 = [
  { id: "sppv_group.label", label: "Group" },
  // { id: "sppv_item.sppv_group.label", label: "Group" },
  { id: "SN", label: "S/N" },
  { id: "Area", label: "Area" },
  { id: "PerformanceAssessmentPeriod", label: "Performance Assessment Period" },
];
const tableHeader1 = headerRow1.map((r) => r["label"]);

// const headerRow2 = [
//   { id: "target", label: "Target" },
//   { id: "actual", label: "Actual" },
//   { id: "performance", label: "Performance" },
//   { id: "Justification", label: "Justification" },
// ];
// const tableHeader2 = headerRow2.map((r) => r["label"]);

export const SPPVItemShow = function ({ record }) {
  console.log({SPPVItemShow: record})
  let rows = [
    {
      isHeader: true,
      vals: tableHeader1,
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => _.result(record, r["id"])),
    },
    // {
    //   isHeader: true,
    //   vals: tableHeader2,
    // },
    // {
    //   isHeader: false,
    //   vals: headerRow2.map((r) => _.result(record, r["id"])),
    // },
  ];
  return <ItemTable rows={rows} />;
};

export const SPPV_ITEM_SHOW = (props) => {
  return <SPPVItemShow record={props.record.sppv_item} />;
};



const SPPVsShowActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="List" />
  </TopToolbar>
);

export const SPPVShow = (props) => {
  return (
    <Show
      actions={<SPPVsShowActions />}
      title="SPPV Item Data"
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="sppv_item.SN" />
        <TextField source="sppv_item.Area" />
        <TextField source="sppv_item.PerformanceAssessmentPeriod" />
        <NumberField source="target" />
        <NumberField source="actual" />
        <NumberField source="performance" />
        <TextField source="Justification" />
      </SimpleShowLayout>
    </Show>
  );
};

export default { SPPV_ITEM_SHOW, SPPVItemShow, SPPVShow };
