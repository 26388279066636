module.exports = {
    categories: [
        "Other",
        "UniformOrDressCode",
        "SnapShotOrCustomerFeedback",
        "Enviromental",
        "WorkStandards",
        "Operational",
        "HealthAndSafety",
        "infrastructure",
    ]
}