import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  closeButton: {
    float: 'right'
  }
}))

export default function AlertDialog({ open, messages = {}, handler, maxWidth = 'sm' }) {
  let classes = useStyles()
  let Description = messages.description || (() => <span>{'Are You Sure?'}</span>);
  return (
      <Dialog
        open={open}
        onClose={() => handler("Cancel")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <DialogTitle id={`alert-dialog-title-${messages.id}`}>
          { messages.title || "Confirm" }
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handler("Cancel")}
            size='small'
            >
            <HighlightOffIcon />
          </IconButton>
        </DialogTitle>
        <span style={{marginLeft: '20px', float: 'left',width: '50%', borderTop: '0.5px solid #aca591'}} />
        <DialogContent>
          <DialogContentText id={`alert-dialog-description-${messages.id}`}>
            <Description />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handler("Ok")} variant="outlined" color="primary">
            { messages.ok || 'Ok' }
          </Button>
          <Button onClick={() => handler("Cancel")} variant="outlined" color="primary">
            { messages.cancel || 'Cancel' }
          </Button>
        </DialogActions>
      </Dialog>
  );
}
