import React from "react";
import { Notification } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  alert: {
    // position: "fixed",
    // top: "50px",
    // left: "50px",
  },
}));

const MyNotification = (props) => {
  const classes = useStyles();
  return (
    <Notification
      {...props}
      autoHideDuration={4000000}
      className={`${props.classes ? props.classes : ""} ${classes.alert}`}
    />
  );
};

export default MyNotification;
