import React from "react";
import ItemTable from "../helpers/simple_table";

const headerRow1 = [
  { id: "KPI", label: "KPI", sortField: "sortVal" },
  { id: "clientWeighting", label: "clientWeighting" },
  { id: "keyActivity", label: "Key Activity" },
  { id: "keyPerformanceIndicators", label: "keyPerformanceIndicators" },
  { id: "measurement", label: "measurement" },
];
const tableHeader1 = headerRow1.map((r) => r["label"]);

const headerRow2 = [
  { id: "metric", label: "Metric", sortField: "sortVal" },
  { id: "performanceLevel", label: "performanceLevel" },
  { id: "serviceArea", label: "serviceArea" },
  { id: "serviceType", label: "serviceType" },
  { id: "targetLabel", label: "targetLabel" },
];
const tableHeader2 = headerRow2.map((r) => r["label"]);

export const KpiItemShow = function ({ record }) {
  let rows = [
    {
      isHeader: true,
      vals: tableHeader1,
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => record[r["id"]]),
    },
    {
      isHeader: true,
      vals: tableHeader2,
    },
    {
      isHeader: false,
      vals: headerRow2.map((r) => record[r["id"]]),
    },
  ];
  return <ItemTable rows={rows} />;
};

export const KPI_ITEM_SHOW = (props) => {
  return <KpiItemShow record={props.record.kpi_item} />;
};

export default { KPI_ITEM_SHOW, KpiItemShow };
