// import React, { useState } from 'react';
import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3a97f3",
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: "1px solid white",
  },
  body: {
    border: "1px solid white",
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
      // backgroundColor: '#595959',
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 250,
    maxHeight: 10,
  },
  margin: {
    // margin: theme.spacing(1),
  },
});

const WithHeaderGroupTable = ({ columns, message, rows, onEdit }) => {
  const classes = useStyles();
  let [updating, setUpdating] = useState("");
  let inputs_ids = [];

  let getOriginal = (obj, accessor) =>
    _.result(obj, `${accessor}_original`, _.result(obj, accessor, "-"));

  useEffect(() => {
    inputs_ids.forEach(([id, obj, accessor]) => {
      var input = document.getElementById(id);
      if (!input) return;
      input.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          let newVal = input.value;
          let original = `${getOriginal(obj, accessor)}`;
          if (newVal !== original) {
            onEdit(obj, accessor, newVal);
          }

          // document.getElementById(`${id}-btn`).click();
        }
      });
    });
  }, [inputs_ids, onEdit]);

  function handleUpdate(obj, accessor, e) {
    let newVal = document.getElementById(`${obj.id}-${accessor}`).value;
    let original = `${getOriginal(obj, accessor)}`;
    if (newVal !== original) {
      onEdit(obj, accessor, newVal);
    }
  }

  let colSpan = columns.reduce(
    (acc, { columns: subCols }) => acc + subCols.length,
    0
  );
  let InformativeMessage = (msg) => (
    <StyledTableRow>
      <StyledTableCell
        style={{ textAlign: "center", fontWeight: "bold" }}
        colSpan={colSpan}
      >
        {msg}
      </StyledTableCell>
    </StyledTableRow>
  );
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow key={"mainHeader"}>
            {columns.map((h, m) => (
              <StyledTableCell
                align="center"
                key={`${h.Header} - ${m}`}
                colSpan={
                  h.columns.length +
                  h.columns.filter((c) => c.editable && rows.length).length
                }
                rowSpan={h.columns[0].Header === "" ? 2 : 1}
              >
                {h.Header}
              </StyledTableCell>
            ))}
          </TableRow>
          <TableRow key={"subHeader"}>
            {columns.map((h, ind) =>
              h.columns.map((r) =>
                r.Header === "" ? null : (
                  <StyledTableCell
                    colSpan={r.editable && rows.length ? 2 : 1}
                    key={`${h.Header}-${r.accessor}`}
                    align="center"
                  >
                    {r.Header}
                  </StyledTableCell>
                )
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((obj, i) => (
            <StyledTableRow key={`${i} - Row`}>
              {columns.map(({ Header, columns: subCols }) =>
                subCols.reduce(
                  (acc, { accessor, stylePerCell, editable }, ind) => {
                    if (
                      updating.match(obj.id) &&
                      editable &&
                      updating.match(accessor)
                    ) {
                      inputs_ids.push([`${obj.id}-${accessor}`, obj, accessor]);
                    }

                    acc.push(
                      <StyledTableCell
                        style={
                          stylePerCell
                            ? stylePerCell({ row: obj, accessor }, rows, i)
                            : undefined
                        }
                        key={`cell-${i}-${ind}-${obj.id}`}
                        align="center"
                        // colSpan={editable && !obj.editable ? 2 : 1}
                      >
                        {updating.match(obj.id) &&
                        editable &&
                        updating.match(accessor) ? (
                          <input
                            style={{ maxWidth: "80px" }}
                            id={`${obj.id}-${accessor}`}
                            defaultValue={getOriginal(obj, accessor)}
                          />
                        ) : (
                          _.result(obj, accessor, "-")
                        )}
                      </StyledTableCell>
                    );
                    if (obj.editable && editable) {
                      acc.push(
                        <StyledTableCell
                          style={
                            stylePerCell
                              ? {
                                  ...stylePerCell(
                                    { row: obj, accessor },
                                    rows,
                                    i
                                  ),
                                  width: "0.5em",
                                }
                              : { width: "0.5em" }
                          }
                          key={`cell-editButton-${i}-${ind}-${obj.id}`}
                          align="center"
                        >
                          {updating.match(obj.id) &&
                          updating.match(accessor) ? (
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              id={`${obj.id}-${accessor}-btn`}
                              className={classes.margin}
                              onClick={(e) => handleUpdate(obj, accessor, e)}
                            >
                              <CheckIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <>
                              {(!updating.match(obj.id) ||
                                !updating.match(accessor)) && (
                                <IconButton
                                  color="primary"
                                  aria-label="edit"
                                  className={classes.margin}
                                  onClick={(e) => {
                                    setUpdating(`${obj.id}-${accessor}`);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              )}
                            </>
                          )}
                        </StyledTableCell>
                      );
                    } else if (editable) {
                      acc.push(
                        <StyledTableCell
                          style={
                            stylePerCell
                              ? {
                                  ...stylePerCell(
                                    { row: obj, accessor },
                                    rows,
                                    i
                                  ),
                                  width: "0.5em",
                                }
                              : { width: "0.5em" }
                          }
                          key={`cell-editButton-${i}-${ind}-${obj.id}`}
                          align="center"
                        ></StyledTableCell>
                      );
                    }
                    return acc;
                  },
                  []
                )
              )}
            </StyledTableRow>
          ))}
          {message && !rows.length && InformativeMessage(message)}
          {!rows.length && !message && InformativeMessage("No Data Available")}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WithHeaderGroupTable;
