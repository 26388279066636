import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { usePermissions, useRedirect, Loading } from "react-admin";

import { resources } from "../../provider/configs";
import { authProvider } from "../../provider/provider";
import { AntTabs, AntTab, TabPanel } from "../../helpers/tabs";
import { UsersTab } from "./users";
import { RolesTab } from "./roles";
import { getParameterByName } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    root: {
      borderBottom: "1px solid #e8e8e8",
    },
    indicator: {
      backgroundColor: "#1890ff",
    },
  },
}));

const tabsConfigs = [
  {
    resource: resources["users"],
    TabComponent: UsersTab,
  },
  {
    resource: resources["roles"],
    TabComponent: RolesTab,
  },
];

export default function SettingsUsers(props) {
  const classes = useStyles();
  const redirect = useRedirect();
  const { loaded: permissionsLoaded, permissions } = usePermissions();
  const tab = getParameterByName("tab") || "0";
  const [value, setValue] = React.useState(Number(tab));

  const handleChange = (event, newValue) => {
    console.log("handleChange", value);
    props.history.push(`settingsUsers?tab=${value}&view=list`);
    setValue(newValue);
  };

  const getPerm = useCallback(
    (n, a) => {
      return authProvider.getPermissionPerType(permissions, n, a || "find");
    },
    [permissions]
  );

  if (permissionsLoaded) {
    tabsConfigs.forEach((e) => (e.permissions = getPerm(e.resource.app.name)));
    if (!tabsConfigs[0].permissions.find.enabled) {
      redirect("/quality-totals");
      return null;
    }
  } else {
    return <Loading loadingPrimary="Loading..." loadingSecondary="Loading Permissions" />
  }
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="ant example"
        >
          {tabsConfigs.map(({ resource }, i) => (
            <AntTab
              wrapped
              label={resource.alias}
              key={`settings-user-tab-header-${i}`}
            />
          ))}
        </AntTabs>
        <Typography className={classes.padding} />
      </div>
      {tabsConfigs.map(({ TabComponent, resource, permissions }, i) => (
        <TabPanel key={`settings-user-tab-${i}`} value={value} index={i}>
          <TabComponent
            configResource={resource.name}
            {...props}
            permissions={permissions}
          />
        </TabPanel>
      ))}
    </div>
  );
}
