import { withStyles } from "@material-ui/core/styles";
import React from "react";
import {
  EditController,
  NumberInput,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  ListButton,
  TextField,
  Labeled,
  FormDataConsumer,
  FileField,
  FileInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CustomSimpleForm from "../customise/CustomSimpleForm";
import CustomToolbar from "../customise/CustomToolbar";
import { OER_ITEM_SHOW } from "./item_show";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const editStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  form: {
    width: '100%',
  },
  line: {
    border: 0,
  },
};

const ShowListAction = ({ basePath, size, title = "OER Item Details" }) => (
  <Grid container spacing={0}>
    <Grid item xs={5}>
      <span
        style={{
          float: "left",
          marginLeft: "20px",
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "1.3em",
        }}
      >
        {title}
      </span>
    </Grid>
    <Grid item xs={5}></Grid>
    <Grid item xs={2}>
      <span style={{ float: "right", marginRight: "20px", marginBottom: "30px" }}>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} size={size} />
      </span>
    </Grid>
  </Grid>
);

class OERsEditToStyle extends React.Component {
  render() {
    const FormDiv = (({ children, actions, ...props }) => {
      return (
        <div>
          {actions}
          <OER_ITEM_SHOW {...props} />
          <br />
          <br />
          <br />
        </div>
      );
    });

    return (
      <EditController {...this.props} undoable={false}>
        {({ resource, record, redirect, save, basePath, version, ...rest }) => {
          return (
            <div>
              <Card>
                {record && (
                  <CustomSimpleForm
                    toolbar={<CustomToolbar allowDelete={false} editable={record.editable} />}
                    basePath={basePath}
                    redirect={redirect}
                    resource={resource}
                    record={record}
                    save={save}
                    version={version}
                  >
                    <FormDiv record={record} actions={<ShowListAction basePath={basePath} size="large" />} />
                    <ArrayInput source="keyActivitiesCalculation">
                      <SimpleFormIterator
                        inline
                        disableAdd={true}
                        disableRemove={true}
                        classes={this.props.classes}
                      >
                        <FormDataConsumer>
                          {({ scopedFormData }) => (
                            <Labeled label="Ref.">
                              <TextField source="Ref" record={scopedFormData} />
                            </Labeled>
                          )}
                        </FormDataConsumer>

                        <NumberInput source="actual" helperText={false} label="actual"/>
                        <TextInput multiline rows={3} source="Justification" helperText={"Justification"} label="Justification"/>
                      </SimpleFormIterator>
                    </ArrayInput>
                    <FileInput
                      source="attachments"
                      multiple
                    >
                      <FileField source="url" title="name" />
                    </FileInput>
                  </CustomSimpleForm>
                )}
              </Card>
            </div>
          );
        }}
      </EditController>
    );
  }
}

const OERsEdit = withStyles(editStyles)(OERsEditToStyle);
export default OERsEdit;

export { OERsEdit };
