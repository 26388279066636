import React, {
  isValidElement,
  Children,
  cloneElement,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { sanitizeListRestProps } from "ra-core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import { useNotify } from "react-admin";
import * as _ from "lodash";

import {
  DatagridLoading,
  DatagridHeaderCell,
  // DatagridBody,
  PureDatagridBody,
  Loading,
} from "react-admin";

import CustomDatagridBody from "./customDataGridBody";
import { formatCurrency } from "../helpers/utils";
import { resources } from '../provider/configs'
const resourcesArr = Object.values(resources);
const resourcesByName = resourcesArr.reduce((acc, obj) => {
  acc[obj.name] = obj;
  return acc;
}, {});

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
  },
  border: {
    borderBottom: "1px solid #ddd8c9",
    boxShadow: "3px 3px 7px #ddd8c9",
  },
  thead: {},
  tbody: {},
  headerRow: {
    backgroundColor: "#3a97f3",
  },
  headerCell: {
    color: "#FFFFFF",
    textAlign: "left",
  },
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: "pointer",
  },
  rowEven: {
    backgroundColor: "#f5f5f5",
  },
  rowOdd: {
    backgroundColor: "white",
    // backgroundColor: "#ddd8c9"
  },
  rowCell: {
    border: "2px solid #f3ecd8",
    textAlign: "left",
  },
  expandHeader: {
    padding: 0,
    width: theme.spacing(6),
  },
  expandIconCell: {
    width: theme.spacing(6),
  },
  expandIcon: {
    padding: theme.spacing(1),
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: "rotate(0deg)",
  },
}));

/**
 * The Datagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - rowStyle
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <Datagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 */
function MyDatagrid({
  classes: classesOverride,
  total_fields,
  toSum,
  colSpan,
  AfterLoadComponents,
  Aside,
  ...props
}) {
  const classes = useStyles({ classes: classesOverride });
  const {
    basePath,
    optimized = false,
    body = optimized ? <PureDatagridBody /> : <CustomDatagridBody />,
    children,
    className,
    currentSort,
    data: propsData,
    expand,
    hasBulkActions,
    hover,
    ids: propsIds,
    loading,
    loaded,
    onSelect,
    onToggleItem,
    resource,
    rowClick,
    rowStyle,
    selectedIds,
    setSort,
    size = "small",
    total,
    version,
    ...rest
  } = props;
  let ids = propsIds;
  let data = propsData;

  const notify = useNotify();
  function persistedAlert() {
    notify(`Data Has Been Blocked`, "warning");
  }

  function getTotalStyle() {
    let styles = data[ids[0]]["colorConfigs_response"];
    if (styles) {
      styles.color = styles.fontColor;
      return styles;
    }
    if (data[ids[0]].backgroundColor || data[ids[0]].color) {
      return {
        backgroundColor: data[ids[0]].backgroundColor,
        color: data[ids[0]].fontColor,
      };
    } else {
      return {};
    }
  }

  const getTotalForField = (rowField, index) => {
    let isTotal = false;
    const findTotalFieldValue = function(total_field) {
      let selectedId = ids.find(id => Object.hasOwnProperty.call(data[id], total_field.key));
      return data[selectedId][total_field.key];
    }
    function calculate() {
      if (!ids.length || (!toSum.length && !total_fields.length)) return false;
      let fieldCurrency = (rowField.props.options || {}).currency;
      let unit =
        fieldCurrency || (rowField.props.unit ? rowField.props.unit : "");
      let field = rowField.props.source;
      let total_field =
        total_fields.find((total_data) => total_data.field === field) || {};
      if (total_field.field && total_field.field === field) {
        isTotal = true;
        if (fieldCurrency) {
          return formatCurrency(findTotalFieldValue(total_field), fieldCurrency);
        }
        return `${findTotalFieldValue(total_field)} ${unit}`;
      }
      if (toSum.indexOf(field) === -1) {
        return false;
      }
      let sum = ids
        .map((id) => _.result(data[id], field))
        .reduce((acc, curr) => acc + (curr || 0), 0);
      if (fieldCurrency) {
        return formatCurrency(sum, fieldCurrency);
      }
      sum = sum.toFixed(2);
      return `${
        Number.parseInt(sum) === Number.parseFloat(sum)
          ? Number.parseInt(sum)
          : sum
      } ${unit}`;
    }
    let val = calculate();
    if (!val || !isTotal) {
      return (
        <td
          key={index}
          style={{
            textAlign: "left",
            padding: "6px 24px 6px 16px",
            fontWeight: "bold",
            height: "1.5em",
          }}
        >
          {val}
        </td>
      );
    }
    return (
      <td
        key={index}
        style={{
          textAlign: "left",
          padding: "6px 24px 6px 16px",
          fontWeight: "bold",
          height: "1.5em",
          ...getTotalStyle(),
        }}
      >
        {val}
      </td>
    );
  };

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation();
      setSort(event.currentTarget.dataset.sort);
    },
    [setSort]
  );

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        onSelect(ids.concat(selectedIds.filter((id) => !ids.includes(id))));
      } else {
        onSelect([]);
      }
    },
    [ids, onSelect, selectedIds]
  );

  /**
   * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
   * if loaded is true, the data for the list has at least been returned once by the dataProvider
   * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
   */
  if (loaded === false) {
    return (
      // <Loading />
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
        size={size}
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  /**
   * Once loaded, the data for the list may be empty. Instead of
   * displaying the table header with zero data rows,
   * the datagrid displays nothing in this case.
   */
  // if (loaded && (ids.length === 0 || total === 0)) {
  //     return null;
  // }

  /**
   * After the initial load, if the data for the list isn't empty,
   * and even if the data is refreshing (e.g. after a filter change),
   * the datagrid displays the current data.
   */
  let allFilters = resourcesByName[resource].requiredFilters.every((v) => props.filterValues[v]);
  let groupby = props.groupby;
  if (groupby) {
    let groups = {};
    for (const id of ids) {
      let row = data[id];
      let key = _.result(row, groupby);
      groups[key] = groups[key] || { title: key, rows: [] };
      groups[key].rows.push(row);
    }

    let groupsIds = [];
    let groupsData = {};
    Object.values(groups).forEach(({ title, rows }) => {
      groupsIds.push(title);
      groupsData[title] = { id: title, isGroupHeader: true, rows };
      rows.forEach(rr => {
        groupsIds.push(rr.id);
        groupsData[rr.id] = rr;
      })
    })
    ids = groupsIds;
    data = groupsData;
  }
    
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={Aside && ids.length ? 9 : 12}>
          <Table
            className={classnames(
              { [classes.border]: !!Aside || !!AfterLoadComponents },
              classes.table,
              className
            )}
            size={size}
            {...sanitizeListRestProps(rest)}
          >
            <TableHead className={classes.thead}>
              <TableRow className={classnames(classes.row, classes.headerRow)}>
                {expand && (
                  <TableCell padding="none" className={classes.expandHeader} />
                )}
                {hasBulkActions && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      className="select-all"
                      color="primary"
                      checked={
                        selectedIds.length > 0 &&
                        ids.length > 0 &&
                        ids.every((id) => selectedIds.includes(id))
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                )}
                {Children.map(children, (field, index) =>
                  isValidElement(field) ? (
                    <DatagridHeaderCell
                      className={classes.headerCell}
                      currentSort={currentSort}
                      field={field}
                      isSorting={
                        currentSort.field ===
                        (field.props.sortBy || field.props.source)
                      }
                      key={field.props.source || index}
                      width={field.props.width || undefined}
                      resource={resource}
                      updateSort={updateSort}
                    />
                  ) : null
                )}
              </TableRow>
            </TableHead>
            {children.length &&
              cloneElement(
                body,
                {
                  basePath,
                  className: classes.tbody,
                  classes,
                  expand,
                  rowClick:
                    ids.length && ids.find(id => data[id].isPersisted_response)
                      ? persistedAlert
                      : rowClick,
                  data,
                  hasBulkActions,
                  hover,
                  ids,
                  onToggleItem,
                  resource,
                  rowStyle,
                  selectedIds,
                  version,
                },
                children
              )}

            <tfoot>
              {!!ids.length ? (
                <tr
                  className={classnames(classes.headerCell, className)}
                  style={{
                    height: "50px",
                    backgroundColor: "#f1f1f1",
                    color: "#000",
                  }}
                >
                  {expand && <TableCell padding="none" />}
                  {hasBulkActions && <TableCell padding="none" />}
                  {Children.map(children, (field, index) => {
                    if (index === 0) {
                      return (
                        <td
                          key={-1}
                          colSpan={colSpan}
                          style={{
                            textAlign: "left",
                            padding: "6px 24px 6px 16px",
                            fontWeight: "bold",
                            height: "1.5em",
                          }}
                        >
                          {"Monthly Total Percentage"}
                        </td>
                      );
                    }
                    if (index < colSpan) {
                      return null;
                    }
                    return getTotalForField(field, index);
                  })}
                </tr>
              ) : (
                <tr
                  className={classnames(classes.headerCell, className)}
                  style={{
                    height: "50px",
                    backgroundColor: "#f1f1f1",
                    color: "#000",
                  }}
                >
                  <td
                    key={"No Data"}
                    colSpan={children.length + (expand ? 1 : 0)}
                    style={{
                      textAlign: "center",
                      padding: "6px 24px 6px 16px",
                      fontWeight: "bold",
                      height: "1.5em",
                    }}
                  >
                    {!allFilters
                      ? "Please Select All required filters: " + resourcesByName[resource].requiredFilters.join(", ")
                      : "No Data Available"}
                  </td>
                </tr>
              )}
            </tfoot>
          </Table>
        </Grid>
        {!!ids.length && Aside && (
          <Grid item xs={Aside && ids.length ? 3 : 0}>
            {cloneElement(Aside, {
              filters: props.filterValues,
            })}
          </Grid>
        )}
      </Grid>
      {!!ids.length &&
        AfterLoadComponents &&
        cloneElement(AfterLoadComponents, {
          data,
          ids,
          filter: { ...props.filterValues },
        })}
      {/* {!!ids.length && AfterLoadComponents && AfterLoadComponents()} */}
    </>
  );
}

MyDatagrid.propTypes = {
  basePath: PropTypes.string,
  body: PropTypes.element,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object.isRequired,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSort: PropTypes.func,
  total: PropTypes.number,
  version: PropTypes.number,
  colSpan: PropTypes.number,
  total_fields: PropTypes.arrayOf(PropTypes.object),
  toSum: PropTypes.arrayOf(PropTypes.string),
  AfterLoadComponents: PropTypes.object,
  Aside: PropTypes.object,
  groupby: PropTypes.string,
};

MyDatagrid.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  selectedIds: [],
  total_fields: [],
  toSum: [],
  colSpan: 0,
  Aside: null,
  groupby: null,
  filterValues: {},
  currentSort: { field: "_id", order: "ASC" },
};

export default MyDatagrid;
