import * as React from "react";
import { Layout } from "react-admin";

import MyNotification from "./CustomNotification";
import CustomMenue from "./sideBar/CustomMenue";
import CustomSidebar from "./sideBar/CustomSidebar";
import CustomAppBar from "./CustomAppBar";

const CustomLayout = (props) => (
  <Layout
    {...props}
    menu={CustomMenue}
    sidebar={CustomSidebar}
    notification={MyNotification}
    appBar={CustomAppBar}
  />
);

export default CustomLayout;
