import * as React from "react";

import RefreshableTable from "../helpers/refreshableTable";
import SettingsTables from "../helpers/tables";
import { filtersChanged, formatCurrency } from "../helpers/utils";

const headerRow = [
  { id: "name", numeric: false, disablePadding: true, label: "Description" },
  {
    id: "1st Failure",
    numeric: true,
    disablePadding: false,
    label: "1st Failure",
  },
  {
    id: "2nd Failure",
    numeric: true,
    disablePadding: false,
    label: "2nd Failure",
  },
  {
    id: "3rd Failure",
    numeric: true,
    disablePadding: false,
    label: "3rd Failure",
  },
];

const TypesTable = ({ filters }) => {
  let setPayloadFilter = (f) => ({
    year: f.year,
    month: f.month,
    cost_center: f.cost_center,
    vendor: f.vendor,
  });

  function handleFetchedData(data) {
    return data.map((doc) => {
      let row = { name: doc.description };
      doc.failure_penalty.forEach((e) => {
        row[e.failure] = formatCurrency(e.penalty, "AED");
      });
      return row;
    });
  }

  return (
    <RefreshableTable
      filters={{ ...filters }}
      columns={headerRow}
      resource={"sla-penalty-configs"}
      handleFetchedData={handleFetchedData}
      setPayloadFilter={setPayloadFilter}
      Table={SettingsTables}
      shouldRefresh={(stateFitlerOfTable) =>
        filtersChanged(filters, stateFitlerOfTable)
      }
    />
  );
};

const Components = {
  TypesTable,
};

export default Components;

export { TypesTable };
