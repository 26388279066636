import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { userLogin, useTranslate } from 'ra-core';
import NestromLogo from "../imports/nestrom_blue.png"
import { linkToNestrom } from "../provider/configs";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    button: {
        width: '100%',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    nestrom_image: {
        width: '60%',
    },
    nestrom_div: {
        marginRight: '16px',
        marginLeft: '16px'
    },
    nestrom_anchor: {
        width: '60%'
    }
}));

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm = ({ redirectTo }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(
        (state) => state.admin.loading > 0
    );
    const translate = useTranslate();
    const classes = useStyles({});

    const validate = (values) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = values => {
        dispatch(userLogin(values, redirectTo));
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="username"
                                name="username"
                                component={Input}
                                label={translate('ra.auth.username')}
                                disabled={isLoading}
                            />
                        </div>
                        <div className={classes.input}>
                            <Field
                                id="password"
                                name="password"
                                component={Input}
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={submitting || isLoading}
                            className={classes.button}
                        >
                            {isLoading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                    <div className={classes.nestrom_div}>
                        <Button size="small" color="primary">
                            Go To:
                            <a target="_blank" rel="noopener noreferrer" href={linkToNestrom} className={classes.nestrom_anchor}>
                                <img className={classes.nestrom_image} src={NestromLogo} alt={'Nestrom'}/>
                            </a>
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;
