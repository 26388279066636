import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import LinearProgressWithLabel from "./progressBar";
import ItemTable from "../../helpers/simple_table";

const useStyles = makeStyles((theme) => ({}));

export default function TaskCompletionRate({ handler, summary: apiRes }) {
  const classes = useStyles();

  let percente = !apiRes.total ? 0 : apiRes.completedCount / apiRes.total;
  let isValidPercentage = percente > apiRes.completionPercentage;
  let acceptancePercentage = `${apiRes.threshold} %`;
  let rows = [
    { vals: ["Total Tasks", apiRes.total] },
    { vals: ["Completed Tasks", apiRes.completedCount] },
    { vals: ["Completion Rate", `${(percente * 100).toFixed(2)} %`] },
    { vals: ["Minimum Acceptable Rate", acceptancePercentage] },
  ];

  return (
    <>
      <ItemTable rows={rows} />
      <br />
      <LinearProgressWithLabel
        value={percente * 100}
        isValidPercentage={isValidPercentage}
      />
    </>
  );
}
