export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  // name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function formatCurrency(n, currency) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency && currency !== "" ? currency : "AED",
  }).format(n);
  return `${formatter.replace(/\s*AED\s*/gi, "")} ${currency}`;
}

export function formatPercentage(n) {
  return n ? `${n.toFixed(2)} %` : "";
}

export function getDefaultTime() {
  let date = new Date();
  return { year: date.getFullYear(), month: date.getMonth() + 1 };
}

export function filtersChanged(f1, f2) {
  return (
    f1.cost_center !== f2.cost_center ||
    f1.vendor !== f2.vendor ||
    f1.year !== f2.year ||
    f1.month !== f2.month
  );
}

export function allFilters(filters) {
  return !!(
    filters.cost_center &&
    filters.vendor &&
    filters.year &&
    filters.month
  );
}

export function setWithExpiry(key, value, ttl = 20000) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function getTimeStampFromDateStr(dateVal, startOrEnd) {
  let [yyyy, mm, dd] = dateVal.split("-");
  let date = new Date();
  date.setFullYear(yyyy);
  date.setMonth(Number(mm) - 1);
  date.setDate(dd);
  date.setHours(startOrEnd === "start" ? 0 : 23);
  date.setMinutes(startOrEnd === "start" ? 0 : 59);
  date.setSeconds(startOrEnd === "start" ? 0 : 59);
  date.setMilliseconds(0);

  let utc_timestamp = date.getTime();
  console.log("utc_timestamp", utc_timestamp);
  return utc_timestamp;
}

const allMethods = {
  getParameterByName,
  formatPercentage,
  formatCurrency,
  getDefaultTime,
  filtersChanged,
  allFilters,
  setWithExpiry,
  getWithExpiry,
  getTimeStampFromDateStr,
};

export default allMethods;
