import * as React from "react";

import RefreshableTable from "../helpers/refreshableTable";
import SettingsTables from "../helpers/tables";
import { resources } from "../provider/configs";

const headerRow = [
  { id: "name", numeric: false, disablePadding: true, label: "Type" },
  {
    id: "percentage",
    numeric: true,
    disablePadding: false,
    label: "Percentage",
  },
];

const TypesTable = (params) => {
  let setPayloadFilter = (f) => ({
    year: f.year,
    month: f.month,
    cost_center: f.cost_center,
    vendor: f.vendor,
  });

  function handleFetchedData(data) {
    return [
      {
        name: "Technical Audit",
        percentage: data.technicalAuditWeighting || "N/A",
        isPercentage: true,
      },
      {
        name: "Joint Audit",
        percentage: data.jointAuditWeighting || "N/A",
        isPercentage: true,
      },
      {
        name: "Snap Audit",
        percentage: data.snapAuditWeighting || "N/A",
        isPercentage: true,
      },
    ];
  }

  return (
    <RefreshableTable
      filters={{ ...params.filters }}
      columns={headerRow}
      resource={resources["quality-audit-configuration-percentages"].name}
      handleFetchedData={handleFetchedData}
      setPayloadFilter={setPayloadFilter}
      Table={SettingsTables}
      shouldRefresh={(stateFitlerOfTable) => {
        console.log("compare", stateFitlerOfTable, params.filters);
        return (
          stateFitlerOfTable.month !== params.filters.month ||
          stateFitlerOfTable.year !== params.filters.year ||
          stateFitlerOfTable.cost_center !== params.filters.cost_center ||
          stateFitlerOfTable.vendor !== params.filters.vendor
        );
      }}
    />
  );
};

const Components = {
  TypesTable,
};

export default Components;

export { TypesTable };
