import * as React from "react";
import * as _ from "lodash";
import { KeyActivitiesShow } from "./item_show";

const GroupShow = ({ record }) => {
    let oer_group = record.rows.find(i => i.oer_item.oer_group.totalPercentage).oer_item.oer_group;
    let actual = _.sumBy(record.rows, "actual");
    let target = _.sumBy(record.rows, "oer_item.target");
    return (
        <strong>{oer_group.key} - {oer_group.label}: Total Percentage: {oer_group.totalPercentage} - ({actual} / {target})</strong>
    )
};

const ExpandItemOER = (props) => {
  if (props.record.isGroupHeader) return <GroupShow record={props.record}/>
  return <KeyActivitiesShow record={props.record} />;
};

export default ExpandItemOER;
