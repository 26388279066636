import React from "react";
import { Chart } from "chart.js";
import "chartjs-plugin-labels";

export default class CompletionRatePie extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.labels = ["completed", "open", "Associated-completed", "Associated-open"];
  }

  componentDidUpdate() {
    let dataSet = this.formatData(this.props.data);
    if (this.props.data.total !== 0) {
      if (!this.myChart) this.createChart.bind(this)();
      this.myChart.data.datasets[0].data = dataSet;
      this.myChart.update();
    }
  }

  formatData(data) {
    return [
      data.assigned.PPM.completed +
        data.assigned.CM.completed +
        data.assigned.RM.completed +
        data.assigned.JOINT_AUDIT.completed +
        data.assigned.SNAP_AUDIT.completed,
      data.assigned.PPM.open +
        data.assigned.CM.open +
        data.assigned.RM.open +
        data.assigned.JOINT_AUDIT.open +
        data.assigned.SNAP_AUDIT.open,
      data.associated.PPM.completed +
        data.associated.CM.completed +
        data.associated.RM.completed +
        data.associated.JOINT_AUDIT.completed +
        data.associated.SNAP_AUDIT.completed,
      data.associated.PPM.open +
        data.associated.CM.open +
        data.associated.RM.open +
        data.associated.JOINT_AUDIT.open +
        data.associated.SNAP_AUDIT.open,
    ];
  }

  componentDidMount() {
    let total = this.props.data.total;
    if (total === 0) {
      return null;
    }
    this.createChart.bind(this)();
  }

  createChart() {
    let data = this.formatData(this.props.data);
    let percentages = data.map((c) => `${((c / this.props.data.total) * 100).toFixed(1).replace(".0", "")} % (${c}/${this.props.data.total})`);
    this.myChart = new Chart(this.canvasRef.current, {
      type: "pie",
      data: {
        // labels: this.labels,
        // labels: this.labels.map((l, i) => `${l} - ${data[i]}`),
        labels: this.labels.map((l, i) => `${l} - ${percentages[i]}`),
        datasets: [
          {
            label: "Job Completion Percentage",
            data,
            backgroundColor: [
              "#37bc9b" /* green */,
              "#5d9cec" /* blue */,
              "#30ff8b" /* light-green */,
              "#85b8f7" /* light-blue */,
            ],
            hoverOffset: 4,
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
      options: {
        legend: {
          display: true,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var currentValue = dataset.data[tooltipItem.index];
              return `count: ${currentValue}`;

              // var total = dataset.data[0] + dataset.data[1];
              // var percentage = (currentValue / total) * 100;
              // return percentage.toFixed(1) + "%";
            },
          },
        },

        plugins: {
          labels: {
            render: "percentage",
            // render: "value",
            fontColor: "black",
            fontWeight: "bold",
            fontSize: 10,
            precision: 1,
          },
        },
      },
    });
  }

  render() {
    return (
      <div className="chart-container" id="pie-chart-container">
        <canvas id="pie-chart" ref={this.canvasRef} />
      </div>
    );
  }
}
