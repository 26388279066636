import * as React from "react";

import {
  TopToolbar,
  TextField,
  // EditButton,
  NumberField,
  Show,
  SimpleShowLayout,
  ListButton,
} from "react-admin";

const QualityShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    {/* <EditButton basePath={basePath} record={data} /> */}
  </TopToolbar>
);

export const QualityAuditsShow = (props) => {
  return (
    <Show
      actions={<QualityShowActions />}
      title="Quality Audit Data"
      {...props}
    >
      <SimpleShowLayout>
        <TextField label="Zone" source="zoneId" />
        <TextField label="Description" source="zoneName" />
        <NumberField label="Criticality" source="criticality" />
        <NumberField
          label="Exponential Criticality"
          source="exponential_criticality"
        />
        <NumberField label="Value To Score" source="value_to_score" />
        <NumberField label="Technical Audit" source="technicalAudit" />
        <NumberField label="Joint Audit" source="jointAudit" />
        <NumberField label="Snap Audit" source="snapAudit" />
        <NumberField label="Total Quality" source="total_q" />
        <NumberField label="Total Percentage" source="total" />
      </SimpleShowLayout>
    </Show>
  );
};
