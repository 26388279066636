import React from "react";
import { UPDATE, GET_LIST } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import { resources } from "../provider/configs";
import { authProvider, dataProvider } from "../provider/provider";
import WithHeaderGroupTable from "../helpers/WithHeaderGroupTable.js";
import { formatCurrency } from "../helpers/utils";
import AlertDialog from "../customise/confirmationDialog";

let configResource = resources["deduction_summaries"];
let updateResource = resources["deduction_summaries_update"];

const styles = {
  hide: { display: "none" },
};

class DeductionSummary extends React.Component {
  state = {
    openDialog: false,
    error: null,
    isLoaded: false,
    columns: this.getColumns(
      authProvider.isAllowed(
        this.props.permissions,
        updateResource.app.name,
        updateResource.app.update
      )
    ),
    rows: [],
    filter: {
      vendor: this.props.filters.vendor,
      cost_center: this.props.filters.cost_center,
      year: this.props.filters.year,
      month: this.props.filters.month,
    },
  };

  getColumns(editable) {
    return [
      {
        Header: "Deduction Summary",
        columns: [
          {
            stylePerCell: this.stylePerCell,
            accessor: "type",
            Header: "Type",
          },
          {
            stylePerCell: this.stylePerCell,
            accessor: "value",
            Header: "Deduction",
            editable: editable,
          },
        ],
      },
    ];
  }
  get() {
    this._asyncRequest = dataProvider(GET_LIST, configResource.name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: { ...this.state.filter },
    });
    return this._asyncRequest;
  }

  handleClose(confirm) {
    if (confirm === "Ok") {
      let {
        updateData: { record, field, newValue },
      } = this.state;
      let that = this;
      newValue = Number.parseFloat(newValue);
      that.setState({ isLoaded: false, openDialog: false });
      dataProvider(UPDATE, updateResource.name, {
        id: record.id,
        data: { [field]: newValue },
      }).then((re) => {
        that.props.setLastUpdate(re.data.total);
      });
    } else {
      this.setState({ openDialog: false });
    }
  }

  onEdit(record, field, newValue) {
    this.setState({
      openDialog: true,
      updateData: { record, field, newValue },
    });
  }

  componentDidMount(p) {
    this._isMounted = true;
    this.reFetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._asyncRequest) {
      this._asyncRequest.cancel && this._asyncRequest.cancel();
    }
  }

  stylePerCell({ row, accessor }) {
    if (row.type === "Total Deduction") {
      return { fontWeight: "bold" };
    }
    return {};
  }

  reFetch() {
    if (this.state.isLoaded) this.setState({ isLoaded: false });
    this.get().then(
      (data) => {
        if (!this._isMounted) return;
        let rows = data["deduction_per_types"].map((o) => {
          o.value_original = o.value;
          o.value = formatCurrency(o.value, "AED");
          return o;
        });
        rows.push({
          id: "Total Deduction",
          type: "Total Deduction",
          value: formatCurrency(data["total"], "AED"),
        });

        if (data.isPersisted) {
          this.setState({
            isLoaded: true,
            rows,
            columns: this.getColumns(false),
          });
        } else {
          this.setState({ isLoaded: true, rows });
        }
      },
      (error) => this.setState({ isLoaded: true, error, rows: [] })
    );
  }

  UNSAFE_componentWillReceiveProps(p) {
    this.reFetch();
  }

  render() {
    // const { classes } = this.props;
    const { error, isLoaded, rows, columns, openDialog } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div
          style={{
            top: "50%",
            margin: "auto",
            position: "relative",
            width: "50px",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div
          style={{
            marginLeft: "15px",
          }}
        >
          <WithHeaderGroupTable
            columns={columns}
            rows={rows}
            onEdit={this.onEdit.bind(this)}
          />
          <AlertDialog
            open={openDialog}
            messages={{
              id: "deduction-summary",
              title: "Confirmation!",
              description: () => <span>{"Are You Sure?"}</span>,
              ok: "Update",
              cancel: "Cancel",
            }}
            handler={this.handleClose.bind(this)}
          />
        </div>
      );
    }
  }
}

export default withStyles(styles)(DeductionSummary);
