import React from "react";
import { UPDATE, GET_LIST } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";

import { resources } from "../../provider/configs";
import { dataProvider } from "../../provider/provider";
import AlertDialog from "../../customise/confirmationDialog";
import settingsTablesConfigs from "./settingsTablesConfigs";
import EditableTableWithSaveAll from "./editableTableWithSaveAll.js";

const styles = {
  hide: { display: "none" },
  container: { width: "50%" },
  loader: {
    top: "50%",
    margin: "auto",
    position: "relative",
    width: "50px",
  },
};

function allowEdit({ month, year }, configResource) {
  let currDate = new Date();
  let currYear = currDate.getFullYear();
  let currMonth = currDate.getMonth() + 1;
  let allowEditingOldMonths =
    settingsTablesConfigs[configResource].allowEditingOldMonths;
  let isPreviouseMonth =
    year < currYear || (year == currYear && month <= currMonth);

  return (allowEditingOldMonths && isPreviouseMonth) || month === currMonth;
}

class SettingsComponentWithSaveAll extends React.Component {
  state = {
    openDialog: false,
    configs: settingsTablesConfigs[this.props.configResource],
    resource: resources[this.props.configResource],
    permission: this.props.permission,
    error: null,
    notification: null,
    isLoaded: true,
    isFetched: false,
    // isLoaded: false,
    columns: settingsTablesConfigs[this.props.configResource].columns(
      this.props.permission.update.enabled &&
        allowEdit(this.props.filterValues, this.props.configResource)
    ),
    rows: [],
    data: [],
    loading: false,
    filter: {
      vendor: this.props.filterValues["vendor"],
      cost_center: this.props.filterValues["cost_center"],
      year: this.props.filterValues["year"],
      month: this.props.filterValues["month"],
    },
  };

  get(filters = {}) {
    let filter = {};
    this.state.resource.requiredFilters.forEach(
      (filterKey) =>
        (filter[filterKey] =
          filters[filterKey] || this.props.filterValues[filterKey])
    );

    this._asyncRequest = dataProvider(GET_LIST, this.state.resource.name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter,
    });
    return this._asyncRequest;
  }

  async handleClose(confirm) {
    if (confirm === "Ok") {
      let { updateData: updates } = this.state;
      this.setState({ isLoaded: false, openDialog: false });
      let updatePromise = null;
      if (this.state.configs.onEdit) {
        updatePromise = this.state.configs.onEdit(updates);
      } else {
        updatePromise = new Promise(async (resolve) => {
          let updatesObjsById = {};
          for (let i = 0; i < updates.length; i++) {
            console.log(this.state.resource.name, "update", updates[i]);
            const [obj, accessor, newVal] = updates[i];
            updatesObjsById[obj.id] = updatesObjsById[obj.id]
              ? { ...updatesObjsById[obj.id], [accessor]: newVal }
              : { [accessor]: newVal };
          }
          console.log("updatesObjsById", updatesObjsById);
          for (let id in updatesObjsById) {
            // await new Promise((res) => setTimeout(() => (console.log({
            //      id: id,
            //      data: updatesObjsById[id],
            //    }), res()), 3000)); // TODO:
            await dataProvider(UPDATE, this.state.resource.name, {
              id: id,
              data: updatesObjsById[id],
            });
          }
          resolve(console.log("done"));
        });
      }

      updatePromise.then(this.reFetch.bind(this)).catch((msg) => {
        this.setState({ isLoaded: true, notification: msg });
      });
    } else {
      this.setState({ openDialog: false });
    }
  }

  onEdit(updates) {
    this.setState({
      openDialog: true,
      notification: null,
      updateData: updates,
    });
  }

  componentDidMount(p) {
    this._isMounted = true;
    if (this.state.resource.requiredFilters.length === 0) this.reFetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._asyncRequest) {
      this._asyncRequest.cancel && this._asyncRequest.cancel();
    }
  }

  allFilters() {
    return this.state.resource.requiredFilters.reduce(
      (acc, f) =>
        !!this.state.filter[f] && this.state.filter[f] !== "invalid" && acc,
      true
    );
  }

  reFetch(filters) {
    if (!this.allFilters()) return;
    if (this.state.isLoaded && !this.state.loading) {
      this.setState({ isLoaded: false, loading: true, notification: null });
    }
    this.get(filters).then(
      (data) => {
        if (!this._isMounted) return;
        let rows = this.state.configs.handleResponseAPI(data);
        if (data.data.isPersisted) {
          this.setState({
            isLoaded: true,
            isFetched: true,
            rows,
            data,
            columns: this.state.configs.columns(false),
            loading: false,
            notification: null,
          });
        } else {
          this.setState({
            isLoaded: true,
            isFetched: true,
            data,
            rows,
            loading: false,
            notification: null,
          });
        }
      },
      (error) =>
        this.setState({
          isLoaded: true,
          isFetched: true,
          error,
          rows: [],
          loading: false,
          notification: null,
        })
    );
  }
  filterChanged(obj1, obj2) {
    return (
      obj1.cost_center !== obj2.cost_center ||
      obj1.vendor !== obj2.vendor ||
      obj1.year !== obj2.year ||
      obj1.month !== obj2.month
    );
  }

  UNSAFE_componentWillReceiveProps(p) {
    let isFilterChanged = this.filterChanged(p.filterValues, this.state.filter);
    if (p.fetch) this.reFetch(p.filterValues);

    let editPermissionChanged =
      p.permission.update.enabled !== this.state.permission.update.enabled;
    if (!isFilterChanged && !editPermissionChanged) return;

    let newState = isFilterChanged
      ? {
          filter: { ...p.filterValues },
          isLoaded: true,
          isFetched: false,
          rows: [],
          error: null,
          notification: null,
        }
      : {};
    // if changed the permission or the month filter
    if (
      (isFilterChanged && this.state.filter.month !== p.filterValues['month']) ||
      this.state.permission.update.enabled !== p.permission.update.enabled
    ) {
      newState.columns = this.state.configs.columns(
        p.permission.update.enabled &&
          allowEdit(p.filterValues, this.props.configResource)
      );
    }
    this.setState(newState);
  }

  render() {
    const { classes } = this.props;
    const {
      error,
      isLoaded,
      rows,
      openDialog,
      configs,
      loading,
      notification,
      isFetched,
      data,
      columns,
      resource,
    } = this.state;
    console.log(" this.state", this.state);
    let allFiltersSelected = this.allFilters();
    if (error) {
      return (
        <Alert severity="error">
          <AlertTitle>Error:</AlertTitle>
          {error.message}
        </Alert>
      );
    } else if (!isLoaded && allFiltersSelected) {
      if (!loading && resource.requiredFilters.length === 0) this.reFetch(); // only fetch on apply button click or no filters requierd

      return (
        <Box height="100%" className={classes.loader}>
          <CircularProgress />
        </Box>
      );
    } else if (isLoaded && allFiltersSelected) {
      return (
        <>
          <div style={{ width: configs.width }}>
            <EditableTableWithSaveAll
              validate={configs.validate}
              data={data}
              columns={columns}
              rows={rows}
              onEdit={this.onEdit.bind(this)}
              message={
                isFetched ? null : "Click Search Button To Apply Filters"
              }
            />
            {!!notification && (
              <Alert severity="error">
                <AlertTitle>Update Validation Error:</AlertTitle>
                {notification}
              </Alert>
            )}
            <AlertDialog
              open={openDialog}
              messages={{
                id: "deduction-summary",
                title: "Confirmation!",
                description: () => <span>{"Are You Sure?"}</span>,
                ok: "Update",
                cancel: "Cancel",
              }}
              handler={this.handleClose.bind(this)}
            />
          </div>
        </>
      );
    } else {
      return (
        <div style={{ width: configs.width }}>
          <EditableTableWithSaveAll
            validate={configs.validate}
            data={data}
            columns={columns}
            rows={rows}
            onEdit={this.onEdit.bind(this)}
            message={"please select all filters"}
          />
        </div>
      );
    }
  }
}

export default withStyles(styles)(SettingsComponentWithSaveAll);
