import * as React from 'react';

import {
    TextField,
    NumberField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

import ShowListAction from '../helpers/listAction'

export const ncrShow = (props) => {
    return (
        <Show actions={<ShowListAction />} title="NCR Data" {...props}>
            <SimpleShowLayout>
                <NumberField source="criticality" />
                <TextField source="categoryName" />
                <NumberField source="total" />
                <NumberField source="score" />
            </SimpleShowLayout>
        </Show>);
}

export default ncrShow;
