import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextInput,
  EditController,
  required,
  email,
  ReferenceInput,
  SelectInput,
  PasswordInput,
  useRedirect,
  TopToolbar,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import Button from "@material-ui/core/Button";
import ListIcon from "@material-ui/icons/List";

import CustomSimpleForm from "../customise/CustomSimpleForm";
import CustomToolbar from "../customise/CustomToolbar";
import { resources } from "../provider/configs";

const editStyles = {};

const EditActions = ({ onClickAction }) => {
  const redirect = useRedirect();

  return (
    <TopToolbar>
      <Button
        color="primary"
        onClick={() =>
          onClickAction
            ? onClickAction("list")
            : redirect("/settingsUsers?tab=0&view=list")
        }
        startIcon={<ListIcon />}
      >
        {"Users List"}
      </Button>
    </TopToolbar>
  );
};

class UserEditToStyle extends React.Component {
  render() {
    const { onClickAction, ...propsEtc } = this.props;
    return (
      <>
        <EditActions onClickAction={onClickAction} />
        <EditController {...propsEtc} id={(propsEtc.record || {})._id}>
          {({
            resource,
            record,
            redirect,
            save,
            basePath,
            version,
            ...rest
          }) => {
            let newRecord = { ...record };
            if (newRecord.role && newRecord.role._id) newRecord.role = newRecord.role._id;
            if (newRecord.vendors && newRecord.vendors[0] && newRecord.vendors[0]._id) newRecord.vendors = newRecord.vendors.map(({ _id }) => _id)
            if (newRecord.cost_centers && newRecord.cost_centers[0] && newRecord.cost_centers[0]._id) newRecord.cost_centers = newRecord.cost_centers.map(({ _id }) => _id)

            return (
              <div>
                {newRecord && (
                  <CustomSimpleForm
                    toolbar={
                      <CustomToolbar
                        allowDelete={propsEtc.permissions ? propsEtc.permissions.destroy.enabled : false}
                        editable={true}
                        onCancel={() => onClickAction("list")}
                      />
                    }
                    basePath={basePath}
                    // redirect={redirect}
                    redirect={() => {
                      onClickAction("list");
                      return "/settingsUsers?tab=0&view=list";
                    }}
                    resource={resource}
                    record={newRecord}
                    save={save}
                    version={version}
                  >
                    <TextInput
                      source="username"
                      label={"Username"}
                      validate={[required()]}
                    />
                    <TextInput
                      source="fullName"
                      label={"Full Name"}
                      validate={[required()]}
                    />
                    <TextInput
                      source="email"
                      label={"Email"}
                      validate={[required(), email()]}
                      type="email"
                    />
                    <ReferenceInput
                      label="Role"
                      source="role"
                      reference={resources["roles"].name}
                      validate={[required()]}
                      emptyValue={null}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceArrayInput label="Vendors" source="vendors" reference={resources["vendors"].name}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput label="Cost Centers" source="cost_centers" reference={resources["cost_centers"].name}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <PasswordInput source="password" />
                  </CustomSimpleForm>
                )}
              </div>
            );
          }}
        </EditController>
      </>
    );
  }
}

const UserEdit = withStyles(editStyles)(UserEditToStyle);
export default UserEdit;

export { UserEdit };
