import React, { useState, useCallback } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3a97f3",
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: "1px solid white",
  },
  body: {
    border: "1px solid white",
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
      // backgroundColor: '#595959',
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
    maxHeight: 10,
  },
  margin: {
    // margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const EditableTableWithSaveAll = ({
  columns,
  message,
  rows: propsRows,
  onEdit,
  data,
  validate,
}) => {
  const classes = useStyles();
  let [rows] = useState(clone(propsRows));
  let [warnings, setWarning] = useState({});
  let [updating, setUpdating] = useState([]);
  let [updates, setUpdates] = useState([]);
  let inputs_ids = [];

  const filterUpdating = useCallback(
    function (obj, accessor) {
      return updating.filter((ele) => ele !== `${obj.id}-${accessor}`);
    },
    [updating]
  );

  const filterUpdates = useCallback(
    function (obj, accessor) {
      return updates.filter(
        (ele) => ele[0].id !== obj.id || ele[1] !== accessor
      );
    },
    [updates]
  );

  let getOriginal = useCallback(
    (obj, accessor) =>
      _.result(obj, `${accessor}_original`, _.result(obj, accessor, "-")),
    []
  );

  const removeUpdatesAndUpdating = useCallback(
    function (obj, accessor, e) {
      for (let i = 0; i < rows.length; i++) {
        const { id } = rows[i];
        if (obj.id === id) {
          rows[i][accessor] = propsRows[i][accessor];
          rows[i][accessor + "_original"] =
            propsRows[i][accessor + "_original"];
          break;
        }
      }

      let newWarnings = validate({ data: rows }).reduce(
        (acc, [o, a, msg]) => ({
          ...acc,
          [`${o.id}-${a}`]: msg,
        }),
        {}
      );
      setWarning({ ...newWarnings });
      setUpdates([...filterUpdates(obj, accessor)]);
      setUpdating([...filterUpdating(obj, accessor)]);
    },
    [
      setWarning,
      setUpdates,
      setUpdating,
      filterUpdates,
      filterUpdating,
      propsRows,
      rows,
      validate,
    ]
  );

  const addToUpdates = useCallback(
    (value, obj, accessor) => {
      let newVal = Number(value);
      if (isNaN(newVal)) {
        return setWarning({
          ...warnings,
          [`${obj.id}-${accessor}`]: "Please Enter A Number",
        });
      }
      let original = `${getOriginal(obj, accessor)}`;
      let original_param = `${getOriginal(
        propsRows.find(({ id }) => id === obj.id),
        accessor
      )}`;
      if (`${newVal}` === `${original}`) return;
      if (`${newVal}` === `${original_param}`) {
        console.log(
          "updates before calling removeUpdatesAndUpdating",
          updates.length
        );
        return removeUpdatesAndUpdating(obj, accessor);
      }

      obj[accessor] = newVal;
      obj[accessor + "_original"] = newVal;

      let newWarnings = validate({ data: rows }).reduce(
        (acc, [obj, accessor, msg]) => ({
          ...acc,
          [`${obj.id}-${accessor}`]: msg,
        }),
        {}
      );
      console.log("newWarnings", newWarnings, Object.keys(newWarnings).length);
      setWarning({ ...newWarnings });
      setUpdates([
        ...filterUpdates(obj, accessor),
        [obj, accessor, newVal, data],
      ]);
    },
    [
      setWarning,
      setUpdates,
      removeUpdatesAndUpdating,
      getOriginal,
      validate,
      data,
      filterUpdates,
      propsRows,
      rows,
      warnings,
      updates,
    ]
  );

  function isUpdating(id, accessor) {
    return updating.find((str) => str === `${id}-${accessor}`);
  }

  function isUpdates(qId, qAccessor) {
    let arr = updates.find(
      ([obj, accessor, newVal]) => obj.id === qId && accessor === qAccessor
    );
    return arr ? arr[2] : null;
  }

  function onClickSave(e) {
    console.log("saving updates", updates);
    onEdit(updates);
  }

  let colSpan = columns.reduce(
    (acc, { columns: subCols }) => acc + subCols.length,
    0
  );

  let InformativeMessage = (msg) => (
    <StyledTableRow>
      <StyledTableCell
        style={{ textAlign: "center", fontWeight: "bold" }}
        colSpan={colSpan}
      >
        {msg}
      </StyledTableCell>
    </StyledTableRow>
  );
  console.log("------------------------------------------", warnings);
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow key={"mainHeader"}>
              {columns.map((h, m) => (
                <StyledTableCell
                  align="center"
                  key={`${h.Header} - ${m}`}
                  colSpan={
                    h.columns.length +
                    h.columns.filter((c) => c.editable && rows.length).length
                  }
                  rowSpan={h.columns[0].Header === "" ? 2 : 1}
                >
                  {h.Header}
                </StyledTableCell>
              ))}
            </TableRow>
            <TableRow key={"subHeader"}>
              {columns.map((h, ind) =>
                h.columns.map((r) =>
                  r.Header === "" ? null : (
                    <StyledTableCell
                      colSpan={r.editable && rows.length ? 2 : 1}
                      key={`${h.Header}-${r.accessor}`}
                      align="center"
                    >
                      {r.Header}
                    </StyledTableCell>
                  )
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!propsRows.length
              ? null
              : rows.map((obj, i) => (
                  <StyledTableRow key={`${i} - Row`}>
                    {columns.map(({ Header, columns: subCols }) =>
                      subCols.reduce((acc, singleCol, ind) => {
                        let { accessor, stylePerCell, editable } = singleCol;
                        let key = `${obj.id}-${accessor}`;
                        if (isUpdating(obj.id, accessor) && editable) {
                          inputs_ids.push([key, obj, accessor]);
                        }

                        let updatesVal = isUpdates(obj.id, accessor);
                        let inputStyle =
                          updatesVal !== null
                            ? warnings[key]
                              ? { backgroundColor: "red", color: "white" }
                              : {}
                            : // : { backgroundColor: "green", color: "white" }
                              {};

                        if (stylePerCell) {
                          let cellStyle = stylePerCell(
                            { row: obj, accessor },
                            rows,
                            i
                          );
                          inputStyle = { ...inputStyle, ...cellStyle };
                        }

                        acc.push(
                          <StyledTableCell
                            style={inputStyle}
                            key={`cell-${i}-${ind}-${obj.id}`}
                            align="center"
                            // colSpan={editable && !obj.editable ? 2 : 1}
                          >
                            {isUpdating(obj.id, accessor) && editable ? (
                              <input
                                style={{ maxWidth: "80px" }}
                                id={key}
                                defaultValue={getOriginal(obj, accessor)}
                                onChange={(event) => {
                                  event.preventDefault();
                                  let value = event.target.value;
                                  console.log(
                                    "send add to updates:",
                                    obj,
                                    updates.length
                                  );
                                  addToUpdates(value, obj, accessor);
                                }}
                              />
                            ) : (
                              _.result(obj, accessor, "-")
                            )}
                          </StyledTableCell>
                        );
                        if (obj.editable && editable) {
                          acc.push(
                            <StyledTableCell
                              style={
                                stylePerCell
                                  ? {
                                      ...stylePerCell(
                                        { row: obj, accessor },
                                        rows,
                                        i,
                                        true
                                      ),
                                      width: "0.5em",
                                    }
                                  : { width: "0.5em" }
                              }
                              key={`cell-editButton-${i}-${ind}-${obj.id}`}
                              align="center"
                            >
                              {isUpdating(obj.id, accessor) && editable ? (
                                updatesVal !== null ? (
                                  <IconButton
                                    color="primary"
                                    aria-label="edit"
                                    id={`${obj.id}-${accessor}-btn`}
                                    className={classes.margin}
                                    onClick={(e) =>
                                      removeUpdatesAndUpdating(obj, accessor, e)
                                    }
                                  >
                                    <CancelIcon fontSize="small" />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    color="primary"
                                    aria-label="edit"
                                    id={`${obj.id}-${accessor}-btn`}
                                    className={classes.margin}
                                  >
                                    <CheckIcon fontSize="small" />
                                  </IconButton>
                                )
                              ) : (
                                <>
                                  {editable && (
                                    <IconButton
                                      color="primary"
                                      aria-label="edit"
                                      className={classes.margin}
                                      onClick={(e) =>
                                        setUpdating([
                                          ...filterUpdating(
                                            obj,
                                            accessor
                                          ).filter((str) => {
                                            let [objId, ...field] = str.split(
                                              "-"
                                            );
                                            return (
                                              isUpdates(
                                                objId,
                                                field.join("-")
                                              ) !== null
                                            );
                                          }),
                                          key,
                                        ])
                                      }
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  )}
                                </>
                              )}
                            </StyledTableCell>
                          );
                        } else if (editable) {
                          acc.push(
                            <StyledTableCell
                              style={
                                stylePerCell
                                  ? {
                                      ...stylePerCell(
                                        { row: obj, accessor },
                                        rows,
                                        i,
                                        true
                                      ),
                                      width: "0.5em",
                                    }
                                  : { width: "0.5em" }
                              }
                              key={`cell-editButton-${i}-${ind}-${obj.id}`}
                              align="center"
                            ></StyledTableCell>
                          );
                        }
                        return acc;
                      }, [])
                    )}
                  </StyledTableRow>
                ))}
            {message && !propsRows.length && InformativeMessage(message)}
            {!propsRows.length &&
              !message &&
              InformativeMessage("No Data Available")}
          </TableBody>
        </Table>
      </TableContainer>
      {!Object.keys(warnings).length
        ? null
        : Object.keys(warnings)
            .filter((e) => warnings[e])
            .map((warning) => (
              <div key={`Warning-${warning}`}>
                <Alert severity="error">
                  <AlertTitle>Update Validation Error:</AlertTitle>
                  {warnings[warning]}
                </Alert>
                <br />
              </div>
            ))}
      {!propsRows.length ? null : (
        <FormControl
          variant="filled"
          className={classes.formControl}
          style={{ float: "right" }}
        >
          <Button
            aria-label="Apply Changes"
            onClick={onClickSave}
            variant="contained"
            color="secondary"
            size="large"
            style={{ minHeight: "56px" }}
            disabled={
              !(updates.length > 0) || Object.keys(warnings).length !== 0
            }
            startIcon={<SaveIcon />}
          >
            Save Changes
          </Button>
        </FormControl>
      )}
    </>
  );
};

export default EditableTableWithSaveAll;
