import React, { useState } from "react";
import { TopToolbar } from "react-admin";

import SendResultTo from "./SendResultTo/sendResultTo";
import DownloadPDF from "./DownloadPDF";

const TotalAction = (props) => {
  let { filterValues, data, setLastUpdate, permissions } = props;

  return (
    <TopToolbar>
      <DownloadPDF data={data} filter={filterValues} setLastUpdate={setLastUpdate} permissions={permissions} />
      <SendResultTo data={data} filter={filterValues} setLastUpdate={setLastUpdate} permissions={permissions} />
    </TopToolbar>
  );
};

export default TotalAction;
