import React from "react";
import _ from "lodash";
import ItemTable from "../helpers/simple_table";

const headerRow1 = [
  { id: "qaa_zone.label", label: "Zone" },
  { id: "criticality", label: "Criticality" },
  { id: "description", label: "Description" },
  { id: "valueToScore", label: "Value To Score" },
];

const tableHeader1 = headerRow1.map((r) => r["label"]);

export const QAAItemShow = function ({ record }) {
  let rows = [
    {
      isHeader: true,
      vals: tableHeader1,
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => _.result(record, r["id"])),
    }
  ];
  return <ItemTable rows={rows} />;
};

export const QAA_ITEM_SHOW = (props) => {
  return <QAAItemShow record={props.record.qaa_item} />;
};

export default { QAA_ITEM_SHOW, QAAItemShow };
