import React from "react";
import ItemTable from "../helpers/simple_table";

const headerRow1 = [
  { id: "Ref", label: "Ref" },
  { id: "ValueDriver", label: "Value Driver" },
  { id: "Measurement", label: "Measurement" },
  { id: "Goal", label: "Goal" },
];
const tableHeader1 = headerRow1.map((r) => r["label"]);

const headerRow2 = [
  { id: "Benefit", label: "Benefit" },
  { id: "KPI", label: "KPI" },
  { id: "Frequency", label: "Frequency" },
  { id: "Target", label: "Target" },
];
const tableHeader2 = headerRow2.map((r) => r["label"]);

export const RegulatoryComplianceItemShowTable = function ({ record }) {
  let rows = [
    {
      isHeader: true,
      vals: tableHeader1,
    },
    {
      isHeader: false,
      vals: headerRow1.map((r) => record[r["id"]]),
    },
    {
      isHeader: true,
      vals: tableHeader2,
    },
    {
      isHeader: false,
      vals: headerRow2.map((r) => record[r["id"]]),
    },
  ];
  return <ItemTable rows={rows} />;
};

export const RegulatoryComplianceItemShow = (props) => {
  return <RegulatoryComplianceItemShowTable record={props.record.regulatory_compliance_item} />;
};

export default { RegulatoryComplianceItemShow, RegulatoryComplianceItemShowTable };
