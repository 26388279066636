import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { resources } from "../provider/configs";
import { UPDATE, CREATE, GET_LIST } from "react-admin";
import { authProvider, dataProvider } from "../provider/provider";
import classnames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { allFilters, filtersChanged } from "../helpers/utils";

let configResource = resources["comments"];

const styles = {
  textarea: {
    width: "100%",
    resize: "none",
    border: "none",
  },
  textwrapper: {
    border: "1px solid #999999",
    margin: "5px 0",
    padding: "3px",
  },
  hide: { display: "none" },
};

class CommentsAside extends React.Component {
  state = {
    error: null,
    isLoaded: false,
    comments: [],
    filter: {
      vendor: this.props.filters.vendor,
      cost_center: this.props.filters.cost_center,
      year: this.props.filters.year,
      month: this.props.filters.month,
    },
  };

  postOrEdit(e) {
    e.stopPropagation();
    this.setState({ isLoaded: false });
    let { comments } = this.state;

    let text = document.getElementById("commentsArea").value;
    (!comments.length ? this.post(text) : this.edit(text)).then(
      this.reFetch.bind(this)
    );
  }

  post(text) {
    return dataProvider(CREATE, configResource.name, {
      data: { ...this.props.filters, content: text },
    });
  }

  edit(text) {
    return dataProvider(UPDATE, configResource.name, {
      id: this.state.comments[0].id,
      data: { content: text },
    });
  }

  get() {
    return dataProvider(GET_LIST, configResource.name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: { ...this.props.filters },
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.reFetch();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (filtersChanged(props.filters, this.state.filter)) {
      this.setState({
        filter: {
          vendor: props.filters.vendor,
          cost_center: props.filters.cost_center,
          year: props.filters.year,
          month: props.filters.month,
        },
      });
      this.reFetch();
    }
  }

  reFetch() {
    if (this.state.isLoaded) this.setState({ isLoaded: false });
    this.get().then(
      (res) => {
        if (!this._isMounted) return;
        let { data } = res;
        this.setState({ isLoaded: true, comments: data });
      },
      (error) => this.setState({ isLoaded: true, error, comments: [] })
    );
  }

  render() {
    const { classes, filters, permissions } = this.props;
    let allowEdit = authProvider.isAllowed(
      permissions,
      resources["comments"].app.name,
      resources["comments"].app.update || "update"
    );
    const { error, isLoaded, comments } = this.state;

    if (!allFilters(filters)) return <>{"Not Loaded..."}</>;
    let currentComment = comments[0] ? comments[0].content : "";

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div
          style={{
            top: "50%",
            margin: "auto",
            position: "relative",
            width: "50px",
          }}
        >
          <CircularProgress />
        </div>
      );
      // return <Loading style={{margin: '10px auto'}} />;
    } else {
      return (
        <div style={{ padding: "20px" }}>
          <Typography style={{ fontWeight: "bold" }} variant="h5">
            Comments
          </Typography>
          <br />
          <br />
          <div style={{ minHeight: "150px" }}>
            <CircularProgress
              className={classnames({ [classes.hide]: isLoaded })}
            />
            <Paper elevation={3}>
              <div className={classes["textwrapper"]}>
                <textarea
                  className={classes["textarea"]}
                  cols="2"
                  rows="5"
                  id="commentsArea"
                  defaultValue={currentComment}
                  placeholder={"Create The First Comment"}
                />
              </div>
            </Paper>
            <br />
            <Button
              disabled={!isLoaded || !allowEdit}
              variant="contained"
              onClick={this.postOrEdit.bind(this)}
            >
              {comments.length ? "Save" : "Add"} Comment
            </Button>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(styles)(CommentsAside);
