import React from "react";
import Typography from "@material-ui/core/Typography";
import { CardContentInner } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

import UserChart from "./userChart";
import CompletionRatePie from "./CompletionRatePie";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "block",
    transitionDuration: "0.3s",
    height: "100%",
  },
  title: {
    textAlign: "center",
    fontSize: "20px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  alert: {
    boxShadow: "3px 3px 3px #888888",
  },
}));

const defaultDataGroup = {
  CM: { count: 0, open: 0, completed: 0, tasks: [] },
  JOINT_AUDIT: { count: 0, open: 0, completed: 0, tasks: [] },
  NCRs: 0,
  PPM: { count: 0, open: 0, completed: 0, tasks: [] },
  RM: { count: 0, open: 0, completed: 0, tasks: [] },
  SNAP_AUDIT: { count: 0, open: 0, completed: 0, tasks: [] },
  NCR_confirmation: { count: 0, open: 0, completed: 0, tasks: [] },
  PRS_APPROVAL: { count: 0, open: 0, completed: 0, tasks: [] },
  total: 0,
};

const defaultData = {
  assigned: defaultDataGroup,
  associated: defaultDataGroup,
  ivivaJobCards: { count: 0, jobs: [] },
  total: 0,
};

export default function ViewCharts(props) {
  const classes = useStyles();

  const [state] = React.useState({
    data: props.data,
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Card className={classes.card}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Auditor KPIs
          </Typography>

          <CardContentInner>
            <React.Fragment>
              <UserChart data={(state.data || {}).assigned ? state.data : defaultData} type={"assigned"}/>
            </React.Fragment>
          </CardContentInner>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className={classes.card}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Associated Tasks
          </Typography>

          <CardContentInner>
            <React.Fragment>
              <UserChart data={(state.data || {}).associated ? state.data : defaultData} type={"associated"}/>
            </React.Fragment>
          </CardContentInner>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className={classes.card}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Job Completion Percentage
          </Typography>
          <CardContentInner>
            {(state.data || defaultData).total === 0 ? (
              <Alert className={classes.alert} severity="warning">
                No Data Available
              </Alert>
            ) : (
              <CompletionRatePie data={state.data || defaultData} />
            )}
          </CardContentInner>
        </Card>
      </Grid>
    </Grid>
  );
}
