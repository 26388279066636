import React, { useCallback, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  DateField,
  Filter,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import ExpandLogs from "./Expands/ExpandLogs";
import { defaultSort } from "../provider/strapiRequestsCustomization";

const useStyles = makeStyles({
  list: { padding: "15px" },
});

const LogsFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Type" source="type" />
      <TextInput label="Ref Id" source="refId" />
    </Filter>
  );
};

const LogsPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

function createChangeDescription({ data }, field) {
  return data[field].description;
}

function CustomData({ record }) {
  const classes = useStyles();
  let fields = Object.keys(record.data);
  if (record.action === "create") {
    if (fields.length === 1) {
      return fields.map((f, i) => (
        <span key={`log-item-${record._id}-${i}`}>
          {createChangeDescription(record, f)}
        </span>
      ));
    } else {
      return (
        <ul className={classes.list}>
          {fields.map((f, i) => (
            <li key={`log-item-${record._id}-${i}`}>
              {createChangeDescription(record, f)
                .replace("new", "")
                .replace(" has been added with the value of", ":")}
            </li>
          ))}
        </ul>
      );
    }
  }
  if (record.action === "update") {
    return (
      <>
        <ul className={classes.list}>
          {fields.map((f, i) => (
            <li key={`log-item-${record._id}-${i}`}>
              {createChangeDescription(record, f)}
            </li>
          ))}
        </ul>
      </>
    );
  }
  return fields.map((f, i) => (
    <span key={`${record._id}-${i}`}>
      {createChangeDescription(record, f)}
      <br />
    </span>
  ));
}

const perPage = 100;
export const AuditLogsList = (props) => {
  let [filterDefaultValues, setFilterDefaultValues] = useState({});
  let rowClickCall = useCallback(
    function (id, basePath, record) {
      setFilterDefaultValues({ type: record.type, refId: record.refId });
      let pagination = `page=1&perPage=${perPage}`;
      let resource = basePath.match(/([A-z]|-)+/gi)[0];
      let [sort, sortOrder] = defaultSort(resource).split(":");
      let sortQ = `sort=${sort}&order=${sortOrder}`;
      let displayedFilters = 'displayedFilters={"type":true,"refId":true}';
      let filter = `filter={"type":"${record.type}","refId":"${record.refId}"}`;
      let q = `${displayedFilters}&${filter}&${sortQ}&${pagination}`;
      return `${basePath}?${q}`;
    },
    [setFilterDefaultValues]
  );

  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<LogsPagination />}
      perPage={perPage}
      filters={<LogsFilters />}
      filterDefaultValues={filterDefaultValues}
      exporter={false}
    >
      <Datagrid expand={ExpandLogs} rowClick={rowClickCall}>
        <DateField
          showTime={true}
          label="Date(UAE Time)"
          source="createdAt"
          locales="AE"
          options={{
            timeZone: "Asia/Dubai",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }}
        />
        <TextField label="Type" source="type" />
        <TextField label="User" source="user.fullName" />
        <TextField label="RefId" source="refId" />
        <TextField label="Action" source="action" />
        <CustomData label="Data" source="data" />
      </Datagrid>
    </List>
  );
};
