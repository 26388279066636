import * as React from "react";
import { cloneElement } from "react";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  List,
  Datagrid,
  TextField,
} from "react-admin";
import exporters from '../helpers/exporters'

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const UsersList = ({ ...props }) => {
  return (
    <div>
      <List
        {...props}
        actions={<ListActions />}
        bulkActionButtons={false}
        exporter={(users) => exporters(users, "users")}
      >
        <Datagrid>
          <TextField label="Username" source="username" />
          <TextField label="Full Name" source="fullName" />
          <TextField label="Role" source="role.name" />
          <TextField label="Email" source="email" />
        </Datagrid>
      </List>
    </div>
  );
};
