import React from "react";
import { Chart } from "chart.js";
export default class UserChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.type = props.type;
    this.labels =
      props.type == "assigned"
        ? [
            "PPMs",
            "CMs",
            "RMs",
            "Joint Audits",
            "Snap Audits",
            "PRS APPROVAL",
            "NCR confirmation",
            "NCRs Raised",
            "Iviva Cards",
          ]
        : [
            "PPMs",
            "CMs",
            "RMs",
            "Joint Audits",
            "Snap Audits",
            "PRS APPROVAL",
            "NCR confirmation",
          ];
  }

  componentDidUpdate() {
    this.myChart.data.datasets = this.formatData(this.props.data);
    this.myChart.update();
  }

  formatData(data) {
    const type = this.type;
    let completed = [
      data[type].PPM.completed,
      data[type].CM.completed,
      data[type].RM.completed,
      data[type].JOINT_AUDIT.completed,
      data[type].SNAP_AUDIT.completed,
      data[type].PRS_APPROVAL.completed,
      data[type].NCR_confirmation.completed,
      undefined, // NCRs
      undefined, // ivivaJobCards
    ];
    let open = [
      data[type].PPM.open,
      data[type].CM.open,
      data[type].RM.open,
      data[type].JOINT_AUDIT.open,
      data[type].SNAP_AUDIT.open,
      data[type].PRS_APPROVAL.open,
      data[type].NCR_confirmation.open,
      undefined, // NCRs
      undefined, // ivivaJobCards
    ];
    let closedBySystem = [
      data[type].PPM.closedBySystem,
      data[type].CM.closedBySystem,
      data[type].RM.closedBySystem,
      data[type].JOINT_AUDIT.closedBySystem,
      data[type].SNAP_AUDIT.closedBySystem,
      data[type].PRS_APPROVAL.closedBySystem,
      data[type].NCR_confirmation.closedBySystem,
      undefined, // NCRs
      undefined, // ivivaJobCards
    ];

    let datasets = [
      {
        data: completed,
        label: "Completed",
        barThickness: 25,
        backgroundColor: "#37bc9b", // Green
      },
      {
        data: closedBySystem,
        label: "Closed By System",
        barThickness: 25,
        backgroundColor: "#30ff8b", // Light Green
      },
      {
        data: open,
        label: "Open",
        barThickness: 25,
        backgroundColor: "#5d9cec", // blue
      },
    ];

    if (this.type == "assigned") {
      const count = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        data.NCRs,
        (data.ivivaJobCards || {}).count,
      ];
      datasets.push({
        data: count,
        label: "Count",
        barThickness: 25,
        backgroundColor: "#f9ea9c", // yellow
      });
    }

    return datasets;
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "bar",
      options: {
        aspectRatio: 1,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
        legend: { display: true },
        tooltips: { enabled: true },
        plugins: {
          labels: {
            render: (p) => (p.value === 0 ? "" : p.value),
          },
        },
      },
      data: {
        labels: this.labels,
        datasets: this.formatData(this.props.data),
      },
    });
  }

  render() {
    return (
      <div id="bar-chart-container">
        <canvas id="bar-chart" ref={this.canvasRef} />
      </div>
    );
  }
}
