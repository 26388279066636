import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  textwrapper: {
    border: "1px solid #999999",
    margin: "5px 0",
    padding: "3px",
    maxWidth: "30%",
    minHeight: "100px",
  },
});

const CommentDetails = ({ record }) => {
  const classes = useStyles();

  return (
    <>
      <strong>{"Content:"}</strong>
      <Paper className={classes["textwrapper"]} elevation={3}>
        {record.content}
      </Paper>
    </>
  );
};

export default CommentDetails;
