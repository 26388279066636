import React, { useState } from "react";
import {
  List,
  // TextField,
  // NumberField,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

import { CommonFilter } from "../helpers/commonFilters";
import MyDatagrid from "../customise/CustomDataGrid";
import { MergeFields } from "../helpers/sharedComponents";
import { TypesTable } from "./configTable";
// import { TypesTable } from './table'
import * as _ from "lodash";

import Grid from "@material-ui/core/Grid";
import {
  getParameterByName,
  getDefaultTime,
  filtersChanged,
  allFilters,
} from "../helpers/utils";
import { authProvider } from "../provider/provider";
import exporters from "../helpers/exporters";

const MergeServiceFields = MergeFields(
  "sla_item.serviceType",
  "sla_item.serviceArea"
);
const MergeKpiAndPerformanceFields = MergeFields(
  "sla_item.KPI",
  "sla_item.performanceLevel"
);

const rowStyle = (record, index) => {
  if ((record.sla_item || {}).CAFM) {
    return {
      backgroundColor: "bisque",
    };
  }
  if (record.isGroupHeader) {
    return {
      height: "2em",
      backgroundColor: "#dde6e9",
      textAlign: "center",
      fontSize: "2em",
      fontWeight: "bold",
    };
  }
  let failed = record.state === "3rd Failure";
  return failed
    ? {
        backgroundColor: "red",
        color: "white !important",
        fontWeight: "bold",
      }
    : {
        backgroundColor: index % 2 === 1 ? "white" : "#f5f5f5",
        color: "black !important",
        fontWeight: "bold",
      };
};

const useStyles = makeStyles({
  boldy: { fontWeight: "bold" },
  red: { backgroundColor: "red", color: "white", fontWeight: "bold" },
  passed: {},
});

const notPass = (v) => v.toUpperCase() !== "PASS";
const thirdFailure = ({ record }) =>
  (record.state || "").toUpperCase() === "3RD FAILURE";
const ColoredStateField = ({ record, source }) => <span>{record[source]}</span>;
const Fields = ({ record, source }) => (
  <span>{_.result(record, source, "-")}</span>
);

const isCAFM = (record) => (record.sla_item || {}).CAFM;
export const SLAsList = ({ ...props }) => {
  // minWidth: '150%'
  let filter = getParameterByName("filter") || "{}";
  filter = JSON.parse(filter);
  let { year, month } = getDefaultTime();
  year = filter.year = filter.year || year;
  month = filter.month = filter.month || month;

  let [stateFilter, setStateFilter] = useState({
    vendor: filter.vendor,
    cost_center: filter.cost_center,
    year,
    month,
    displayed: allFilters(filter),
  });

  const classes = useStyles();
  let total_fields = [
    { field: "score", key: "total_response" },
    { field: "penalty", key: "totalPenalties_response" },
  ];
  let colorAllCells = ({ record }) =>
    thirdFailure({ record }) ? classes.red : undefined;

  function filtersUpdated(filters) {
    if (filtersChanged(filters, stateFilter)) {
      setStateFilter({
        displayed: allFilters(filters),
        ...filters,
      });
    }
  }

  const isEditAllowed = () =>
    authProvider.isAllowed(props.permissions, "sla-item-calculation", "update");

  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        pagination={false}
        filters={<CommonFilter filtersUpdated={filtersUpdated} />}
        filterDefaultValues={filter}
        title="SLAs List"
        exporter={(data) => exporters(data, "slas")}
      >
        <MyDatagrid
          rowClick={(id, basePath, record) => record.isGroupHeader || !isEditAllowed() || (id && id.match(/future/i)) || isCAFM(record) ? null : "edit"}
          groupby={"sla_item.sla_group.label"}
          total_fields={total_fields}
          rowStyle={rowStyle}
          colSpan={2}
        >
          <MergeServiceFields
            cellformat={colorAllCells}
            label="Service Type/Area"
          />
          <Fields
            cellformat={colorAllCells}
            width="200"
            label="Key Activity"
            source="sla_item.keyActivity"
          />
          <MergeKpiAndPerformanceFields
            cellformat={colorAllCells}
            label="KPI / Performance Level"
          />
          <Fields
            cellformat={colorAllCells}
            width="400"
            label="Metric"
            source="sla_item.metric"
          />
          <Fields
            cellformat={colorAllCells}
            width="250"
            label="Performance Indicator"
            source="sla_item.performanceIndicator"
          />
          <Fields
            cellformat={colorAllCells}
            label="Target"
            source="sla_item.target"
          />
          <Fields
            cellformat={colorAllCells}
            label="Client Weighting"
            source="sla_item.clientWeighting"
          />
          <ColoredStateField
            label="Pass/Fail"
            source="state"
            cellformat={({ record, source }) =>
              notPass(record[source] || "") ? classes.red : classes.passed
            }
          />
          <Fields
            cellformat={colorAllCells}
            unit={"%"}
            label=" Score&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            source="score"
            className={classes.boldy}
          />
          <Fields
            cellformat={colorAllCells}
            unit={"AED"}
            label="Penalty"
            // locales="fr-FR"
            // options={{ style: "currency", currency: "AED" }}
            source="penalty"
            className={classes.boldy}
          />
        </MyDatagrid>
      </List>
      {stateFilter.displayed && (
        <div style={{ flexGrow: 1 }}>
          {/* <div className={this.classes.root}> */}
          <h1>Configuration Table</h1>
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <TypesTable filters={{ ...stateFilter }} />
            </Grid>
            <Grid item xs={7}></Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
