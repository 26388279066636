import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
} from 'react-admin';

export const RolesList = ({ ...props }) => {
    return (
        <div>
            <List {...props}>
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="Key" />
                </Datagrid>
            </List>
        </div>)
}

