import * as React from "react";

import {
  TopToolbar,
  TextField,
  SimpleShowLayout,
  Show,
  ListButton,
  Datagrid,
  ArrayField,
} from "react-admin";

const CostCentersShowActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="List Of Cost Centers" />
  </TopToolbar>
);

export const CostCenterShow = (props) => {
  return (
    <Show
      actions={<CostCentersShowActions />}
      title="Cost Center Data"
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="key" />
        <TextField source="calculationMethod" label={"Calculation Method"} />
        <ArrayField source="zone_location_mappings">
          <Datagrid>
            <TextField source="zoneName" />
            <TextField source="zoneId" label={"Zone Id"} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default CostCenterShow;
