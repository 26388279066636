import * as React from "react";

import { TextField, NumberField, Datagrid,  ArrayField, Show, SimpleShowLayout } from "react-admin";

import ShowListAction from "../helpers/listAction";
import { OER_ITEM_SHOW } from "./item_show";

export const oerShow = (props) => {
  return (
    <Show actions={<ShowListAction />} title="OER Data" {...props}>
      <SimpleShowLayout>
        <OER_ITEM_SHOW />
        <ArrayField source="keyActivitiesCalculation">
          <Datagrid>
            <TextField source="Ref" />
            <NumberField source="actual" />
            <TextField source="Justification" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default oerShow;
