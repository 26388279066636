import React from "react";
import GaugeChart from "react-gauge-chart";
import Grid from "@material-ui/core/Grid";

const COLORS = {
  RED: "#EA4228",
  ORANGE: "#f0bf06",
  YELLOW: "#fbf139",
  GREEN: "#5BE12C",
  BLACK: "#000",
};

export default class SummaryCharts extends React.Component {
  render() {
    if (!this.props.data) return null;
    return (
      <Grid container spacing={0}>
        {this.props.ids.map((id) => {
          let ele = this.props.data[id];
          if (!ele.valueToScore) return null;
          let eleId = `gauge-chart-${id}`;
          // let color =
          //   ele.rawPercentage < 85
          //     ? COLORS.RED
          //     : ele.rawPercentage < 90
          //     ? COLORS.ORANGE
          //     : ele.rawPercentage < 95
          //     ? COLORS.YELLOW
          //     : COLORS.GREEN;
          let percent = ((ele.rawPercentage - 84) * 100) / 16
          return (
            <Grid item xs={3} key={`grid-chart-${id}`}>
              <GaugeChart
                key={eleId}
                nrOfLevels={420}
                arcsLength={[0.1, 0.28125, 0.225, 0.39375]}
                colors={[COLORS.BLACK, COLORS.RED, COLORS.YELLOW, COLORS.GREEN]}
                percent={ele.rawPercentage < 84 ? 0 : (percent / 100)}
                arcPadding={0}
                textColor={'black'}
                animate={false}
                formatTextValue={(v) => `${(((v * 16) / 100) + 84).toFixed(2)} %`.replace(/(\.0+|0+)$/, '')}
                arcWidth={0.35}
                cornerRadius={4}
                needleColor={(ele.colorConfigs || {}).backgroundColor || 'black'}
              />
              <div style={{ textAlign: "center" }}>{ele.type}</div>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
