import * as React from "react";
import { Route } from "react-router-dom";
import SettingsPRS from "../settings/settingsPRS";
import SettingsUsers from "../settings/settingsUsers";
import NestromTab from "./NestromTab";
import QualityShow from "../QualityAudits/QualityCustomShow";
import Dashboard from "../Dashboard/Dashboard";
import Iviva from "../Iviva/IvivaPull";

export default [
  <Route exact path="/settingsPRS" component={SettingsPRS} />,
  <Route exact path="/settingsUsers" component={SettingsUsers} />,
  <Route exact path="/NestromTab" component={NestromTab} />,
  <Route exact path="/quality-totals/custom" component={QualityShow} />,
  <Route exact path="/userStatistics" component={Dashboard} />,
  <Route exact path="/iviva" component={Iviva} />,
];
