import * as React from 'react';

import {
    TopToolbar,
    TextField,
    SimpleShowLayout,
    Show,
    ListButton,
} from 'react-admin';

const VendorShowActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label={"Vendors List"}/>
    </TopToolbar>
);

export const VendorShow = (props) => {
    return (<Show actions={<VendorShowActions />} title="Vendor Data" {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="vendorKey" />
            <TextField source="serviceType" />
        </SimpleShowLayout>
    </Show>);
}

export default VendorShow;
