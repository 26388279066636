import * as React from "react";
import { Query } from "react-admin";
import Table from "../helpers/WithHeaderGroupTable";

const columns = [
  {
    Header: "",
    columns: [{ Header: "", accessor: "type" }],
  },
  {
    Header: "Technical",
    columns: [
      { Header: "PPM", accessor: "Technical_PPM" },
      { Header: "CM", accessor: "Technical_CM" },
      { Header: "RM", accessor: "Technical_RM" },
    ],
  },
  {
    Header: "Joint Audit",
    columns: [{ Header: "", accessor: "JOINT_AUDIT" }],
  },
  {
    Header: "Snap Audit",
    columns: [{ Header: "", accessor: "SNAP_AUDIT" }],
  },
];

const QualityExpand = ({ record }) => (
  <Query type="getOne" resource="nwe" payload={{ id: record.zoneId }}>
    {({ data, loading, error }) => {
      if (loading) {
        return "Loading...";
      }
      data = {
        Technical: {
          PPM: { count: 0, open: 0, completed: 0, tasks: [] },
          RM: { count: 0, open: 0, completed: 0, tasks: [] },
          CM: { count: 0, open: 0, completed: 0, tasks: [] },
        },
        JOINT_AUDIT: { count: 0, open: 0, completed: 0, tasks: [] },
        SNAP_AUDIT: { count: 0, open: 0, completed: 0, tasks: [] },
        total: 0,
      };

      function reduceAll(keys, key) {
        return keys.reduce((acc, k) => {
          acc[k] = data[k][key];
          return acc;
        }, {});
      }

      data.Technical_PPM = data.Technical.PPM;
      data.Technical_CM = data.Technical.CM;
      data.Technical_RM = data.Technical.RM;

      let keys = [
        "Technical_PPM",
        "Technical_CM",
        "Technical_RM",
        "JOINT_AUDIT",
        "SNAP_AUDIT",
      ];
      let rows = [
        {
          id: 1,
          type: "count",
          ...reduceAll(keys, "count"),
        },
        {
          id: 1,
          type: "open",
          ...reduceAll(keys, "open"),
        },
        {
          id: 1,
          type: "completed",
          ...reduceAll(keys, "completed"),
        },
      ];
      console.log(rows);
      return <Table columns={columns} rows={rows} />;
    }}
  </Query>
);

export default QualityExpand;
