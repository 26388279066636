import * as React from "react";
import { Edit, SimpleForm, SelectInput, TopToolbar, TextField, ListButton } from "react-admin";

const VendorEditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label={"Vendors List"} />
  </TopToolbar>
);

export const VendorEdit = (props) => {
  return (
    <Edit {...props} actions={<VendorEditActions />} >
      <SimpleForm>
        <TextField source="name" />
        <TextField source="vendorKey" />
        <SelectInput
          source="serviceType"
          choices={[
            { id: "hard", name: "Hard" },
            { id: "soft", name: "Soft" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default VendorEdit;
