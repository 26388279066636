import * as React from "react";

import RefreshableTable from "../helpers/refreshableTable";
import WithHeaderGroupTable from "../helpers/WithHeaderGroupTable.js";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import { filtersChanged } from "../helpers/utils";

const TypesTable = ({ filter: filters }) => {
  let columns = [
    {
      Header: "KPI Performance Requirements",
      columns: [
        {
          accessor: "performanceZone",
          Header: "Performance Zones",
          stylePerCell: ({ row, accessor }) => ({
            backgroundColor: row.backgroundColor,
            color: row.fontColor,
          }),
        },
        { accessor: "bound", Header: "Score" },
      ],
    },
    {
      Header: "KPI Penalties",
      columns: [
        {
          accessor: "penatlyPercentageOfMonthlyInvoice",
          Header: "% Monthly Invoice",
        },
      ],
    },
  ];

  let setPayloadFilter = (f) => ({
    vendor: f.vendor,
    cost_center: f.cost_center,
    year: f.year,
    month: f.month,
  });

  function handleFetchedData(data) {
    return data.map((doc) => {
      doc.bound =
        doc.upperBound === 100
          ? `${doc.lowerBound}% +`
          : doc.lowerBound === 0
          ? `- ${doc.upperBound}%`
          : `${doc.lowerBound}% - ${doc.upperBound}%`;
      return doc;
    });
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <CardContent>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <RefreshableTable
              filters={{ ...filters }}
              columns={columns}
              resource={"kpi-penalty-configs"}
              handleFetchedData={handleFetchedData}
              setPayloadFilter={setPayloadFilter}
              Table={WithHeaderGroupTable}
              shouldRefresh={(stateFitlerOfTable) =>
                filtersChanged(stateFitlerOfTable, filters)
              }
            />
          </Grid>
          <Grid item xs={7}></Grid>
        </Grid>
      </CardContent>
    </div>
  );
};

const Components = {
  TypesTable,
};

export default Components;

export { TypesTable };
