import * as React from "react";
import WithHeaderGroupTable from "../helpers/WithHeaderGroupTable.js";
import { formatCurrency } from "../helpers/utils";

import {
  TopToolbar,
  TextField,
  SimpleShowLayout,
  Show,
  ListButton,
  DateField,
  NumberField,
} from "react-admin";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

const PRSApprovalShowActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="List Of Approval Requests" />
  </TopToolbar>
);

const deductionSummaryCols = [
  {
    Header: "Deduction Summary",
    columns: [
      { accessor: "type", Header: "Type" },
      { accessor: "value", Header: "Deduction" },
    ],
  },
];
function processDeductions(data) {
  let rows = data["deduction_per_types"].map((o) => {
    o.value_original = o.value;
    o.value = `${o.value} AED`;
    return o;
  });
  rows.push({
    id: "Total Deduction",
    type: "Total Deduction",
    value: `${data.total} AED`,
  });
  return rows;
}
const invoiceSummaryColums = [
  {
    Header: "Invoices Summary",
    columns: [
      { accessor: "type", Header: "Type" },
      { accessor: "value", Header: "Penality" },
    ],
  },
];
function processInvoiceSummary(data) {
  return [
    {
      id: 1,
      currency: "AED",
      type: "Monthly Value - Contract",
      value: formatCurrency(data.monthlyValueContract, "AED"),
      value_original: data.monthlyValueContract,
    },
    {
      id: 2,
      currency: "AED",
      type: "Deductions",
      value: formatCurrency(data.deductions, "AED"),
      value_original: data.deductions,
    },
    {
      id: 3,
      currency: "AED",
      type: "Monthly Value - Invoice",
      value: formatCurrency(data.monthlyValueInvoice, "AED"),
      value_original: data.monthlyValueInvoice,
    },
    {
      id: 4,
      currency: "AED",
      type: "Monthly Value - Credit Note",
      value: formatCurrency(data.monthlyValueCreditNote, "AED"),
      value_original: data.monthlyValueCreditNote,
    },
  ];
}
const totalStructuredColumns = [
  {
    Header: "PRS Total Percentages",
    columns: [
      { accessor: "type", Header: "Type" },
      { accessor: "rawPercentage", Header: "PRS Percentage" },
      { accessor: "valueToScore", Header: "Value To Score" },
      { accessor: "percentage", Header: "Percentage", isPercentage: true },
    ],
  },
];
function processTotalStructured(data) {
  return data;
}

const Snapshot = ({ record, permissions }) => {
  let snapshot = record.snapshot;

  return (
    <CardContent>
      <Grid container spacing={0}>
        <Grid container>
          <Grid item xs={12}>
            <WithHeaderGroupTable
              columns={totalStructuredColumns}
              rows={processTotalStructured(snapshot.totalStructuredRes.data)}
              onEdit={() => {}}
            />
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                padding: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                background: "#e6e6e6",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Financial Summary
            </h3>
          </Grid>
          <Grid container item xs={12} spacing={0}>
            <Grid item xs={6}>
              <WithHeaderGroupTable
                columns={invoiceSummaryColums}
                rows={processInvoiceSummary(
                  snapshot.totalDetails.invoiceSummary
                )}
                onEdit={() => {}}
              />
            </Grid>
            <Grid item xs={6}>
              <WithHeaderGroupTable
                columns={deductionSummaryCols}
                rows={processDeductions(snapshot.totalDetails.deductionSummary)}
                onEdit={() => {}}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const PRSApprovalShow = (props) => {
  return (
    <Show
      actions={<PRSApprovalShowActions />}
      title="Approval Request Data"
      {...props}
    >
      <SimpleShowLayout>
        <NumberField source="year" label="Year" />
        <NumberField source="month" label="Month" />
        <TextField source="status" label="Status" />
        <DateField source="createdAt" label="CreatedAt" />
        <TextField source="approvedBy" label="Approved By" />
        <DateField source="approvedAt" label="Approved At" />
        <Snapshot source="snapshot" label="CreatedAt" />
      </SimpleShowLayout>
    </Show>
  );
};

export default PRSApprovalShow;
