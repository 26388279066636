import * as React from "react";

import _ from "lodash";
import WithHeaderGroupTable from "../helpers/WithHeaderGroupTable.js";
import translations from "../translation/translation.json";
import { formatPercentage } from "../helpers/utils";

export default ({ data, ids, filter }) => {
  let monthName = translations["en"][filter.month];
  const COLUMNS = [
    {
      Header: "Service Provider Performance Value Summary",
      columns: [
        { accessor: "label", Header: "SPPV Group" },
        { accessor: "totalPercentage", Header: "Group Percentage" },
      ],
    },
    {
      Header: monthName,
      columns: [
        { accessor: "percentage", Header: "Percentage" },
        { accessor: "subPercentage", Header: "Result" },
      ],
    },
  ];
  const grouped = _.groupBy(
    Object.values(data).filter((e) => !e.isGroupHeader),
    "sppv_item.sppv_group.key"
  );
  let rows = [];
  for (const group in grouped) {
    if (Object.hasOwnProperty.call(grouped, group)) {
      const sppv_item_calculation = grouped[group][0];
      console.log({ sppv_item_calculation });
      let { subPercentage, totalPercentage, label, percentage, key: id } = sppv_item_calculation.sppv_item.sppv_group;
      rows.push({
        id,
        label,
        totalPercentage: formatPercentage(totalPercentage),
        percentage: formatPercentage(percentage),
        subPercentage: formatPercentage(subPercentage),
      });
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <WithHeaderGroupTable columns={COLUMNS} rows={rows} />
    </div>
  );
};
