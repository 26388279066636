import React, { useCallback } from "react";
import {
  usePermissions,
  useRedirect,
  Loading,
  CardContentInner,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import Modal from "@material-ui/core/Modal";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Snackbar from "@material-ui/core/Snackbar";

import { resources } from "../provider/configs";
import { authProvider, dataProvider } from "../provider/provider";
import CreateFilters from "./filters";
import { getTimeStampFromDateStr } from "../helpers/utils";

let {
  ivivaProcessjc,
  ivivaProcessppm_workorder,
  ivivaProcesssnapcard,
} = resources;

function getCurrentDateStr() {
  let date = new Date();
  let year = date.getFullYear();
  // getMonth() return month index
  let month = `${date.getMonth() + 1}`.padStart(2, "0");
  let day = `${date.getDate()}`.padStart(2, "0");

  let str = `${year}-${month}-${day}`;
  return str;
}

const useStyles = makeStyles((theme) => ({
  pullButton: {
    backgroundColor: "green",
    float: "right",
    "&:hover": { backgroundColor: "darkGreen" },
  },

  loaderContainer: {
    top: "50%",
    margin: "auto",
    position: "relative",
    width: "50px",
  },
  chartContainer: {
    width: "40%",
    display: "inline-block",
  },
  button: {
    float: "right",
  },
  card: {
    display: "block",
    transitionDuration: "0.3s",
    // minHeight: "400px",
    // position: "relative",
    // height: "100%",
  },
  title: {
    textAlign: "center",
    fontSize: "15px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  gray: { color: "grey" },
  alert: {
    boxShadow: "3px 3px 3px #888888",
    width: "45%",
    float: "right",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #000",
    // borderRadius: "10px",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    minHeight: "100px",
  },
  actionsToBottom: {
    // position: "absolute",
    // bottom: 0,
    minWidth: "50%",
  },
  buttons: {
    float: "left",
    // borderBottom: "1px solid #3c99f4",
  },
}));

const ButtonContainer = ({ openDialog, button: { type, name }, message }) => {
  const classes = useStyles();
  return (
    <tr>
      <td style={{}}>{message}</td>
      <td>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.buttons}
          onClick={() => openDialog(type)}
        >
          {name}
        </Button>
      </td>
    </tr>
  );
};

export default function Iviva() {
  const classes = useStyles();
  const redirect = useRedirect();
  const { loaded: permissionsLoaded, permissions } = usePermissions();

  const getPerm = useCallback(
    (p) => {
      return authProvider.getPermissionPerType(
        permissions,
        "process-to-nestrom",
        p
      );
    },
    [permissions]
  );

  let currDateStr = getCurrentDateStr();
  const [state, setState] = React.useState({
    open: false,
    fetching: false,
    filter: { startDate: currDateStr, endDate: currDateStr },
  });
  const filtersUpdated = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log("filterChanged", name, value, state.filter);
    setState({
      ...state,
      alert: null,
      success: null,
      error: null,
      fetching: false,
      filter: { ...state.filter, [name]: value },
    });
  };

  if (!permissionsLoaded) {
    return (
      <Loading
        loadingPrimary="Loading..."
        loadingSecondary="Loading Permissions"
      />
    );
  }

  if (
    !getPerm("processjc") ||
    !getPerm("processppm_workorder") ||
    !getPerm("processsnapcard")
  ) {
    redirect("/quality-totals");
    return null;
  }

  const sendPullRequestToPRS = function () {
    let selectedResource;
    switch (state.type) {
      case "PPMs":
        selectedResource = ivivaProcessppm_workorder;
        break;
      case "Job Cards(CMs & Rms)":
        selectedResource = ivivaProcessjc;
        break;
      case "Snap Cards":
        selectedResource = ivivaProcesssnapcard;
        break;
      default:
        break;
    }

    let from = getTimeStampFromDateStr(state.filter.startDate, "start");
    let to = getTimeStampFromDateStr(state.filter.endDate, "end");
    let query = `from=${from}&to=${to}`;
    let resourceWithQuery = `${selectedResource.name}?${query}`;
    console.log("resourceWithQuery", resourceWithQuery);
    // return setTimeout(() => {
    //   setState({
    //     ...state,
    //     fetching: false,
    //     open: true,
    //     success: "re.data",
    //     error: null,
    //   });
    //   //   setState({
    //   //     ...state,
    //   //     alert: null,
    //   //     fetching: false,
    //   //     open: true,
    //   //     error: { message: "Failed Something" },
    //   //     success: null,
    //   //   });
    // }, 2000);
    dataProvider("CUSTOM", resourceWithQuery, {
      method: "POST",
      data: {},
    })
      .then((re) => {
        console.log("re", re);
        setState({
          ...state,
          alert: null,
          fetching: false,
          open: true,
          success:
            typeof re.data === "string" ? re.data : JSON.stringify(re.data),
          error: null,
        });
      })
      .catch((err) => {
        setState({
          ...state,
          alert: null,
          fetching: false,
          open: true,
          error: err,
          success: null,
        });
      });
  };

  const openDialog = function (type) {
    console.log("openDialog");
    let from = getTimeStampFromDateStr(state.filter.startDate, "start");
    let to = getTimeStampFromDateStr(state.filter.endDate, "end");
    let daysDiff = (to - from) / 1000 / 60 / 60 / 24;
    console.log("daysDiff ", daysDiff);
    let msg =
      daysDiff === 0
        ? "Please Select Start/end Dates"
        : daysDiff < 0
        ? "Start Date Must Be Before End Date"
        : "Date Range Can't Exceede 1 Week";
    if (daysDiff > 7 || daysDiff <= 0) {
      // return notify(msg, "warning");
      let timeOut = setTimeout(
        () =>
          setState({
            ...state,
            snackBarOpen: false,
            snackBarMsg: null,
            timeOut: null,
          }),
        4000
      );
      console.log("start timeout");
      setState({ ...state, timeOut, snackBarOpen: true, snackBarMsg: msg });
      return;
    }

    setState({ ...state, alert: null, type, open: true, fetching: false });
  };

  const handleClose = function (option) {
    console.log("handleClose");
    if (option === "Cancel") {
      setState({
        ...state,
        alert: null,
        type: null,
        open: false,
        fetching: false,
        success: null,
        error: null,
      });
    } else if (option === "Pull") {
      sendPullRequestToPRS();
      setState({ ...state, alert: null, fetching: true });
    }
  };

  console.log("state", state);
  return (
    <>
      <Card className={classes.card}>
        <div className={"text-center"}>
          <CreateFilters
            filtersUpdated={filtersUpdated.bind(this)}
            filterValues={state.filter}
            width={"50%"}
          />
        </div>
        <br />
        <div
          style={{
            width: "30%",
            borderBottom: "1px solid #dbdbdb",
            marginLeft: "15px",
          }}
        ></div>
        <br />
        <CardContentInner>
          <>
            <Alert className={classes.alert} variant="outlined" severity="info">
              <span className={classes.title}>
                {"Select start And End Dates To Pull Cards"}
              </span>
              <div width="33%">
                <hr />
              </div>
              <span className={classes.gray}>
                {"Maximum Date Range is One Week"}
              </span>
            </Alert>

            {/* {state.alert && (
              <Alert className={classes.alert} severity="error">
                {state.alert}
              </Alert>
            )} */}
          </>

          <table className={classes.actionsToBottom}>
            <tbody>
              <ButtonContainer
                openDialog={openDialog}
                button={{
                  name: "Force pull data",
                  type: "PPMs",
                }}
                message={"Pull PPMs"}
              />
              <ButtonContainer
                openDialog={openDialog}
                button={{
                  name: "Force pull data",
                  type: "Job Cards(CMs & Rms)",
                }}
                message={"Pull Job Cards(CMs & Rms)"}
              />
              <ButtonContainer
                openDialog={openDialog}
                button={{
                  name: "Force pull data",
                  type: "Snap Cards",
                }}
                message={"Pull Snap Cards"}
              />
            </tbody>
          </table>
        </CardContentInner>
      </Card>
      <Modal
        open={state.open}
        onClose={() => handleClose("Cancel")}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          {state.fetching ? (
            <>
              <h2>Sending Request...</h2>
              <div
                style={{
                  top: "50%",
                  margin: "auto",
                  position: "relative",
                  width: "80%",
                }}
              >
                <div className={classes.loaderContainer}>
                  <CircularProgress />
                </div>
              </div>
            </>
          ) : state.success ? (
            <>
              <h2 style={{ textAlign: "center" }}>{state.success}</h2>
              <div
                style={{
                  top: "50%",
                  margin: "auto",
                  position: "relative",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <CheckCircleOutlineIcon
                  style={{ fontSize: 100, color: "green" }}
                />
              </div>
            </>
          ) : state.error ? (
            <>
              <h2 style={{ textAlign: "center" }}>
                Error: {state.error.message || state.error}
              </h2>
              <div
                style={{
                  top: "50%",
                  margin: "auto",
                  position: "relative",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <CancelOutlinedIcon style={{ fontSize: 100, color: "red" }} />
              </div>
            </>
          ) : (
            <>
              <h3>Are You sure?</h3>
              <p>This Will Pull {state.type} From Iviva</p>
              <Button
                variant="contained"
                color="secondary"
                className={classes.pullButton}
                startIcon={<GetAppIcon />}
                onClick={() => handleClose("Pull")}
              >
                {"Pull From Iviva"}
              </Button>
            </>
          )}
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={state.snackBarOpen}
        onClose={() => {}}
        key={"nsackbar"}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
      >
        <Alert severity="error">{state.snackBarMsg}</Alert>
      </Snackbar>
    </>
  );
}
