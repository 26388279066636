import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { usePermissions, useRedirect, Loading } from "react-admin";

import SettingsComponent from "./settingsComponent";
import SettingsComponentWithSaveAll from "./SettingsComponentWithSaveAll";
import { resources } from "../../provider/configs";
import CreateFilters from "./filters";
import { authProvider } from "../../provider/provider";
import { AntTabs, AntTab, TabPanel } from "../../helpers/tabs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    root: {
      borderBottom: "1px solid #e8e8e8",
    },
    indicator: {
      backgroundColor: "#1890ff",
    },
  },
}));

const tabsConfigs = [
  {
    resource: resources["invoice-summary-settings"],
    TabComponent: SettingsComponent,
  },
  {
    resource: resources["kpi-penalty-configs"],
    TabComponent: SettingsComponentWithSaveAll,
  },
  {
    resource: resources["ncr-categories"],
    TabComponent: SettingsComponent,
  },
  {
    resource: resources["prs-configuration-percentage-settings"],
    TabComponent: SettingsComponentWithSaveAll,
  },
  {
    resource: resources["quality-audit-zone-configs"],
    TabComponent: SettingsComponentWithSaveAll,
  },
  {
    resource: resources["quality-audit-configuration-percentages"],
    TabComponent: SettingsComponentWithSaveAll,
  },
  {
    resource: resources["sla-penalty-configs-settings"],
    TabComponent: SettingsComponent,
  },
  {
    resource: resources["user-type-percentages"],
    TabComponent: SettingsComponent,
  },
];

export default function CustomizedTabs() {
  const classes = useStyles();
  const redirect = useRedirect();
  const { loaded: permissionsLoaded, permissions } = usePermissions();
  const [value, setValue] = React.useState(7);

  const getPerm = useCallback(
    (n, a) => {
      return authProvider.getPermissionPerType(permissions, n, a || "find");
    },
    [permissions]
  );

  let d = new Date();
  let def = {
    month: d.getMonth() + 1,
    year: d.getFullYear(),
    // STg
    // vendor: "5f61d11c3ed47ec8e020f3df", // AFE
    // cost_center: "5f61d11a3ed47ec8e020f3d5", // FT
    // Ihab
    // cost_center: "5f604b4b8bfa7514334bdd35", // MP
    // vendor: "5f604b4b8bfa7514334bdd3d", // PSS
  };
  const [state, setState] = React.useState({ fetch: false, filter: def });

  const onClickFetch = () => {
    setState({ fetch: true, filter: { ...state.filter } });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filtersUpdated = (event) => {
    const name = event.target.name.toLowerCase();
    const value = event.target.value;
    setState({ fetch: false, filter: { ...state.filter, [name]: value } });
  };

  if (!permissionsLoaded) {
    return (
      <Loading
        loadingPrimary="Loading..."
        loadingSecondary="Loading Permissions"
      />
    );
  }

  tabsConfigs.forEach((e) => (e.permission = getPerm(e.resource.app.name)));
  let tabsConfigsFiltered = tabsConfigs.filter(
    (e) => e.permission.find.enabled
  );
  if (!tabsConfigsFiltered.length) {
    redirect("/quality-totals");
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="ant example"
        >
          {tabsConfigsFiltered.map(({ resource }, i) => (
            <AntTab
              wrapped
              label={resource.alias}
              key={`settings-tab-header-${i}`}
            />
          ))}
        </AntTabs>
        <Typography className={classes.padding} />
      </div>
      {tabsConfigsFiltered.map(({ TabComponent, resource, permission }, i) => (
        <TabPanel key={`settings-tab-${i}`} value={value} index={i}>
          {resource.requiredFilters.length ? (
            <>
              <CreateFilters
                filtersUpdated={filtersUpdated.bind(this)}
                filterValues={state.filter}
                requiredFilters={resource.requiredFilters}
                onClickFetch={onClickFetch}
                width={"100%"}
                // width={settingsTablesConfigs[resource.name].width}
              />
              <br />
            </>
          ) : null}
          <TabComponent
            permission={permission}
            configResource={resource.name}
            filterValues={state.filter}
            fetch={state.fetch}
          />
        </TabPanel>
      ))}
    </div>
  );
}
