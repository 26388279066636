import React, { useState } from "react";
import { List, NumberField, TextField } from "react-admin";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

import MyDatagrid from "../customise/CustomDataGrid";
import { CommonFilter } from "../helpers/commonFilters";
// import exporters from "../helpers/exporters";
import { getParameterByName, getDefaultTime, filtersChanged, allFilters } from "../helpers/utils";
import { authProvider } from "../provider/provider";
import { resources } from "../provider/configs";

const useStyles = makeStyles({
  failed: { color: "red" },
  passed: { color: "green" },
  boldy: { fontWeight: "bold" },
});

const PerformanceField = (props) => {
  const classes = useStyles();
  let fail = !props.record["performance"].match(/pass/i);
  return (
    <span
      className={classnames({
        [classes.failed]: fail,
        [classes.passed]: !fail,
        [classes.boldy]: true,
      })}
    >
      {props.record["performance"]}
    </span>
  );
};

const rowStyle = (record, index) => {
  if (record.isGroupHeader) {
    return {
      height: "2em",
      backgroundColor: "#dde6e9",
      textAlign: "center",
      fontSize: "2em",
      fontWeight: "bold",
    };
  }
  return {};
};

export const RegulatoryCompliancesList = ({ ...props }) => {
  let filter = getParameterByName("filter") || "{}";
  filter = JSON.parse(filter);
  let { year, month } = getDefaultTime();

  let [stateFilter, setStateFilter] = useState({
    displayed: allFilters(filter),
    vendor: filter.vendor,
    cost_center: filter.cost_center,
    year: filter.year || year,
    month: filter.month || month,
  });

  function filtersUpdated(filters) {
    if (filtersChanged(filters, stateFilter)) {
      if (!allFilters(filters)) {
        setStateFilter({ ...filters, displayed: false });
      } else {
        setStateFilter({ ...filters, displayed: true });
      }
    }
  }

  const isEditAllowed = () => authProvider.isAllowed(props.permissions, resources.regulatoryCompliance.app.name, "update");
  let total_fields = [{ field: "performance", key: "totalPenalties_response" }];
  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<CommonFilter filtersUpdated={filtersUpdated} />}
        filterDefaultValues={{ year, month }}
        pagination={false}
        title="Regulatory Compliances List"
        exporter={false}
        // exporter={(d) => exporters(d, "regulatoryCompliance")}
      >
        <MyDatagrid
          groupby={"regulatory_compliance_item.regulatory_compliance_group.label"}
          rowStyle={rowStyle}
          rowClick={(id, basePath, record) => (record.isGroupHeader || !isEditAllowed() || id.match(/future/i) ? null : "edit")}
          colSpan={3}
          total_fields={total_fields}
          toSum={["actual"]}
        >
          <TextField source="regulatory_compliance_item.Ref" label="Ref" />
          <TextField sortable={false} source="regulatory_compliance_item.ValueDriver" label="Value Driver" />
          <TextField sortable={false} source="regulatory_compliance_item.Measurement" label="Measurement" />
          <TextField sortable={false} source="regulatory_compliance_item.Benefit" label="Benefit" />
          <TextField sortable={false} source="regulatory_compliance_item.Goal" label="Goal" />
          <TextField sortable={false} source="regulatory_compliance_item.KPI" label="KPI" />
          <TextField sortable={false} source="regulatory_compliance_item.Frequency" label="Frequency" />
          <TextField sortable={false} label="Expected Target" source="regulatory_compliance_item.Target" />
          <NumberField sortable={false} source="actual" label="Actual" />
          <NumberField sortable={false} source="Justification" label="Justification" />
          <PerformanceField sortable={false} source="performance" label="Performance" />
        </MyDatagrid>
      </List>
    </div>
  );
};
