import { fetchUtils } from 'react-admin';
import authProviderFunction from './authProvider'
import simpleRestProvider from './ra-strapi-rest';
import Cookies from '../helpers/Cookies';
import { apiLink } from "./configs";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = Cookies.getCookie('token')
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(apiLink, httpClient);
const authProvider = authProviderFunction(apiLink)

const provider = {
    dataProvider,
    authProvider
}

export default provider;

export {
    dataProvider,
    authProvider
}
