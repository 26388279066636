import React, { Component } from "react";
import { SaveButton } from "react-admin";
import AlertDialog from "../customise/confirmationDialog";

class CustomSaveButton extends Component {
  state = { open: false };

  handleClick = (confirm) => {
    console.log("confirm", confirm);
    if (confirm === "Ok") {
      this.props.handleSubmitWithRedirect(this.props.redirect);
    }
    this.setOpen(false);
  };

  setOpen(b) {
    if (this.state.open === b) return;
    this.setState({ open: b });
  }

  handleClickOpen = () => {
    this.setOpen(true);
  };

  render() {
    return (
      <>
        {!this.props.saving && (
          <SaveButton
            disabled={this.props.disabled}
            onClick={this.handleClickOpen}
            handleSubmitWithRedirect={this.handleClickOpen}
          />
        )}
        <AlertDialog
          open={this.state.open}
          messages={{
            id: "custom-save-button",
            title: "Confirm!",
            description: () => <span>{"Are You Sure?"}</span>,
            ok: "Save",
            cancel: "Cancel",
          }}
          handler={this.handleClick}
          maxWidth={"xs"}
        />
        {this.props.saving && "saving"}
      </>
    );
  }
}

export default CustomSaveButton;
