import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import "date-fns";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

const dateInputUseStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    paddingTop: "8px",
  },
}));

const CreateFilters = ({ filtersUpdated, filterValues, width }) => {
  const classes = dateInputUseStyles();
  return (
    <div style={{ width }}>
      <Box
        component="div"
        display="inline"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <FormControl variant="filled" className={classes.formControl} error>
          <form className={classes.container} noValidate>
            <TextField
              id="start-date"
              label="Start Date(MM-DD-YYYY)"
              type="date"
              name="startDate"
              className={classes.textField}
              value={filterValues.startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={filtersUpdated}
            />
            <TextField
              id="end-date"
              label="End Date(MM-DD-YYYY)"
              type="date"
              name="endDate"
              className={classes.textField}
              value={filterValues.endDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={filtersUpdated}
            />
          </form>
        </FormControl>
      </Box>
    </div>
  );
};

export default CreateFilters;
