import * as React from 'react';
import {
    TopToolbar,
    ListButton,
} from 'react-admin';

const ShowListAction = ({ basePath, size = "medium", data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} size={size} />
    </TopToolbar>
)

export default ShowListAction;
