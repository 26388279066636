import React from "react";
import { resources } from "../provider/configs";
import { GET_LIST } from "react-admin";
import { dataProvider } from "../provider/provider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from '@material-ui/core/styles';
import WithHeaderGroupTable from "../helpers/WithHeaderGroupTable.js";
import { formatCurrency } from '../helpers/utils';

let configResource = resources["invoice-summaries"];

const styles = {
  hide: { display: "none" },
};

class InvoiceSummary extends React.Component {
  state = {
    error: null,
    isLoaded: false,
    columns:  [
      {
          Header: "Invoices Summary",
          columns: [
              {
                  accessor: "type",
                  Header: "Type",
                  stylePerCell: this.stylePerCell
              },
              // {
              //     accessor: "currency",
              //     Header: "Currency",
              //     stylePerCell: this.stylePerCell
              // },
              {
                  accessor: "value",
                  Header: "Penality",
                  stylePerCell: this.stylePerCell
              },
          ]
      }
    ],
    rows: [],
    filter: {
      vendor: this.props.filters.vendor,
      cost_center: this.props.filters.cost_center,
      year: this.props.filters.year,
      month: this.props.filters.month,
    },
  };

  stylePerCell({row, accessor}) {
    if(row.type === 'Monthly Value - Credit Note') {
        return { color: 'red', fontWeight: 'bold' }
    }
    if(row.type === 'Deductions' && accessor === 'value') {
        return { fontWeight: 'bold', color: 'red' }
    } else if(accessor === 'type') {
        return { fontWeight: 'bold' }
    } else {
        return {}
    }
  }

  get() {
    this._asyncRequest = dataProvider(GET_LIST, configResource.name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {...this.state.filter},
    });
    return this._asyncRequest;
  }

  componentWillUnmount() {
    this._isMounted = false
    if (this._asyncRequest) {
      this._asyncRequest.cancel && this._asyncRequest.cancel();
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.reFetch();
  }

  reFetch() {
    if(this.state.isLoaded) this.setState({isLoaded: false})
    this.get().then(
      (res) => {
        let { data } = res
        if (!this._isMounted) return;
        if(!res.data) {
          return this.setState({ isLoaded: true, error: {message: 'No Data Available'}, rows: [] })
        }
        let rows = [
            {
                id: 1,
                currency: 'AED',
                type: 'Monthly Value - Contract',
                value: formatCurrency(data.monthlyValueContract, 'AED'),
                value_original: data.monthlyValueContract
            },
            {
                id: 2,
                currency: 'AED',
                type: 'Deductions',
                value: formatCurrency(data.deductions, 'AED'),
                value_original: data.deductions
            },
            {
                id: 3,
                currency: 'AED',
                type: 'Monthly Value - Invoice',
                value: formatCurrency(data.monthlyValueInvoice, 'AED'),
                value_original: data.monthlyValueInvoice
            },
            {
                id: 4,
                currency: 'AED',
                type: 'Monthly Value - Credit Note',
                value: formatCurrency(data.monthlyValueCreditNote, 'AED'),
                value_original: data.monthlyValueCreditNote
            },
        ]
        
        this.setState({ isLoaded: true, rows })
      },
      (error) => this.setState({ isLoaded: true, error, rows: [] })
    );
  }

  UNSAFE_componentWillReceiveProps(p) {
    // let { rows } = this.state
    // if(this.props.lastUpdate !== (rows[rows.length - 1] || {}).value_original) {
      if(this.state.isLoaded) this.reFetch()
    // }
  }

  render() {
    // const { classes } = this.props;
    const { error, isLoaded, rows, columns } = this.state;
        
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div style={{
            top: '50%',
            margin: 'auto',
            position: 'relative',
            width: '50px'
        }}><CircularProgress /></div>;
    } else {
      return (
        <WithHeaderGroupTable columns={columns} rows={rows}/>
      );
    }
  }
}

export default withStyles(styles)(InvoiceSummary);
