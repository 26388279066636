import * as React from "react";
import { List, Datagrid, TextField, ShowButton, EditButton } from "react-admin";

export const VendorsList = ({ ...props }) => {
  return (
    <div>
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick={"show"}>
          <TextField source="name" />
          <TextField source="vendorKey" />
          <TextField source="serviceType" />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  );
};
