import React from "react";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AlertDialog from "../../customise/confirmationDialog";

import { resources } from "../../provider/configs";
import { dataProvider } from "../../provider/provider";

let updateResource = resources["send_to_iviva_update"];

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    textAlign: "center",
  },
  button: {
    "&:hover": { backgroundColor: "darkGreen" },
    backgroundColor: "darkGreen",
  },
});

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

class SendToIviva extends React.Component {
  state = {
    open: false,
    error: null,
    success: null,
    loading: false,
    filter: {
      vendor: this.props.filter.vendor,
      cost_center: this.props.filter.cost_center,
      year: this.props.filter.year,
      month: this.props.filter.month,
    },
  };

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose(confirm) {
    if (confirm === "Ok") {
      let that = this;
      that.setState({ loading: true, open: true });
      let data = this.state.filter;
      console.log("creating prs for ", data);
      // new Promise((res, rej) => {
      //   setTimeout(() => res({ data }), 3000);
      // })
      dataProvider("CUSTOM", updateResource.name, {
        method: "POST",
        data,
      })
        .then((re) => {
          if (!this._isMounted) return;
          console.log("re", re);
          that.props.setLastUpdate(new Date());
          that.setState({ loading: false, open: true, success: re.data });
        })
        .catch((err) => {
          console.log({ err });
          if (!this._isMounted) return;
          let cost_centers = JSON.parse(localStorage.getItem("cost-centers"));
          let vendors = JSON.parse(localStorage.getItem("vendors"));
          that.setState({
            loading: false,
            open: true,
            error:
              err.status === 500
                ? `Make sure that you created a contract for ${
                    cost_centers.find((cc) => cc._id == data.cost_center).name
                  } - ${vendors.find((v) => v._id == data.vendor).name} at ${
                    data.month
                  }/${data.year}`
                : err,
          });
        });
    } else {
      this.setState({ open: false });
    }
  }

  stateChanged(obj1, obj2) {
    return (
      obj1.cost_center !== obj2.cost_center ||
      obj1.vendor !== obj2.vendor ||
      obj1.year !== obj2.year ||
      obj1.month !== obj2.month
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.stateChanged(props.filter, this.state.filter)) {
      this.setState({
        success: null,
        filter: {
          vendor: props.filter.vendor,
          cost_center: props.filter.cost_center,
          year: props.filter.year,
          month: props.filter.month,
        },
      });
    }
  }

  render() {
    let body = null;
    const { classes, /* data,  */disabled } = this.props;
    let { error, success, loading, open/* , summary */ } = this.state;
    let disableButton = !!(disabled || open || success);

    if (open && !success && !error && !loading) {
      let message = "Are you sure? This is un-reversable!";
      let description = () => {
        return <span>{message}</span>;
      };

      return (
        <AlertDialog
          open={open}
          messages={{
            title: "Confirmation!",
            description,
            ok: "Send",
            cancel: "Cancel",
          }}
          handler={this.handleClose.bind(this)}
        />
      );
    }

    if (success) {
      body = (
        <div style={modalStyle} className={classes.paper}>
          <h2 style={{ textAlign: "center" }}>{success.status}</h2>
          <div
            style={{
              top: "50%",
              margin: "auto",
              position: "relative",
              width: "100%",
              textAlign: "center",
            }}
          >
            <CheckCircleOutlineIcon style={{ fontSize: 100, color: "green" }} />
          </div>
          <br />
          <br />
        </div>
      );
    } else if (error) {
      console.error("error, ", error, error.message);
      body = (
        <div style={modalStyle} className={classes.paper}>
          <h2 style={{ textAlign: "center" }}>
            Error: {error.message || error}
          </h2>
          <div
            style={{
              top: "50%",
              margin: "auto",
              position: "relative",
              width: "100%",
              textAlign: "center",
            }}
          >
            <CancelOutlinedIcon style={{ fontSize: 100, color: "red" }} />
          </div>
        </div>
      );
    } else if (loading) {
      body = (
        <div style={modalStyle} className={classes.paper}>
          <h2>{"Sending ..."}</h2>
          <div
            style={{
              top: "50%",
              margin: "auto",
              position: "relative",
              width: "80%",
            }}
          >
            <LinearProgress />
          </div>
          <br />
          <br />
        </div>
      );
    }

    return (
      <div>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<SendIcon />}
          disabled={disableButton}
          onClick={this.handleOpen.bind(this)}
        >
          {"Send To Iviva"}
        </Button>
        {body && (
          <Modal
            open={open}
            onClose={() => this.handleClose("Cancel")}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SendToIviva);
