import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  email,
  required,
  ReferenceInput,
  SelectInput,
  useRedirect,
  PasswordInput,
} from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import ListIcon from "@material-ui/icons/List";
import Button from "@material-ui/core/Button";
import { resources } from "../provider/configs";

const useStyles = makeStyles((theme) => ({
  header: { float: "left" },
  button: { float: "right" },
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    minHeight: theme.spacing(5),
    [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
  },
}));

const UsersCreateActions = ({ onClickAction }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <>
      <h2 className={classes.header}>{"Create New User"}</h2>
      <Toolbar className={classes.root}>
        <Button
          color="primary"
          onClick={() =>
            onClickAction
              ? onClickAction("list")
              : redirect("/settingsUsers?tab=0&view=list")
          }
          startIcon={<ListIcon />}
          className={classes.button}
        >
          {"Users List"}
        </Button>
      </Toolbar>
    </>
  );
};

export const UserCreate = ({ onClickAction, ...props }) => {
  return (
    <Create
      {...props}
      onSuccess={({ data }) => onClickAction("list")}
      actions={<UsersCreateActions onClickAction={onClickAction} />}
    >
      <SimpleForm>
        <TextInput
          source="username"
          label={"Username"}
          validate={[required()]}
        />
        <TextInput
          source="fullName"
          label={"Full Name"}
          validate={[required()]}
        />
        <TextInput
          source="email"
          label={"Email"}
          validate={[required(), email()]}
          type="email"
        />
        <ReferenceInput
          label="Role"
          source="role"
          reference={resources["roles"].name}
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <PasswordInput source="password" />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
