import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ShowButton,
} from "react-admin";

export const ConstCentersList = ({ ...props }) => {
  return (
    <div>
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick={"show"}>
          <TextField source="name" />
          <TextField source="key" />
          <TextField source="calculationMethod" label={"Calculation Method"} />
          <ArrayField source="zone_location_mappings">
            <SingleFieldList>
              <ChipField source="zoneName" />
            </SingleFieldList>
          </ArrayField>
          <ShowButton />
        </Datagrid>
      </List>
    </div>
  );
};
