import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItemLink, usePermissions } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconForPRS from "@material-ui/icons/PermDataSetting";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UserSettingIcon from "@material-ui/icons/Group";
import IvivaIcon from "./ivivaIcon";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LocationCityIcon from "@material-ui/icons/LocationCity";

import { authProvider } from "../../provider/provider";
const isAllowed = authProvider.isAllowed;

const useStylesSubList = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  nested_closed: {
    paddingLeft: theme.spacing(0),
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  menueItem: {
    fontSize: "small",
    // fontSize: "x-small",
    overflow: "wrape",
    wordWrap: "initial",
    overflowWrap: "normal",
    width: '100%'
  },
  parentItem: {
    backgroundColor: "#e6e6e6",
  },
}));

function ivivaPermission(permissions) {
  if (!permissions) return false;
  return (
    isAllowed(permissions, "process-to-nestrom", "processjc") ||
    isAllowed(permissions, "process-to-nestrom", "processppm_workorder") ||
    isAllowed(permissions, "process-to-nestrom", "processsnapcard")
  );
}

const SettingsItems = ({ open: sideBarOpen }) => {
  const classes = useStylesSubList();
  const { permissions } = usePermissions();

  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);

  let userFind = isAllowed(permissions, "users", "find");
  let prsConfigs = isAllowed(permissions, "prsConfigs", "find");
  let iviva = ivivaPermission(permissions);

  let items = [];
  if (userFind) {
    items.push(
      <MenuItemLink
        onClick={handleClick}
        key={"settingsUsers"}
        to={"/settingsUsers?tab=0&view=list"}
        primaryText={"Users & Permissions"}
        leftIcon={<UserSettingIcon size={"sm"} />}
        color="primary"
        className={classes.menueItem}
      />
    );
  }

  if (prsConfigs) {
    items.push(
      <MenuItemLink
        onClick={handleClick}
        key={"SettingsPRS"}
        to={"/settingsPRS"}
        primaryText={"PRS Settings"}
        leftIcon={<IconForPRS size={"sm"} />}
        color="primary"
        className={classes.menueItem}
      />
    );
  }

  if (iviva) {
    items.push(
      <MenuItemLink
        onClick={handleClick}
        key={"Ivvia"}
        to={"/iviva"}
        primaryText={"Sampling Settings"}
        leftIcon={<IvivaIcon size={"sm"} />}
        color="primary"
        className={classes.menueItem}
      />
    );
  }
  if (!items.length) return null;
  if (items.length === 1) return items[0];
  const costCentersItem = (
    <MenuItemLink
      onClick={handleClick}
      key={"CostCenters"}
      to={"/cost-centers"}
      primaryText={"Cost Centers"}
      leftIcon={<LocationCityIcon size={"sm"} />}
      color="primary"
      className={classes.menueItem}
    />
  );

  const vendorsItem = (
    <MenuItemLink
      onClick={handleClick}
      key={"Vendors"}
      to={"/vendors"}
      primaryText={"Vendors"}
      leftIcon={<StorefrontIcon size={"sm"} />}
      color="primary"
      className={classes.menueItem}
    />
  );
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button onClick={handleClick} className={classes.parentItem}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        {sideBarOpen ? (
          <ListItemText primary="Settings" className={classes.menueItem} />
        ) : (
          ""
        )}
        {sideBarOpen ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, i) => (
            <ListItem
              key={`settings-menu-item-${i + 1}`}
              button
              className={!sideBarOpen ? classes.nested_closed : classes.nested}
            >
              {item}
            </ListItem>
          )).concat([
            <ListItem key={`settings-menu-item-${items.length + 1}`} button className={!sideBarOpen ? classes.nested_closed : classes.nested}>
              {costCentersItem}
            </ListItem>,
            <ListItem key={`settings-menu-item-${items.length + 2}`} button className={!sideBarOpen ? classes.nested_closed : classes.nested}>
              {vendorsItem}
            </ListItem>,
          ])}
        </List>
      </Collapse>
    </List>
  );
};

export default SettingsItems;
