import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { dataProvider, authProvider } from "../provider/provider";
import { resources, apiLink } from "../provider/configs";

const configResource = resources["downloadSummaryPDF"];

const styles = (theme) => ({});

class SendResultTo extends React.Component {
  state = {
    error: null,
    success: null,
    loading: false,
    filter: {
      vendor: this.props.filter.vendor,
      cost_center: this.props.filter.cost_center,
      year: this.props.filter.year,
      month: this.props.filter.month,
    },
  };

  stateChanged(obj1, obj2) {
    return (
      obj1.cost_center !== obj2.cost_center ||
      obj1.vendor !== obj2.vendor ||
      obj1.year !== obj2.year ||
      obj1.month !== obj2.month
    );
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.filter.cost_center) {
      dataProvider("CUSTOM", `${resources.cost_centers.name}/${this.props.filter.cost_center}`, { method: "GET" })
      .then((re) => {
        if (!this._isMounted) return;
        if (re.data.calculationMethod == "mall") this.setState({ allowedCC: true });
      })
    }
    if (this.props.filter.vendor) {
      dataProvider("CUSTOM", `${resources.vendors.name}/${this.props.filter.vendor}`, { method: "GET" })
        .then((re) => {
          if (!this._isMounted) return;
          if (re.data.serviceType == "hard") this.setState({ allowedV: true });
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._asyncRequest) {
      this._asyncRequest.cancel && this._asyncRequest.cancel();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.stateChanged(props.filter, this.state.filter)) {
      this.setState({
        success: null,
        filter: { ...props.filter },
      });
    }
    if (props.filter.cost_center) {
      dataProvider("CUSTOM", `${resources.cost_centers.name}/${props.filter.cost_center}`, { method: "GET" })
      .then((re) => {
        if (!this._isMounted) return;
        if (re.data.calculationMethod == "mall") this.setState({ allowedCC: true });
      })
    }
    if (props.filter.vendor) {
      dataProvider("CUSTOM", `${resources.vendors.name}/${props.filter.vendor}`, { method: "GET" })
        .then((re) => {
          if (!this._isMounted) return;
          if (re.data.serviceType == "hard") this.setState({ allowedV: true });
        })
    }
  }

  isFuture() {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const isFuture =
      (this.props.filter.month > currentMonth && this.props.filter.year == currentYear) ||
      this.props.filter.year > currentYear;

    return isFuture;
  }

  downlaodSummary() {
    let filter = this.state.filter;
    let query = `cost_center=${filter.cost_center}&vendor=${filter.vendor}&year=${filter.year}&month=${filter.month}`;
    
    return window.open(`${apiLink}/${configResource.name}?${query}`, "_blank")
    // is this needed?
    // this._asyncRequest = dataProvider("CUSTOM", `${configResource.name}?${query}`, {
    //   method: "GET",
    //   data: {},
    // }).then((response) => {
    //     console.log({"response.data": response.data})
    //   const url = window.URL.createObjectURL(
    //     new Blob([response.data], { type: "application/pdf", responseType: "arraybuffer" })
    //   );

    //   return window.open(url, "_blank")
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "docname.pdf");
    //   document.body.appendChild(link);
    //   link.click();
    // });
    // return this._asyncRequest;
  }

  render() {
    const { permissions, data } = this.props;
    let { allowedCC, allowedV } = this.state;

    let allowed = authProvider.isAllowed(
      permissions,
      configResource.app.name,
      configResource.app.findOne
    );

    let noData = Object.keys(data).length === 0;
    let isFuture = this.isFuture();
    
    return (
      <Button
        disabled={noData || !allowed || isFuture || !allowedCC || !allowedV}
        style={{ marginRight: "10px" }}
        variant="outlined"
        startIcon={<PictureAsPdfIcon />}
        onClick={this.downlaodSummary.bind(this)}
      >
        Download
      </Button>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SendResultTo);
